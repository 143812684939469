.draggable-block-menu {
  padding: 2px 1px;
  cursor: grab;
  will-change: transform;
  opacity: 0;
  @apply absolute -left-16 top-0 rounded maxMd:-left-[34px];
}

.draggable-block-menu .icon {
  opacity: 0.3;
  background: url("../../images/icons/dots-vertical.svg") no-repeat center / cover;
  @apply h-4 w-4 maxMd:mx-auto;
}

.draggable-block-menu:active,
.tasks-draggable-block-menu:active {
  cursor: grabbing;
}

.draggable-block-menu .icon:hover,
.draggable-block-menu .trash-icon:hover,
.draggable-block-menu .plus-icon:hover {
  background-color: #efefef;
}

.draggable-block-target-line {
  @apply pointer-events-none absolute left-0 top-0 h-[2px] w-full bg-mirage opacity-0 dark:bg-white;
}

.draggable-block-menu .trash-icon {
  width: 20px;
  height: 20px;
  opacity: 0.3;
  background-image: url(../../images/icons/trash-drag.svg);
}

.draggable-block-menu .plus-icon {
  width: 20px;
  height: 20px;
  opacity: 0.3;
  background-image: url(../../images/icons/plus-drag.svg);
}

.tasks-draggable-block-menu {
  border-radius: 4px;
  padding: 2px 1px;
  cursor: grab;
  position: absolute;
  top: 0.2rem;
  will-change: transform;
  opacity: 50 !important;
  transform: none !important;
}

.tasks-draggable-block-menu .plus-icon {
  width: 20px;
  height: 20px;
  background-image: url(../../images/icons/plus-drag.svg);
}

.tasksEditor .ContentEditable__root {
  @apply ml-4;
}
.tasksEditor .PlaygroundEditorTheme__paragraph {
  @apply !ml-0;
}
.tasksEditor .Placeholder__root {
  left: 40px;
}
