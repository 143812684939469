.gridContainer {
  @apply relative;
}
:global(body.dark) .gridContainer {
  @apply bg-darkjunglegreens;
}
.noDataContainer {
  height: calc(100vh - 194px);
}
.noDataContainerCommunity {
  height: calc(100vh - 242px);
}
.cardContainer {
  @apply grid gap-4 px-4 py-4 sm:grid-cols-2 md:grid-cols-3 md:px-5 lg:grid-cols-4 mac13Inch:grid-cols-3;
}
:global(body.dark) .cardContainer {
  @apply bg-darkjunglegreens;
}
.gridCard {
  @apply flex h-full cursor-pointer flex-col justify-between rounded-lg border border-transparent bg-white p-4;
}
:global(body.dark) .gridCard {
  @apply bg-darkjunglegreenss;
}

.titleContainer {
  @apply grid gap-1;
}
.gridTitle {
  @apply flex justify-between gap-4 overflow-x-hidden font-semibold mac13Inch:text-sm;
}
.gridTitleContent {
  @apply overflow-hidden text-ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
:global(body.dark) .gridTitle span {
  @apply text-white;
}
:global(body.dark) .gridCommentsCount {
  @apply text-stormdust;
}
:global(body.dark) .gridCommentsCount img {
  @apply opacity-40;
}
:global(body.dark) .gridSubTitle,
:global(body.dark) .gridBeforeDate span {
  @apply text-greychateau;
}
:global(body.dark) .gridTitle {
  @apply text-white;
}

.gridSubTitle {
  @apply text-sm text-aurometalsaurus;
}
:global(body.dark) .gridSubTitle {
  @apply text-greychateau;
}

.gridTags {
  @apply mt-4 flex flex-wrap gap-2 text-xs;
}
.gridCommentsContainer {
  @apply mt-4 flex w-full flex-row flex-wrap items-center gap-3;
}
.gridCommentsCount {
  @apply flex flex-wrap items-center gap-[6px] text-xs text-santagrey;
}
.gridDueDate {
  @apply !text-lavared;
}
.gridDate {
  @apply flex items-center justify-center rounded-md text-xs font-medium text-glantsOrange;
}
.gridBeforeDate {
  @apply !text-aurometalsaurus;
}
.gridTagChip {
  word-break: break-all;
  @apply flex w-fit items-center justify-center gap-1 rounded-md px-2 py-1 text-xs font-medium;
}
.gridFooter {
  @apply flex items-end justify-between;
}
.gridCardWrapper {
  @apply mb-4 space-y-4;
}
