.task_wrapper {
  @apply flex flex-col bg-ghostwhite;
}
:global(body.dark) .task_wrapper {
  @apply bg-darkjunglegreens;
}

.taskContainer {
  @apply flex items-start justify-start gap-4 overflow-x-auto overflow-y-hidden px-4;
}
.taskDropContainer {
  height: calc(100vh - 146px) !important;
}
.taskContainer::-webkit-scrollbar {
  height: 10px;
}
.taskList {
  @apply overflow-y-auto overflow-x-hidden;
}
.taskList::-webkit-scrollbar {
  display: none;
}
.taskCard {
  @apply flex max-w-md transform cursor-move flex-col rounded-lg bg-white p-5 shadow dark:bg-gray-800;
}
.taskCard > div:first-child {
  @apply mb-4 grid  grid-cols-1 items-center justify-between;
}

.editTaskButton {
  @apply ml-auto  rounded-lg text-sm text-gray-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-700;
}

.hideSelection {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
