.tabs > div {
  @apply h-[2.125rem];
}
.tabs [role="tablist"] {
  @apply h-[2.125rem];
}
.tabs [role="tablist"] {
  @apply w-fit rounded-lg bg-antiflashwhite;
}
:global(body.dark) .tabs [role="tablist"] {
  @apply bg-raisinblack;
}
.tabs [role="tablist"] button {
  @apply border border-transparent px-3 py-1 text-sm text-davygrey;
}
:global(body.dark) .tabs [role="tablist"] button {
  @apply text-silvers hover:!bg-[#373738];
}
.tabs [role="tablist"] button[aria-selected="true"] {
  @apply m-[0.0625rem] border-brightgray bg-white text-richblack;
  box-shadow: 0px 1px 2px 0px #0000000d;
}
:global(body.dark) .tabs [role="tablist"] button[aria-selected="true"] {
  @apply border-jet bg-mirage text-white hover:!bg-mirage;
}
.tabs [role="tablist"] button[aria-selected="false"] {
  @apply ml-0;
}
.tabs div:first-child > div:last-child {
  @apply hidden;
}
.vendorsProfileTabs {
  @apply w-full overflow-x-auto pt-2;
}
.vendorTabList {
  @apply min-w-[46.25rem];
}
.vendorTabList button::before {
  content: "";
  transition: all 0.3s ease-in;
  @apply absolute left-0 top-0 h-[1.75rem] w-full rounded-lg border border-brightgray opacity-0;
}
:global(body.dark) .vendorTabList button::before {
  @apply border-balticSea;
}
.vendorTabList button:hover::before {
  transition: all 0.3s ease-in;
  @apply opacity-100;
}
.vendorTabList button:hover {
  transition: all 0.2s ease-in;
  @apply !bg-ghostwhite disabled:!bg-antiflashwhite before:disabled:border-transparent;
}
:global(body.dark) .vendorTabList button:hover {
  @apply !bg-nobleBlack disabled:!bg-raisinblack;
}
.vendorsProfileTabs [role="tablist"] {
  @apply flex h-[1.75rem] w-full rounded-lg !bg-antiflashwhite text-center;
}
:global(body.dark) .vendorsProfileTabs [role="tablist"] {
  @apply !bg-raisinblack;
}
.vendorTabList button:has(.active_tab)::before {
  @apply opacity-100;
}

.vendorsProfileTabs [role="tablist"] button {
  @apply relative flex items-center justify-center rounded-lg !border-x-0 bg-antiflashwhite px-3 py-1 text-sm text-davygrey outline-none disabled:cursor-not-allowed disabled:text-santagrey;
}
:global(body.dark) .vendorsProfileTabs [role="tablist"] button {
  @apply bg-raisinblack text-gray_600 disabled:text-gray_400;
}
.vendorsProfileTabs [role="tablist"] button:has(.active_tab) {
  @apply rounded-lg !bg-white font-inter-medium text-sm text-gray-900;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

:global(body.dark) .vendorsProfileTabs button[aria-selected="true"] {
  @apply !bg-darkjunglegreens text-white hover:!bg-darkjunglegreens;
}
:global(body.dark) .vendorsProfileTabs button[aria-selected="true"]:hover::before {
  @apply block;
}
.vendorsProfileTabs [role="tablist"] button[aria-selected="true"]:hover {
  @apply !bg-white;
}
.vendorsProfileTabs [role="tablist"] button[aria-selected="true"]:hover::before {
  @apply block;
}
.vendorsProfileTabs div[role="tabpanel"] {
  @apply px-0 py-0;
}
.vendorsFormInput input,
.vendorsFormInput input[type="text"],
.vendorsFormInput input[type="number"],
.vendorsFormInput select {
  @apply gap-2 rounded-lg border border-brightgray bg-white px-3 py-2 font-inter-medium text-sm text-gray-700 placeholder-santagrey focus:!border-brightgray disabled:!text-gray-400 disabled:placeholder-gray-400 disabled:opacity-100;
}

.vendorsWithoutBorderInput input {
  @apply rounded-none border-none bg-transparent p-0 font-inter-semibold text-richblack placeholder-lightSilver disabled:!text-gray-300 disabled:placeholder-gray-300;
}

.vendorsWithoutBorderTextArea {
  @apply flex resize-none flex-wrap overflow-hidden break-words rounded-none border-none bg-transparent p-0 font-inter-semibold text-richblack placeholder-lightSilver disabled:!text-gray-300 disabled:placeholder-gray-300;
}

.vendorsTextXLInput,
.vendorsTextXLInput input {
  @apply text-xl placeholder:text-xl;
}

.vendorsTextSMInput,
.vendorsTextSMInput input {
  @apply font-inter-medium text-sm !text-aurometalsaurus placeholder:text-sm;
}

.vendorsFormInputTextArea {
  @apply resize-none gap-2 rounded-lg border border-brightgray bg-white font-inter-regular text-sm text-gray-700 placeholder:font-inter-medium placeholder:text-santagrey focus:!border-brightgray disabled:!text-gray-400 disabled:opacity-100;
}

.vendorFormSeparationStyle {
  @apply m-0 h-[0.0625rem] bg-gray-100 after:h-0;
}

.vendorEnhanceButton {
  @apply flex flex-row items-center gap-1 rounded-md border !border-gray-200 bg-white px-1.5 py-0.5 font-inter-medium text-xs text-gray-900;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

:global(body.dark) .vendorFormSeparationStyle {
  @apply bg-gray_100;
}
:global(body.dark) .vendorsFormInput input,
:global(body.dark) .vendorsFormInput input[type="text"],
:global(body.dark) .vendorsFormInput input[type="number"],
:global(body.dark) .vendorsFormInput select {
  @apply !border-[#FFFFFF1A] !bg-mirage !text-lavender_grey placeholder-gray_400 focus:!border-jet disabled:!text-gray_400 disabled:!placeholder-gray_400;
}

:global(body.dark) .vendorsWithoutBorderInput input {
  @apply !bg-transparent !text-lavender_grey placeholder-gray_300 disabled:!text-gray_300;
}

:global(body.dark) .vendorsWithoutBorderTextArea {
  @apply !bg-transparent !text-lavender_grey placeholder-gray_300 disabled:!text-gray_300;
}

:global(body.dark) .vendorsTextXLInput,
.vendorsTextXLInput input {
  @apply text-xl;
}

:global(body.dark) .vendorsTextSMInput,
.vendorsTextSMInput input {
  @apply !text-battleshipgray;
}

:global(body.dark) .vendorsFormInput input[type="number"]::placeholder {
  @apply !text-gray_900 disabled:!text-gray_400 disabled:!placeholder-gray_400;
}

:global(body.dark) .vendorsWithoutBorderInput input {
  @apply !bg-transparent !text-lavender_grey placeholder-gray_300 disabled:!text-gray_300;
}

:global(body.dark) .vendorsFormInputTextArea {
  @apply !border-[#FFFFFF1A] !bg-mirage !text-lavender_grey placeholder:text-gray_400 focus:!border-[#FFFFFF1A] disabled:!text-gray_400;
}

:global(body.dark) .vendorsFormInputTextArea::placeholder {
  @apply !bg-mirage !text-gray_300 disabled:!text-gray_400;
}

:global(body.dark) .vendorEnhanceButton {
  @apply !border-[#FFFFFF1A] !bg-mirage !text-gray_900;
}

:global(body.dark) .vendorsFormEdit input,
:global(body.dark) .vendorsFormEdit input:focus {
  @apply !border-dodgerblue;
}
:global(body.dark) .vendorsFormEditTextArea,
:global(body.dark) .vendorsFormEditTextArea:focus {
  @apply !border-dodgerblue;
}

/* vendor sidebar menu */
.vendor_status_parent_container {
  @apply grid gap-3 border-b border-gray-200 p-4 leading-none dark:border-jet;
}
.tasks_reminder_parent_container {
  @apply grid gap-3 border-b border-gray-200 p-4 leading-none dark:border-jet;
}
.related_risks_parent_container {
  @apply grid gap-3 border-b border-gray-200 p-4 leading-none dark:border-jet;
}
.related_control_parent_container {
  @apply grid gap-3 p-4 leading-none;
}
.vendor_menu_title_ {
  @apply font-inter-medium text-sm font-medium;
}
.vendor_status_container {
  @apply inline-flex;
}
.vendor_status_dot {
  @apply h-[9px] w-[9px] rounded-full bg-blue-500;
}
.tasks_reminder_title_container {
  @apply flex !cursor-pointer items-center justify-between;
}
.tasks_action_buttons_container {
  @apply flex items-center gap-2;
}
.tasks_reminders_parent_container {
  @apply grid gap-1.5 rounded-lg bg-gray-50 p-2;
}
.tasks_reminder_container {
  @apply flex items-center justify-between gap-2 overflow-hidden;
}
.tasks_reminder_status_container {
  @apply flex w-[40%] items-center gap-2;
}
.status_title_container {
  @apply w-[calc(100%-1.5rem)] overflow-hidden text-ellipsis whitespace-nowrap font-inter-medium text-xs;
}
.tasks_reminder_status_actions {
  @apply flex items-center gap-2;
}
.tasks_reminder_status_timeline {
  @apply font-inter-medium text-xs text-aurometalsaurus;
}
.workflow_title_container {
  @apply flex items-center justify-between;
}
.related_risks_title_container {
  @apply flex cursor-pointer items-center justify-between;
}
.related_risks_container {
  @apply flex flex-col gap-1.5 rounded-lg bg-gray-50 p-2;
}
.related_risk_action_container {
  @apply flex items-center gap-2;
}
.related_risk_counter {
  @apply h-5 w-[26px] rounded-md px-1.5 py-0.5 font-inter-medium text-xs;
}
.workflow_link_container {
  @apply font-inter-medium text-xs text-newcar;
}
.related_control_title_container {
  @apply flex !cursor-pointer items-center justify-between;
}
.related_action_buttons_container {
  @apply flex items-center gap-2;
}
.related_control_child_container {
  @apply grid gap-1.5 rounded-lg bg-gray-50 p-2;
}
.related_control_title {
  @apply w-[3.125rem] font-inter-medium text-xs;
}

.related_control_risk {
  @apply w-full font-inter-medium text-xs;
}

.vendor_menu_union_icon > path {
  @apply !stroke-none;
}
.vendor_risk_level_union_icon > path {
  @apply !fill-[#f05252] !stroke-none;
}
.vendor_details_menu_center_modal > div > div {
  @apply h-full md:!min-h-[calc(100vh-9rem)] md:max-w-[64rem];
}

.status_title_related_container {
  @apply w-[calc(100%-3.125rem)] font-inter-medium text-xs;
}

.assessmentKnowVendorReport [role="tablist"] {
  @apply w-[25%] bg-transparent;
}

.assessmentKnowVendorReport [role="tablist"] button {
  @apply border-b-2 border-r-0 border-t-0 border-richblack bg-white pl-0;
}

:global(body.dark) .assessmentKnowVendorReport [role="tablist"] {
  @apply border-b-kettleman;
}

:global(body.dark) .assessmentKnowVendorReport [role="tablist"] button {
  @apply !border-lynxwhite bg-transparent outline-richblack;
}
