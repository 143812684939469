.sidebar {
  @apply fixed top-16 h-full border-r border-brightgray bg-white pt-4 dark:border-thunders maxMd:h-full maxMd:pt-0;
  z-index: 1;
}
.sidebarContent {
  @apply overflow-y-auto md:px-[18px] md:pt-2.5;
}
.sidebarContent::-webkit-scrollbar {
  @apply w-1;
}
.sidebarContent ul li a {
  @apply mac13Inch:text-sm;
}
.sidebarContent ul li a:hover span {
  @apply text-mirage;
}
.sidebarContent ul li a:hover svg path {
  @apply !fill-mirage;
}
:global(body.dark) .sidebarContent ul li a svg path {
  @apply fill-greychateau;
}

.sidebarContent ul li ul li a:hover {
  @apply text-mirage;
}

.sidebarToggle:hover,
.sidebarToggle:hover .sidebarBottom,
.sidebarFullWidth {
  width: 15.625rem;
  transition: width 0.2s;
  z-index: 11;
  @apply border-r border-brightgray bg-white maxMd:z-20 maxMd:w-full;
}
.sidebarToggle .sidebarTop img {
  max-width: inherit;
  @apply h-7 w-7;
}

.sidebarToggle .sidebarTop {
  @apply border-b-0;
}
.sidebarToggle .sidebarContent {
  @apply px-2.5;
}

:global(body.dark) .sidebarToggle:hover {
  @apply border-thunders bg-darkjunglegreenss;
}
:global(body.dark) .sidebarBottom {
  @apply !border-thunders !bg-darkjunglegreenss;
}
:global(body.dark) .sidebarToggle:hover h3 {
  @apply text-white;
}
:global(body.dark) .sidebarToggle:hover .sidebarMenu {
  @apply hover:bg-balticsea;
}
.sidebarToggle {
  width: 3.75rem;
}
@screen mac13Inch {
  .sidebarBottom h3 {
    @apply text-sm;
  }
}
.sidebarSetting {
  @apply mt-1 px-5 py-3.5 hover:bg-antiflashwhite;
}
:global(body.dark) .sidebarSetting:hover {
  @apply bg-balticsea;
}

.sidebarToggle .sidebarSetting {
  @apply mb-1 !p-3 !py-0;
}

.sidebarTop {
  @apply relative flex w-full items-center justify-between border-b border-brightgray px-4 pb-4 maxMd:bg-ghostwhite maxMd:py-5;
}
:global(body.dark) .sidebarTop {
  @apply border-thunders maxMd:bg-thunders;
}
.menuItem .sidebarTop {
  @apply maxMd:bg-white maxMd:p-4;
}

.sidebarTopProfile {
  @apply mr-3 flex h-8 w-8 items-center justify-center rounded-lg bg-antiflashwhite;
}
:global(body.dark) .sidebarTopProfile {
  @apply bg-liver text-white;
}

.sidebarToggle .sidebarTopProfile {
  @apply rounded text-xs;
  padding: 0.25rem;
}

.sidebarToggle .sidebarTopProfile img {
  @apply h-4 w-8;
}

.sidebarToggle:hover .sidebarTopDetails,
.sidebarTopDetails {
  @apply flex items-center justify-center;
}
@screen maxMd {
  .sidebarTopDetails {
    @apply justify-between;
  }
}

.sidebarToggle:hover .sidebarTopDetails h3,
.sidebarTopDetails h3 {
  @apply flex items-center font-inter-semibold text-mirage;
  font-size: 1rem;
}
@screen mac13Inch {
  .sidebarTopDetails h3 {
    @apply text-sm;
  }
}

.sidebarTopDetails h3 {
  word-break: break-word;
  line-height: 22px;
}

.sidebarToggle:hover .upgrade,
.upgrade {
  @apply !block border-t border-brightgray px-5 py-4 text-center;
}
:global(body.dark) .upgrade {
  @apply !border-thunders;
}
:global(body.dark) .upgrade h3 {
  @apply text-red-300;
}

.sidebarToggle .upgrade {
  @apply !hidden;
}

.sidebarToggle:hover .upgrade p,
.upgrade p {
  @apply mb-7 mt-0.5 text-xs text-aurometalsaurus;
}

/* sidebar menu CSS */
.menu {
  @apply absolute left-0 top-0 z-30 rounded-md border border-brightgray bg-white shadow-cardShadow;
  width: 16.5rem;
}
:global(body.dark) .menu {
  @apply border-thunders bg-darkjunglegreenss;
}
@screen maxSm {
  .menu {
    width: calc(100% - 32px);
  }
}

@screen maxMd {
  .menu {
    @apply left-4 top-[70px];
  }
}

.menuItem {
  @apply m-0 max-h-64 overflow-y-auto overflow-x-hidden p-0;
}
.menuItem .sidebarTop .sidebarTopDetails h3 {
  @apply block;
}
.menuOptions {
  @apply flex w-full items-center border-b border-antiflashwhite bg-ghostwhite md:px-3.5 maxMd:!px-4 maxMd:!py-5;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

:global(body.dark) .menuOptions {
  @apply border-thunders bg-balticsea;
}
.sidebarToggle:hover .sidebarBottomContent,
.sidebarBottomContent {
  @apply flex items-center justify-between px-5;
}

.sidebarToggle .sidebarBottomContent {
  @apply hidden;
}

.sidebarToggle:hover .sidebarSettingContent,
.sidebarSettingContent {
  @apply ml-3 block font-inter-medium text-base text-mirage;
}

.sidebarToggle .sidebarSettingContent {
  @apply hidden;
}

.sidebarToggle .sidebarSettingContainer {
  @apply justify-center rounded-lg py-2 hover:bg-gray-100;
}
:global(body.dark) .sidebarToggle .sidebarSettingContainer:hover {
  @apply bg-balticsea;
}

.sidebarSettingContainer {
  @apply flex;
}

.sidebarToggle:hover .sidebarMenu,
.sidebarMenu {
  @apply flex items-center rounded-lg px-1.5 py-1 text-base font-normal text-gray-900 md:hover:bg-gray-100 md:hover:text-greychateau;
}
@screen maxMd {
  .sidebarMenu {
    @apply rounded-none border-b border-antiflashwhite px-4  py-3;
  }
}

:global(body.dark) .sidebarMenu {
  @apply hover:bg-balticsea maxMd:border-thunders;
}

:global(body.dark) .sidebarMenu:hover span {
  @apply !text-white;
}

:global(body.dark) .sidebarMenu:hover svg path {
  @apply !fill-white;
}

.sidebarToggle .sidebarMenu {
  @apply px-0;
}

.sidebarToggle .sidebarMenuItem {
  @apply hidden;
}

.sidebarToggle:hover .sidebarMenuItem,
.sidebarMenuItem {
  @apply ml-3 block text-aurometalsaurus;
}
:global(body.dark) .sidebarToggle:hover .sidebarMenuItem {
  @apply text-greychateau;
}

.sidebarToggle .sidebarMenuArrow {
  @apply hidden;
}

.sidebarToggle:hover .sidebarMenuArrow,
.sidebarMenuArrow {
  @apply ml-auto block h-6 w-6;
}

.sidebarToggle:hover .sidebarBottomButtontoggle,
.sidebarFullWidth .sidebarBottomButtontoggle {
  @apply hidden;
}

.sidebarBottomButtontoggle {
  @apply px-3;
}

.sidebarToggle:hover .sidebarTopProfileArrow,
.sidebarTopProfileArrow {
  @apply ml-2 block;
}
.sidebarTopProfileArrow path {
  @apply h-6 w-6;
}
.sidebarToggle .sidebarTopProfileArrow {
  @apply hidden;
}

.sidebarDetails {
  @apply flex;
}
@screen maxMd {
  .sidebarDetails :global(.avatar_regular) {
    @apply h-6 w-6;
  }
}

.sidebarToggle ul svg {
  @apply mx-auto mac13Inch:max-h-[20px] mac13Inch:max-w-[20px];
}

.sidebarToggle:hover ul svg {
  @apply m-0;
}
.sidebarToggle .sidebarSettingContainer {
  @apply w-full;
}

.switchCompanyModal button[aria-label="Close"] {
  @apply hidden;
}
.switchCompanyModal div:first-child {
  @apply maxMd:flex maxMd:flex-col maxMd:justify-center;
}
.sidebarMenu svg:first-child {
  @apply h-5 w-5;
}
.sidebarToggle .sidebarTopDetails h3 {
  @apply hidden;
}

.menuItem .sidebarTopDetails {
  width: calc(100% - 44px);
}
@screen maxMd {
  .menuItem .sidebarTopDetails {
    width: calc(100% - 36px);
  }
}

.sidebarToggle :global(.avatar_md) {
  @apply h-7 w-7;
}
.sidebarToggle:hover :global(.avatar_md) {
  @apply h-12 w-12;
}
.sidebarToggle:hover :global(.avatar_md) img {
  @apply h-12 w-12;
}
.sidebarTopDetails > div {
  width: calc(100% - 48px);
}
.sidebarTopDetails > div h3 {
  width: calc(100% - 44px);
}
.sidebarToggle .sidebarTop {
  @apply px-3.5;
}
@screen maxMd {
  .sidebarTopDetails :global(.avatar_md) {
    @apply h-10 w-10;
  }
}
