.settingModal div:first-child {
  max-width: 78rem;
}

:global(body.dark) .settingModal > div > div {
  @apply !bg-darkjunglegreenss;
}

@screen maxMd {
  .settingModal {
    @apply !h-full;
  }
  .settingModal > div > div {
    @apply h-full max-h-full;
  }
}

.settingModalBody {
  @apply overflow-visible !p-0 mac13Inch:!pb-4;
}
.settingModal {
  @apply maxMd:items-start;
}
.account_content {
  @apply border-b border-brightgray py-4 pl-11 pr-6 maxMd:!py-5 maxMd:px-4 mac13Inch:pl-6;
}
:global(body.dark) .account_content {
  @apply border-thunders;
}

.settingModalHeader {
  @apply !p-5 !pl-11 maxMd:!p-4 maxMd:py-2.5 mac13Inch:items-center mac13Inch:px-6 mac13Inch:py-4;
}
.settingModalHeader button {
  @apply text-gray-400;
}
.settingModalHeader h3 {
  @apply font-sans-medium text-lg text-mirage mac13Inch:text-base;
}

.account_details {
  @apply !mt-3 flex justify-start maxMd:flex-col;
}

.settingModalBody label {
  @apply text-base mac13Inch:text-sm;
}

.account_details div {
  @apply justify-start;
}

.account_details img {
  @apply mr-5 h-28 w-28;
}

.account_content_profile {
  @apply mr-4 flex h-28 w-28 items-center rounded bg-antiflashwhite text-2xl;
}

.manage_teams_tabs p,
.manage_teams_tabs h3 {
  @apply mt-2 text-sm font-light tracking-wide text-aurometalsaurus;
}

:global(body.dark) .manage_teams_tabs p {
  color: #a6a6a7 !important;
}

.settingModalBody p {
  @apply text-sm font-light tracking-wide text-aurometalsaurus;
}

.manage_teams_tabs p {
  @apply mt-0;
}
.manage_teams_tabs > div button {
  line-height: 0px;
  line-height: normal !important;
  @apply font-inter-medium text-base;
}
.manage_teams_tabs > div button span {
  @apply pr-0 font-inter-regular text-aurometalsaurus;
}
.manage_teams_tabs > button {
  @apply pl-0;
}
.manage_teams_tabs > button:first-child {
  @apply maxMd:pr-2.5;
}

.account_content input {
  max-width: 42.75rem;
}

.main_content {
  @apply px-4 py-4 md:px-6;
}
.main_content p {
  @apply mt-0;
}

.change_password_description {
  @apply text-base text-aurometalsaurus;
}

@screen md {
  .change_password_description::after {
    @apply absolute left-0 w-full border-b border-brightgray bg-red-500;
    content: "";
    top: 7.563rem;
  }
}

.company_settings .account_details img {
  width: 5rem !important;
  height: 5rem !important;
}

.close_btn {
  @apply absolute right-2.5 top-11;
}

.price_card {
  @apply flex cursor-pointer flex-col justify-between rounded-lg border border-brightgray px-4 py-8 text-center maxMd:px-4 minMaxMd:px-4;
}
:global(body.dark) .price_card {
  border-color: #3a3a3c;
}
:global(body.dark) .price_card p {
  @apply text-greychateau;
}
.price_card:hover {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.upgrade_payment > div {
  @apply my-5;
}
.upgrade_payment .selectCountry select {
  @apply text-aurometalsaurus;
}
@screen maxMd {
  .upgrade_payment {
    @apply !h-full;
  }
  .upgrade_payment > div > div {
    @apply h-full max-h-full;
  }
}

:global(body.dark) .upgrade_payment .selected select,
:global(body.dark) .upgrade_card_details .selected select,
:global(body.dark) .upgrade_card_details .selectCountry select {
  background-color: #28282a !important;
  border-color: #3a3a3c !important;
  color: #646466 !important;
}
:global(body.dark) .upgrade_payment .selectCountry select {
  @apply bg-balticsea text-stormdust;
}
.upgrade_payment .selected select {
  @apply text-gray-900;
}
.paymentIcon {
  @apply mb-4 mt-2 flex items-center;
}
.paymentIcon img {
  @apply mr-2 h-6 w-6 object-contain;
}
.upgradeModal {
  @apply overflow-y-auto !p-0;
  height: calc(100vh - 10.625rem);
}

.account_details {
  @apply flex items-center;
}

.account_details img {
  @apply mr-4 h-20 w-20;
}

.manage_teams input,
.manage_teams_tabs input {
  @apply !pl-9;
  max-width: 21.875rem;
}

.manage_teams img,
.manage_teams_tabs img {
  top: 0.813rem;
}

.manage_teams_header,
.team_add_header {
  @apply border-none pb-0;
}
.manage_teams_header h3 {
  @apply flex items-center;
}
.manage_teams_tabs button {
  @apply text-mirage;
}
.manage_teams_tabs button:nth-child(2),
.manage_teams_tabs button:nth-child(3) {
  @apply px-2.5 py-3.5 md:px-5;
}
.manage_teams_tabs {
  @apply -mt-2.5;
}
:global(body.dark) .manage_teams_tabs {
  @apply border-thunders;
}
:global(body.dark) .manage_teams_tabs button {
  @apply text-greychateau;
}
/* .manage_teams_tabs button:hover {
  @apply border-b-transparent;
} */
.team_list {
  @apply overflow-x-auto;
}
.team_list > div {
  @apply shadow-none;
}
.team_list thead th {
  @apply normal-case !important;
}

.team_list tbody td:first-child {
  @apply md:pl-11 mac13Inch:pl-6;
}
.team_list tr td {
  @apply text-aurometalsaurus;
}
.team_list tr td {
  @apply text-palesky;
}

.manage_teams_tabs button[aria-selected="true"] {
  @apply border-b border-mirage text-mirage;
}
:global(body.dark) .manage_teams_tabs button[aria-selected="true"] {
  @apply border-white bg-transparent text-white;
}

.modalHeader {
  @apply border-none p-4 text-mirage md:p-5;
}

.table_header {
  @apply border-b border-brightgray bg-white text-xs font-bold text-mirage;
}
:global(body.dark) .table_header {
  @apply border-thunders;
}
.table_header tr th {
  @apply maxMd:!px-4;
}

td {
  @apply text-black;
}

th:first-child {
  @apply pl-4 md:pl-6;
}

.sidebarTopProfile {
  @apply mr-4 flex h-4 w-0 items-center justify-center rounded-lg bg-antiflashwhite;
  padding: 1rem 1.3rem;
}

.permissions {
  @apply -mx-4;
  padding-bottom: 15.75rem;
}

.team_add_header {
  @apply flex justify-center mac13Inch:pt-4;
}

.team_add_header h3 {
  @apply mt-8 text-center;
}
.form .account_content:last-child {
  @apply border-b-0;
}

@screen maxMd {
  .sidebar_custom {
    @apply flex items-center justify-evenly;
  }
  .sidebar_custom li {
    @apply !mt-0 inline-block;
  }
  .sidebar_custom li a {
    @apply rounded p-1 hover:bg-transparent;
  }
  .sidebar_custom li a > span {
    padding: 0px;
  }
}
.manage_teams_tabs button {
  @apply border-r-0 border-t-0;
}

.search_img {
  @apply pointer-events-none absolute left-3 top-3 h-[18px] w-[18px] mac13Inch:!top-[0.6rem];
}
.search_block {
  @apply relative flex items-end gap-4;
}
.search_field {
  @apply w-full;
}
.search_field input {
  @apply cursor-text !pl-9;
}
.billingTable td {
  @apply text-sm;
}
.billingTable td:first-child {
  @apply w-[35%] font-inter-medium text-mirage;
}
.billingTable td:nth-child(2) {
  @apply w-[50%] text-davygrey;
}
:global(body.dark) .billingTable td {
  @apply text-greychateau;
}

.billingTable td:last-child {
  @apply w-1/5 text-center font-inter-medium text-mirage;
}
.billingTable tr {
  @apply mac13Inch:px-6;
}

.upgradeList li span {
  @apply ml-1 text-left text-sm text-davygrey;
}
:global(body.dark) .upgradeList li span {
  color: #a6a6a7;
}

.general_body input {
  max-width: 100%;
  padding-left: 10px !important;
}
.general_body > div button {
  @apply !border-r !border-t;
}
.general_body h4,
.general_body label {
  @apply mac13Inch:text-sm;
}

.general_header h3 {
  @apply font-sans-medium text-[18px] text-mirage mac13Inch:text-base;
}

.account_header {
  @apply font-inter-semibold mac13Inch:items-center mac13Inch:px-6 mac13Inch:py-4;
}
:global(body.dark) .account_header {
  @apply border-thunders;
}
.account_header h3 {
  @apply font-sans-medium text-[18px] text-mirage mac13Inch:text-base;
}
.setPasswordHeader {
  @apply mac13Inch:items-center;
}
.setPasswordHeader h3 {
  @apply text-lg text-mirage mac13Inch:text-base;
}

.upgrade_modal {
  @apply relative md:w-1/2 md:pr-11 mac13Inch:pr-7;
}

.upgrade_modal::before {
  content: "";
  @apply absolute right-0 h-full border-brightgray md:border-r;
}

.upgrade_modal::after {
  content: "";
  @apply absolute right-0 h-full border-brightgray md:border-r;
  top: 100%;
}
:global(body.dark) .upgrade_modal::before,
:global(body.dark) .upgrade_modal::after,
:global(body.dark) .upgrade_summary li {
  @apply border-thunders;
}

.upgrade_summary {
  @apply relative md:h-full md:w-1/2 md:pl-11;
}

.upgrade_summary li {
  @apply border-b border-brightgray py-4 md:py-[18px];
}

.upgrade_summary li p {
  @apply text-sm text-aurometalsaurus;
}

.upgrade_summary li h4 {
  @apply text-sm text-mirage;
}

:global(body.dark) .upgrade_summary li p {
  @apply text-greychateau;
}

:global(body.dark) .upgrade_summary li h4 {
  @apply text-white;
}

.upgrade_summary li:last-child {
  @apply border-none py-0;
}

.upgrade_payment_modal {
  @apply rounded-md p-3 md:flex md:overflow-y-auto md:p-12 maxMd:!max-h-full mac13Inch:p-6;
  max-height: 550px;
}
.modalHeader {
  @apply maxMd:!p-4 mac13Inch:items-center mac13Inch:!py-4;
}

.modalHeader h3 {
  @apply font-sans-medium text-[18px] text-mirage mac13Inch:text-base;
}

.upgrade_card_details h3 {
  @apply font-inter-semibold text-lg text-mirage;
}

.sso_permissions h4,
.sso_permissions span {
  @apply font-inter-medium text-sm;
}

.sso_permissions li {
  @apply flex items-center justify-between border-b border-brightgray py-3;
}
:global(body.dark) .sso_permissions li {
  @apply border-thunders;
}

.sso_permissions .sso_permissions_dropdown li {
  @apply border-none py-2;
}

.sso_permissions svg {
  @apply rotate-90;
}

.sso_permissions button,
.sso_permissions_dropdown li {
  @apply text-sm text-aurometalsaurus;
}

:global(body.dark) .sso_permissions button,
:global(body.dark) .sso_permissions_dropdown li {
  @apply text-greychateau;
}

.sso_permissions .no_access button svg {
  @apply text-aurometalsaurus;
}

.sso_permissions .no_access button {
  @apply !text-lavared;
}

.group_container {
  @apply ml-10 mr-4 flex justify-end text-aurometalsaurus;
}

:global(body.dark) .group_container .permission_dropdown {
  @apply !bg-balticsea;
}

:global(body.dark) .group_container .permission_dropdown li:hover {
  @apply !bg-iridium;
}

.group_container svg:last-child {
  @apply hidden;
}
.creatableSelect svg {
  @apply h-5 w-5 fill-lightSilver;
}

.creatableSelect .css-wsp0cs-MultiValueGeneric {
  @apply text-mirage;
}

.remove_team_member path {
  @apply fill-santagrey;
}

.team_member_permission_modal {
  @apply pt-20;
}

.themeToggle {
  @apply h-6 w-11 rounded-full border border-brightgray bg-gray-200 after:absolute after:left-[4px] after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-mirage peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600 dark:bg-gray-700;
}

.themeToggle::after {
  width: 1.125rem;
  height: 1.125rem;
  top: 0.188rem;
  background-image: url("../images/sidebar/light-theme.svg");
  background-position: center;
  background-size: 0.8rem 0.8rem;
  background-repeat: no-repeat;
}
.themeToggle.themeSwitchToggle::after {
  left: 20px;
}
.remove_team_member path {
  @apply fill-santagrey;
}

.team_member_permission_modal {
  @apply pt-20;
  z-index: 1000000;
}

.setting_risks {
  @apply px-11 py-4;
}

.presetTitle {
  @apply flex items-center gap-2 pb-4;
}

.risk_divider {
  @apply my-4 inline-block h-[1px] w-full border border-brightgray;
}

.presetStatus {
  @apply rounded-lg bg-antiflashwhite px-3 py-1 text-xs text-aurometalsaurus;
}
:global(body.dark) .presetStatus {
  @apply bg-iridium text-greychateau;
}

.levelLabel {
  @apply mb-2 flex items-center gap-4 text-sm text-aurometalsaurus;
}
.levelTitle,
.presetLevelTitle label {
  @apply text-sm font-medium;
}
:global(body.dark) .levelTitle {
  @apply text-white;
}

.levelValue {
  @apply !text-sm text-aurometalsaurus;
}
:global(body.dark) .levelValue {
  @apply text-greychateau;
}
.setting_risks hr::after {
  @apply bg-brightgray;
}
:global(body.dark) .setting_risks hr::after {
  @apply bg-thunders;
}
.manage_teams_tabs > button:first-child {
  @apply !px-5;
}

.presetActionDropdown {
  @apply absolute left-6 top-0 w-44 list-none rounded-lg border border-gray-200 bg-white py-1 text-left text-sm font-medium  text-gray-900;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

:global(body.dark) .presetActionDropdown {
  @apply border-thunders bg-balticsea;
}
.presetActionDropdown li {
  @apply flex cursor-pointer items-center gap-2 px-4 py-2 text-aurometalsaurus  hover:bg-gray-100;
}
:global(body.dark) .presetActionDropdown li {
  @apply text-greychateau hover:bg-darkjunglegreenss;
}
:global(body.dark) .presetActionIcon:hover svg path {
  @apply !fill-white;
}

.presetActionIcon:hover svg path {
  @apply !fill-mirage;
}

/* new setting design */
.sidebar_component {
  @apply h-full rounded-lg bg-white;
}
:global(body.dark) .sidebar_component {
  @apply bg-darkjunglegreenss;
}
.company_setting_card {
  @apply overflow-y-auto;
}

.setting_header {
  max-width: 1306px;
  @apply mx-auto;
}
.setting_header h3 {
  @apply px-5 text-2xl;
}
.setting_container {
  max-width: 1265px;
  @apply mx-auto;
}
.setting_modal {
  @apply mt-5 flex gap-4 pb-8;
}
.setting_container .setting_sidebar {
  @apply flex w-[249px] flex-col gap-4;
}

.setting_container .setting_body {
  width: calc(100% - 265px);
}

.setting_card {
  @apply rounded-lg bg-white p-4;
}
:global(body.dark) .setting_card {
  @apply bg-darkjunglegreenss;
}

.setting_card > ul {
  @apply grid;
}

.setting_card .sidebar_item {
  @apply cursor-pointer items-center !p-2;
}
.setting_card .sidebar_item:not(:first-child) {
  @apply !mt-2;
}
:global(body.dark) .setting_card .sidebar_item {
  @apply text-greychateau;
}
:global(body.dark) .setting_card .sidebar_item span svg path {
  @apply stroke-greychateau;
}

.setting_card .sidebar_item:hover,
.setting_header .setting_navigation a:hover {
  @apply rounded-md bg-ghostwhite;
}
:global(body.dark) .setting_card .sidebar_item:hover,
:global(body.dark) .setting_header .setting_navigation a:hover {
  @apply bg-thunders;
}
.setting_card .sidebar_item_active {
  @apply rounded-md;
}

.setting_card .sidebar_item_active svg path,
.setting_card .sidebar_item_active {
  @apply stroke-crayolasblue text-crayolasblue;
}

:global(body.dark) .setting_card .sidebar_item_active span svg path {
  @apply stroke-crayolasblue;
}

:global(body.dark) .setting_card .sidebar_item_active {
  @apply text-crayolasblue;
}

.setting_header .setting_navigation a:hover {
  @apply rounded-full bg-antiflashwhite;
}
.setting_card .setting_title {
  @apply mb-4 text-santagrey;
}
:global(body.dark) .setting_card .setting_title {
  @apply text-stormdust;
}

.setting_header .setting_navigation {
  @apply py-5;
}

.setting_header .setting_navigation a {
  @apply flex w-fit items-center gap-3  !px-5 !py-3;
}

.setting_card .sidebar_item {
  @apply flex gap-3;
}

.sidebar_icon {
  @apply grid h-6 w-6 place-items-center;
}

.sidebar_icon_rotate {
  transform: rotate(180deg);
}

/* my account  */
.setting_layout .layout_card_info {
  @apply border-b border-brightgray py-6 pl-5 pr-5;
}
:global(body.dark) .setting_layout .layout_card_info {
  @apply border-thunders;
}
.setting_layout .layout_card_info input,
.setting_layout .layout_card_info .card_info_input {
  max-width: 42.75rem;
}

.setting_layout .layout_card_info:last-child {
  @apply border-b-0;
}

/* groups */
.create_groupContainer {
  width: 864px;
  @apply rounded-lg bg-white p-8;
}
.create_groupContainer .group_changeIcon {
  @apply mt-3 flex items-center gap-2 rounded-full border border-mirage px-3 py-2 text-sm text-mirage;
}
:global(body.dark) .create_groupContainer {
  @apply bg-darkjunglegreenss;
}

.group_changeIcon svg path {
  @apply !stroke-mirage;
}

:global(body.dark) .create_groupContainer .group_changeIcon svg path {
  @apply !stroke-white;
}

:global(body.dark) .create_groupContainer .group_changeIcon {
  @apply border-white text-white;
}

.create_step_one {
  @apply pt-6;
}

.create_step_one_footer {
  @apply mt-4 flex justify-between;
}

.create_group_wrapper {
  max-width: 864px;
  height: 100vh;
  @apply mx-auto grid items-center;
}

.create_groupContainer .create_groupHeader {
  @apply flex bg-ghostwhite;
}

:global(body.dark) .create_groupHeader {
  @apply bg-balticsea;
}

.group_invitation_container {
  @apply py-6;
}

.group_invitation_container .add_more_icon path {
  @apply !fill-mirage !stroke-none;
}

:global(body.dark) .group_invitation_container .add_more_icon path {
  @apply !fill-white !stroke-none;
}

.email_container {
  @apply my-4 grid gap-4;
}

.group_invitation_container .add_more_icon path {
  @apply !fill-mirage !stroke-none;
}

:global(body.dark) .group_invitation_container .add_more_icon path {
  @apply !fill-white !stroke-none;
}

.group_invitation_footer {
  @apply mt-6 flex justify-between;
}
.email_role select {
  @apply cursor-pointer rounded-l-none bg-antiflashwhite !p-[10.3px] text-aurometalsaurus;
}
.email_role {
  @apply flex rounded-lg;
}

.email_role > div {
  @apply !w-full;
}

:global(body.dark) .email_role select {
  @apply !bg-iridium;
}

:global(body.dark) .email_role {
  @apply border-iridium;
}

.withoutSidebarWrapper {
  height: calc(100vh - 4rem);
  @apply flex;
}

.withoutSidebarWrapper > div {
  @apply m-auto flex items-center justify-center py-5;
}
.successIcon path {
  @apply !stroke-white;
}
.create_group_icon path {
  @apply !fill-white !stroke-none;
}

:global(body.dark) .create_group_icon path {
  @apply !fill-mirage;
}
.group_avatar {
  font-size: 30px;
  @apply my-2 grid h-20 w-20 place-items-center rounded-lg bg-iceberg font-inter-medium text-bondiBlue;
}
/* member  */
.new_member_list > div {
  @apply shadow-none;
}
.new_member_list > div {
  overflow: initial;
  @apply maxMd:overflow-auto;
}
.new_member_list > div {
  @apply h-full;
}
.new_member_list > td {
  @apply p-0 text-mirage;
}
.new_member_list th {
  @apply py-4 text-sm;
}
.new_member_list td,
.new_member_list th {
  @apply normal-case;
}
.new_member_list tbody tr:hover {
  @apply bg-antiflashwhite;
}

.new_member_list tr td {
  @apply relative text-aurometalsaurus;
}

.new_member_list td:first-child,
.new_member_list th:first-child {
  @apply !pl-11 maxMd:!pl-4 mac13Inch:!pl-6;
}
.new_member_list tr td:last-child {
  @apply px-5;
}

.role_dropdown {
  @apply absolute right-0 top-10 z-10 my-1 grid max-h-40 w-[220px]  cursor-pointer overflow-y-auto rounded-md border border-antiflashwhite bg-white  font-inter-medium;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
}
.role_dropdown li {
  @apply hover:bg-antiflashwhite;
}
.new_member_list tr:last-child .role_dropdown {
  @apply -top-40;
}

.new_member_list tr:first-child .role_dropdown {
  @apply !top-10;
}

:global(body.dark) .role_dropdown li {
  @apply hover:bg-iridium;
}
:global(body.dark) .role_dropdown {
  @apply border-thunders bg-darkjunglegreenss text-greychateau;
}
.role_dropdown li {
  @apply px-4  py-2 hover:bg-antiflashwhite;
}
.new_member_list tr:last-child .role_dropdown {
  @apply -top-40;
}

.new_member_list tr:first-child .role_dropdown {
  @apply !top-10;
}

:global(body.dark) .role_dropdown li {
  @apply hover:bg-iridium;
}
:global(body.dark) .role_dropdown {
  @apply border-thunders bg-darkjunglegreenss text-greychateau;
}
.role_dropdown li {
  @apply px-4  py-2 hover:bg-antiflashwhite;
}
.new_member_list tr:last-child .role_dropdown {
  @apply -top-40;
}

.new_member_list tr:first-child .role_dropdown {
  @apply !top-10;
}

:global(body.dark) .role_dropdown li {
  @apply hover:bg-iridium;
}
:global(body.dark) .role_dropdown {
  @apply border-thunders bg-darkjunglegreenss text-greychateau;
}

.active_member_role {
  @apply bg-antiflashwhite;
}

.create_icon path {
  @apply !fill-aurometalsaurus !stroke-none;
}

:global(body.dark) .create_icon path {
  @apply !fill-greychateau;
}

.email_input_gray input {
  @apply rounded-lg border bg-ghostwhite p-3 pl-[2.4rem];
}
:global(body.dark) .email_input_gray input,
.email_input input {
  @apply bg-balticsea;
}
.email_input input {
  @apply bg-red-50 text-red-900;
}
.warningIcons path {
  @apply !stroke-lavared;
}
:global(body.dark) .rolesDownIcon path {
  @apply fill-greychateau;
}

.rolesDownIcon path {
  @apply !stroke-none;
}

.member_email_icon {
  @apply absolute left-14;
}

.member_email_icon path {
  @apply fill-aurometalsaurus !stroke-none;
}
:global(body.dark) .member_email_icon path {
  @apply fill-greychateau;
}

.create_icon path {
  @apply !fill-aurometalsaurus !stroke-none;
}

:global(body.dark) .create_icon path {
  @apply !fill-greychateau;
}

.email_input_gray input {
  @apply rounded-lg border bg-ghostwhite p-3 pl-[2.4rem];
}
:global(body.dark) .email_input_gray input,
.email_input input {
  @apply bg-balticsea;
}
.email_input input {
  @apply bg-red-50 text-red-900;
}
.warningIcons path {
  @apply !stroke-lavared;
}
:global(body.dark) .rolesDownIcon path {
  @apply fill-greychateau;
}

.rolesDownIcon path {
  @apply !stroke-none;
}

.member_email_icon {
  @apply absolute left-14;
}

.member_email_icon path {
  @apply fill-aurometalsaurus !stroke-none;
}
:global(body.dark) .member_email_icon path {
  @apply fill-greychateau;
}

.group_loader {
  @apply pointer-events-none opacity-30;
}
