.subscriptionTitle {
  font-family: "GrandSansDisplay";
  @apply text-2xl text-mirage maxMd:pb-2;
}
@screen mac13Inch {
  .subscriptionTitle {
    @apply text-lg;
  }
}
:global(body.dark) .subscriptionTitle {
  @apply text-white;
}

.innerBlock {
  @apply mt-[6px] flex w-full items-center gap-y-2 lg:w-[70%] lg:gap-0 maxMd:flex-wrap;
  @apply maxLg:flex-wrap;
}

.innerBlock button span {
  @apply font-inter-medium text-sm text-aurometalsaurus;
}

:global(body.dark) .innerBlock button span {
  @apply text-greychateau;
}
:global(body.dark) .innerBlock .img_tag > div:hover > button span {
  @apply text-white;
}

:global(body.dark) .img_tag > div[aria-describedby] span,
:global(body.dark) .actionTag > div[aria-describedby] span {
  @apply text-white;
}
.innerBlock .searchField {
  @apply w-full flex-[100%] md:max-w-[37.125rem] maxLg:mb-1.5;
}
.searchField > div {
  @apply cursor-text rounded-lg bg-ghostwhite  text-sm text-gray-500;
}

:global(body.dark) .searchField > div {
  @apply bg-balticsea text-white;
}
:global(body.dark) .searchField input {
  @apply !bg-transparent !text-greychateau;
}

.innerBlock .searchField > div input {
  @apply focus:ring-0;
}

.innerBlock .searchField > div .labelTag {
  @apply flex gap-2;
}
.filterTag {
  @apply !bg-white;
}
.normalTag {
  @apply !bg-black;
}
.innerBlock .img_tag,
.innerBlock .actionTag {
  @apply flex min-w-fit cursor-pointer justify-between bg-transparent text-mirage;
}

@screen mac13Inch {
  .actionTag button img,
  .img_tag button img {
    @apply max-w-[16px];
  }
}

:global(body.dark) .innerBlock .img_tag > div:first-child {
  @apply opacity-100;
}

.subscriptionBody {
  @apply relative flex justify-between maxLg:flex-wrap;
}
:golbal(body.dark) .subscriptionBody {
  @apply bg-darkjunglegreenss;
}

.imgBlock img {
  @apply mr-5 cursor-pointer;
}
.paginationBlock ul {
  @apply mr-[0.625rem] flex gap-[0.625rem];
}
.paginationBlock > img {
  @apply mr-3 cursor-pointer;
}
.paginationBlock > p {
  @apply min-w-[7.3rem] text-sm;
}
.innerDropDown {
  @apply w-[12.5rem] p-0 !important;
}
.filterDropDown {
  @apply w-[12.5rem] border-none !py-0 !pl-0;
}
:global(body.dark) .filterDropDown,
:global(body.dark) .innerDropDown {
  @apply bg-thunders;
}
:global(body.dark) .innerDropDown ul li {
  @apply text-greychateau hover:bg-darkjunglegreenss;
}

.filterDropDown button {
  text-align: left;
  @apply flex w-full cursor-pointer items-center justify-between px-4 py-2 text-sm text-mirage  hover:bg-gray-100 !important;
}
:global(body.dark) .filterDropDown button {
  @apply !bg-thunders !text-greychateau hover:!bg-darkjunglegreenss;
}
:global(body.dark) .filterDropDown .btn_primary__imp {
  @apply hover:!text-white;
}

.filterDropDown > div {
  @apply w-full p-0 !important;
}

.filterDropDown .formLabel {
  @apply text-sm !font-semibold text-mirage;
}
.filterDropDown .formInput > div > input {
  @apply p-2.5;
}

.magicWand > div > input {
  @apply !pr-8;
}

@screen mac13Inch {
  .filterDropDown .formInput > div > input {
    @apply !px-3 !py-2;
  }
}

.filterDropDown .btn_primary__imp {
  @apply !w-auto !rounded-full !bg-mirage !px-5 !py-2.5 !text-xs !text-white hover:!bg-slate-700;
}
:global(body.dark) .filterDropDown .btn_primary__imp {
  @apply !bg-white !text-darkjunglegreens hover:!bg-greygoose hover:!text-darkjunglegreens;
}
.btn_primary__imp {
  @apply !w-auto !rounded-full !bg-mirage !px-5 !py-2.5 !text-sm !text-white hover:!bg-slate-700 maxMd:!w-full;
}
:global(body.dark) .btn_primary__imp {
  @apply !bg-white !text-darkjunglegreens;
}

.filterDropDown .formDropDown {
  min-width: 15.625rem !important;
  @apply !overflow-hidden;
}
.innerDropDown {
  @apply !left-[13rem] !max-h-60 !w-[12.5rem] overflow-y-auto overflow-x-hidden;
}
.innerDropDown ul li {
  word-break: break-word;
  @apply text-mirage;
}
.indexDropdownWidth {
  min-width: 400px !important;
}
.indexBooleanDropdownWidth {
  @apply !min-w-[17rem];
}
.unitFilter {
  @apply mt-2 grid gap-2;
}
.unitFilter select,
.unitFilter input {
  @apply !text-xs;
}

.actionTag,
.img_tag {
  @apply ml-1 md:ml-6;
}
:global(body.dark) .actionTag > div:hover button span {
  @apply text-white;
}

@screen mac13Inch {
  .actionTag {
    @apply ml-3;
  }
  .img_tag {
    @apply ml-4;
  }
}

.img_tag > div,
.actionTag > div {
  @apply border-2 border-transparent px-[0.375rem] py-[0.313rem];
}
.filterDropDown ul div[aria-describedby],
.favoriteDropdown ul div[aria-describedby] {
  @apply !font-semibold;
}
:global(body.dark) .filterDropDown ul div[aria-describedby] button {
  @apply !bg-darkjunglegreenss !text-white;
}
:global(body.dark) .favoriteDropdown ul div[aria-describedby] button {
  @apply !bg-darkjunglegreenss !text-white;
}
.img_tag > div[aria-describedby],
.actionTag > div[aria-describedby] {
  @apply rounded-md bg-ghostwhite;
}

:global(body.dark) .img_tag > div[aria-describedby],
:global(body.dark) .actionTag > div[aria-describedby] {
  @apply bg-balticsea;
}

.img_tag > div > img {
  @apply opacity-70;
}
.img_tag > div > span {
  @apply text-sm;
}
.img_tag > div:hover span,
.img_tag > div[aria-describedby] span {
  @apply text-mirage;
}
.favoriteDropdown > div {
  padding-left: 0.375rem;
}
.favoriteDropdown > div > ul > div {
  @apply !w-full;
}
.favoriteDropdown > div > ul > div > button {
  @apply flex !w-full justify-between px-4 py-2 text-mirage hover:bg-gray-100;
}
:global(body.dark) .favoriteDropdown {
  @apply bg-thunders;
}

:global(body.dark) .favoriteDropdown > div > ul > div > button {
  @apply text-greychateau hover:!bg-darkjunglegreenss;
}
.favoriteDropdown {
  @apply !pr-1.5;
}
.actionDropDown {
  @apply absolute right-0 top-[55px] z-[11] max-h-[490px] min-w-[12.188rem] overflow-y-auto py-2 !pr-[0.375rem];
}
:global(body.dark) .actionDropDown {
  @apply bg-thunders;
}
:global(body.dark) .actionDropDown ul li {
  @apply hover:bg-darkjunglegreenss;
}
.actionDropDown button {
  @apply !rounded-none border-transparent;
}
:global(body.dark) .actionDropDown button {
  @apply border-thunders;
}
.actionDropDown input[type="checkbox"] {
  @apply !ml-0;
}

.actionDropDown .items,
.actionTag > div > button,
.img_tag > div > button {
  @apply flex items-center justify-between gap-[0.5rem];
}
.img_tag:hover :global(.themesvg_color path),
.actionTag > div[aria-describedby] :global(.themesvg_color path),
.actionTag:hover :global(.themesvg_color path),
.img_tag > div[aria-describedby] :global(.themesvg_color path) {
  @apply fill-mirage;
}
:global(body.dark) .img_tag:hover :global(.themesvg_color path),
:global(body.dark) .img_tag > div[aria-describedby] :global(.themesvg_color path),
:global(body.dark) .actionTag:hover :global(.themesvg_color path),
:global(body.dark) .actionTag > div[aria-describedby] :global(.themesvg_color path) {
  @apply fill-white;
}

.img_tag:hover,
.actionTag:hover,
.img_tag[aria-describedby],
.actionTag[aria-describedby] {
  @apply rounded-md bg-ghostwhite;
}
:global(body.dark) .img_tag:hover,
:global(body.dark) .actionTag:hover {
  @apply bg-balticsea;
}

.displayNone {
  @apply hidden !important;
}
.subscriptionHeader {
  @apply bg-white px-4 pb-[1.375rem] pt-1 md:px-5;
}
:global(body.dark) .subscriptionHeader {
  @apply bg-darkjunglegreenss;
}
.paginationBlock {
  @apply flex items-center justify-end maxMd:items-end maxMd:justify-center;
}

.paginationBlock ul {
  @apply mr-[0.625rem] flex gap-[0.625rem];
}
.paginationBlock > img {
  @apply mr-3 cursor-pointer;
}
@screen maxMd {
  .paginationBlock > img {
    filter: invert(1) brightness(0%);
  }
}
.paginationBlock > p {
  @apply min-w-[7.3rem] text-sm;
}
.toggleMenu {
  z-index: 1;
  @apply fixed mb-[1px] bg-white p-5 shadow-md;
  width: calc(100% - 15.625rem);
}

.filterDropDown::-webkit-scrollbar,
.innerDropDown::-webkit-scrollbar {
  @apply maxMd:h-1 maxMd:w-1;
}

.filterDropDown > div {
  max-height: 260px;
  overflow-y: auto;
}
.filterDropDown > div::-webkit-scrollbar {
  @apply maxMd:h-1 maxMd:w-1;
}
.filterDropDown > div ul div {
  @apply w-full;
}

.img_tag > div:hover button > div svg path,
.actionTag > div:hover > button svg path {
  @apply fill-mirage;
}

:global(body.dark) .img_tag > div:hover button > div svg path,
:global(body.dark) .actionTag > div:hover > button svg path {
  @apply fill-white;
}
:global(body.dark) .actionDropDown > div ul li:hover span {
  @apply text-greychateau;
}
.img_tag > div[aria-describedby] > button svg path,
.actionTag > div[aria-describedby] > button svg path {
  @apply fill-mirage;
}
:global(body.dark) .img_tag > div[aria-describedby] > button svg path,
:global(body.dark) .actionTag > div[aria-describedby] > button svg path {
  @apply fill-white;
}

.actionTag > div:hover button span,
.actionTag > div[aria-describedby] button span {
  @apply text-mirage;
}
:global(body.dark) .favoriteDropdown div ul li {
  @apply hover:bg-darkjunglegreenss;
}
