.modal > div > div {
  @apply dark:!bg-darkjunglegreenss;
}
.commentsList {
  @apply mt-[40px];
}
.commentsList li {
  @apply mb-4 border-b border-brightgray pb-3;
}
:global(body.dark) .commentsList li {
  @apply border-thunders;
}
.commentsList li:last-child {
  @apply mb-0 border-b-0 pb-0;
}

.commentsAvatar {
  width: 20px;
  height: 24px;
  @apply mr-[14px] rounded-full object-cover;
}
.commentsList li h1 {
  @apply text-sm font-semibold;
}
:global(body.dark) .commentsList li h1 {
  @apply text-white;
}
:global(body.dark) .commentsList li span {
  @apply text-greychateau;
}
.commentsList li span {
  min-width: 80px;
  @apply ml-auto break-words text-xs text-aurometalsaurus maxMd:min-w-[110px];
}
.commentsList li p {
  @apply text-xs font-medium leading-[18px] text-aurometalsaurus;
}

.pdfGenerate {
  @apply flex max-w-[350px] items-center rounded border border-brightgray p-3;
}
:global(body.dark) .pdfGenerate {
  @apply border-thunders bg-balticsea;
}

.pdfGenerate span {
  @apply w-full;
}
.activityLogList li {
  @apply mb-3 flex;
}

.activityLogList li p {
  @apply mb-2 text-sm font-medium leading-[21px] text-aurometalsaurus mac13Inch:mb-0;
}
:global(body.dark) .activityLogList li p:first-child {
  @apply text-stormdust;
}
:global(body.dark) .activityLogList li p {
  @apply text-greychateau;
}

.activityLogList li p b {
  @apply font-bold text-mirage;
}

.activityLogBox {
  @apply flex h-6 w-6 items-center justify-center rounded-md bg-antiflashwhite;
}
:global(body.dark) .activityLogBox {
  @apply bg-liver text-white;
}
.activityLogBox h4 {
  @apply text-xs font-bold leading-[12px];
}

.permissionsList li {
  @apply mt-5 flex;
}
.permissionsList span {
  @apply font-bold text-mirage;
}

.permissionsList li h1 {
  @apply text-sm font-bold leading-6;
}

:global(body.dark) .permissionsList li h1 {
  @apply text-white;
}

.permissionsList li p {
  @apply text-xs leading-[18px] text-aurometalsaurus;
}
:global(body.dark) .permissionsList li p {
  @apply text-greychateau;
}
.commentsTopProfile {
  @apply relative mr-3 flex h-6 w-6 items-center justify-center rounded-full bg-gainsboro;
}
.commentsTopProfile span {
  @apply flex items-center justify-center rounded text-center text-sm font-medium text-palesky;
}

.permissionsList select {
  @apply border-lightSilver !bg-transparent text-aurometalsaurus shadow-none ring-0 focus:border-transparent;
}

:global(body.dark) .permissionsList select {
  @apply !border-iridium bg-transparent text-aurometalsaurus;
}

.emojiPicker {
  @apply absolute left-[2px] top-12 z-[2] h-48 w-full rounded-lg bg-white p-4;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

:global(body.dark) .emojiPicker {
  @apply bg-darkjunglegreenss;
}
@screen maxMd {
  .modal div[role="tabpanel"] {
    @apply pb-0;
  }
}

.metadata_mobile > div {
  @apply maxMd:!flex-row;
}
