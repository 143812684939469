.header {
  @apply fixed z-10 flex w-full items-center justify-between border-b border-brightgray bg-white px-5 maxMd:px-4;
  height: 4rem;
}
.logo {
  @apply flex items-center pl-4 md:pl-6;
}

@screen mac13Inch {
  .logo img:first-child {
    @apply h-6 w-6;
  }
  .team {
    margin-right: 1.375rem;
  }
}
.teamDropdown {
  @apply absolute top-14  max-h-[10.5rem] w-56 overflow-y-auto overflow-x-hidden bg-white maxMd:right-0;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 8px 0px rgba(0, 0, 0, 0.1);
}
:global(body.dark) .teamDropdown {
  @apply bg-thunders;
}

@screen maxMd {
  .team {
    @apply mr-1.5;
  }
}

.teamName {
  @apply flex h-8 w-8 items-center justify-center rounded-md bg-antiflashwhite text-xs font-medium;
}

.teamName:hover {
  @apply text-mirage;
}

.notification {
  @apply absolute -right-[19px] top-[2.1rem] z-20 my-6 w-[414px] list-none divide-y divide-gray-100 overflow-hidden rounded bg-white text-base shadow-lg;
}
:global(body.dark) .notification {
  @apply bg-darkjunglegreenss;
}
@screen maxMd {
  .notification {
    max-width: 414px;
    height: calc(100vh - 64px);
    @apply top-6 w-auto;
  }
}
:global(body.dark) .notification {
  @apply border border-thunders;
}

.notificationBtnOpen {
  @apply self-center !important;
}

.notificationBtn {
  @apply relative flex rounded-[0.25rem] border border-transparent p-3 pr-0 maxMd:m-0;
}

.notificationBtn:hover > div svg path {
  @apply fill-mirage;
}
:global(body.dark) .notificationBtn:hover > div svg path {
  @apply fill-white;
}

.notificationBtn span {
  @apply absolute right-[-4px] top-0 text-xs;
}
.notification ul {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 124px);
}

.notification ul::-webkit-scrollbar {
  @apply w-1;
}
.notification ul::-webkit-scrollbar-thumb {
  @apply block w-4 rounded-full bg-mirage !important;
}
.teamIcon {
  @apply relative flex w-full items-center gap-[0.875rem] px-4 py-2 hover:bg-antiflashwhite;
}
.teamIcon h3 {
  @apply text-mirage;
}
:global(body.dark) .teamIcon {
  @apply hover:bg-darkjunglegreenss;
}
.teamIcon :global(.avatar) {
  @apply bg-antiflashwhite text-brightgrey dark:text-greychateau;
}
:global(body.dark) .teamIcon :global(.avatar) {
  @apply bg-iridium text-greychateau;
}

.team li {
  @apply px-0 py-0 !important;
}
:global(body.dark) .team li {
  @apply hover:!bg-darkjunglegreenss;
}
:global(body.dark) .team li:hover h3 {
  @apply text-white;
}
@screen maxMd {
  .articleSubscriptions {
    @apply hidden;
  }
  .articleSubscriptions a {
    @apply text-xs;
  }
  .articleSubscriptions img {
    transform: rotateZ(-90deg);
    @apply ml-4;
  }
}
@screen mac13Inch {
  .articleSubscriptions a {
    @apply mr-6 text-sm;
  }
}
.notificationBtn .notificationTooltiop {
  width: 8.9rem;
  z-index: 111;
}
.teamTooltip {
  top: 8px !important;
  left: -125px !important;
}
.dropdownHidden {
  @apply hidden;
}
.active_dropdown {
  @apply bg-antiflashwhite;
}

:global(body.dark) .active_dropdown {
  @apply bg-darkjunglegreenss;
}
