@font-face {
  font-family: "InterRegular";
  src: url("../fonts/Inter-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "InterMedium";
  src: url("../fonts/Inter-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "InterSemiBold";
  src: url("../fonts/Inter-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "InterBold";
  src: url("../fonts/Inter-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "GrandSansDisplay";
  src: url("../fonts/grand-sans-display-500.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HKGroteskRegular";
  src: url("../fonts/HKGrotesk-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "HKGroteskMedium";
  src: url("../fonts/HKGrotesk-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HKGroteskSemiBold";
  src: url("../fonts/HKGrotesk-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "HKGroteskBold";
  src: url("../fonts/HKGrotesk-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
