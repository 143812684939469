.modalHeader {
  @apply border-none px-6 pb-0 pt-6 maxMd:px-4 maxMd:pt-3 mac13Inch:items-center mac13Inch:!py-4;
}

:global(body.dark) .modalHeader {
  @apply !border-thunders;
}
.modalHeader h3 {
  @apply font-sans-medium text-lg text-mirage mac13Inch:text-base;
}
:global(body.dark) .modalHeader h3 {
  @apply text-white;
}

:global(body.dark) .dropdownButton svg {
  @apply text-greychateau;
}
.dropdownButton svg {
  transform: rotate(90deg);
}

.dropdownButton {
  @apply self-center;
}
.dropdownButton button {
  @apply flex w-full items-center justify-between gap-3 !bg-transparent text-black;
}
.dropdownButton button {
  @apply py-0;
}
:global(body.dark) .dropdownButton button span svg path {
  @apply !stroke-greychateau;
}
.dropdownButton button span {
  @apply pr-0 text-aurometalsaurus;
}
.dropdownButton button:focus {
  border: none;
  box-shadow: none !important;
}

.dropdownButton button:hover {
  background-color: unset !important;
}
.dropdownButton ul {
  @apply p-0 !important;
}
.dark .dropdownButton svg {
  @apply fill-white;
}
.formStyle {
  @apply relative flex justify-end p-4;
}

.membersTabs[role="tablist"] h3 {
  @apply font-inter-medium text-[16px] text-mirage;
}
.membersTabs[role="tablist"] button[role="tab"] {
  @apply border-r-0 border-t-0;
}

.membersTabs[role="tablist"] button[aria-selected="true"] {
  @apply !border-b-mirage pl-0 pr-4;
}

:global(body.dark) .tabs_details [role="tablist"] {
  @apply border-thunders;
}

.membersTabs [role="tabpanel"] {
  @apply p-0;
}

.tabs_details [role="tablist"] {
  @apply mx-6 mt-[10px];
}
.tabs_details [role="tablist"] button {
  @apply border-transparent font-inter-medium text-[16px] text-mirage;
}
:global(body.dark) .tabs_details [role="tablist"] button[aria-selected="true"] {
  @apply border-b-white bg-transparent text-white;
}

.tabs_details [role="tablist"] button[aria-selected="true"] {
  @apply border-b-mirage pl-0;
}
.tabs_details [role="tabpanel"] {
  @apply px-6 pt-0 maxMd:-mt-2;
}
.tabs_details [role="tabpanel"] > div:first-child {
  @apply p-0;
}

.permissionsList div:last-child {
  @apply border-b-0;
}
