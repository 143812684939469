/* Common form controls CSS Start */
/* Button & Link CSS start  */
.default_button,
.default_link {
  @apply px-3 font-inter-medium text-sm font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:text-santagrey;
}
.default_link {
  @apply flex;
}
.default_button:hover,
.default_link:hover {
  transition: background-color 0.3s;
}
.default_button:disabled svg path {
  @apply !fill-santagrey;
}

.button_primary,
.link_primary {
  @apply !border border-transparent bg-richblack  text-white hover:border-transparent hover:bg-midnightexpress focus:border-transparent focus:bg-midnightexpress focus:outline focus:outline-2 focus:outline-offset-0 focus:outline-brightgray active:border-transparent active:bg-blackknight active:outline-0   disabled:border-transparent disabled:bg-antiflashwhite;
}

:global(body.dark) .button_primary,
:global(body.dark) .link_primary {
  @apply border-transparent  bg-antiflashwhites text-mirage  hover:border-transparent hover:bg-steam  focus:border-transparent focus:bg-steam focus:outline focus:outline-2 focus:outline-offset-0 focus:outline-jet active:border-transparent active:bg-antiflashwhites active:text-mirage active:outline-0  disabled:border-transparent disabled:bg-raisinblack disabled:text-gray_400;
}

.button_secondary,
.link_secondary {
  @apply !border border-brightgray bg-white text-richblack shadow-buttonShadow hover:border-brightgray hover:bg-ghostwhite focus:!border-transparent focus:bg-ghostwhite focus:outline focus:outline-2 focus:outline-offset-0 focus:outline-brightgray active:!border-brightgray active:bg-antiflashwhite  active:outline-0 disabled:border-brightgray disabled:bg-white;
}

:global(body.dark) .button_secondary,
:global(body.dark) .link_secondary {
  @apply border-jet bg-mirage text-white focus:!border-transparent focus:outline  focus:outline-2 focus:outline-offset-0 focus:outline-carbon active:!border-balticSea active:bg-raisinblack active:outline-0  disabled:border-jet disabled:bg-mirage disabled:text-gray_300;
}

.button_tertiary,
.link_tertiary {
  @apply !border-2 border-transparent bg-ghostwhite text-richblack hover:border-transparent hover:bg-antiflashwhite focus:border-lightSilver  focus:bg-antiflashwhite active:border-transparent active:bg-brightgray  disabled:border-transparent  disabled:bg-ghostwhite;
}

:global(body.dark) .button_tertiary,
:global(body.dark) .link_tertiary {
  @apply border-transparent bg-mirage/95 text-gray_900 hover:border-transparent hover:bg-raisinblack focus:border-kettleman focus:bg-raisinblack active:border-transparent active:bg-jet disabled:border-transparent disabled:bg-mirage/95 disabled:text-gray_400;
}

.button_plain,
.link_plain {
  @apply !border-2 border-transparent bg-transparent text-richblack hover:border-transparent hover:bg-ghostwhite focus:border-brightgray focus:bg-ghostwhite active:border-transparent active:bg-antiflashwhite  disabled:bg-transparent;
}

:global(body.dark) .button_plain,
:global(body.dark) .link_plain {
  @apply bg-transparent text-gray_900 hover:border-transparent hover:bg-transparent focus:border-jet  focus:bg-transparent active:border-transparent active:bg-raisinblack disabled:border-transparent disabled:bg-mirage/95 disabled:text-gray_400;
}

.button_light,
.link_light {
  @apply !border-2 border-transparent bg-transparent text-dodgerblue hover:border-transparent hover:bg-ghostwhite focus:border-brightgray focus:bg-ghostwhite active:border-transparent active:bg-antiflashwhite  disabled:bg-transparent;
}

:global(body.dark) .button_light,
:global(body.dark) .link_light {
  @apply bg-transparent text-dodgerblue  hover:border-transparent hover:bg-darkCharcol focus:border-bauhaus focus:bg-darkCharcol active:border-transparent active:bg-bauhaus disabled:border-transparent disabled:bg-transparent disabled:text-gray_400;
}

.button_danger,
.link_danger {
  @apply !border-2 border-transparent bg-valentinered text-white hover:border-transparent hover:bg-lavared focus:border-transparent focus:bg-valentinered focus:outline focus:outline-2 focus:outline-offset-0 focus:outline-mistyrose  active:border-transparent active:bg-lavared active:outline-0 disabled:bg-antiflashwhite;
}

:global(body.dark) .button_danger,
:global(body.dark) .link_danger {
  @apply hover:border-transparent hover:bg-lavared focus:border-transparent focus:bg-valentinered focus:outline focus:outline-2 focus:outline-offset-0 focus:outline-mistyrose  active:border-transparent   active:bg-lavared active:outline-0 disabled:border-transparent disabled:bg-raisinblack disabled:text-gray_400;
}

/* button and link size varient start */
.button_sm,
.link_sm {
  @apply rounded-lg py-1;
}
.button_md,
.link_md {
  @apply rounded-lg py-[6px];
}
.button_lg,
.link_lg {
  @apply rounded-lg py-2;
}
/* button and link size varient end */

/* button and link size for without text start */
.button_icon_xs {
  @apply h-7 w-7 rounded-md p-1 hover:bg-antiflashwhite;
}
:global(body.dark) .button_icon_xs {
  @apply hover:bg-richblack;
}
.button_icon_sm,
.link_icon_sm {
  @apply rounded-md p-1;
}
.button_icon_md,
.link_icon_md {
  @apply rounded-md p-[6px];
}
.button_icon_lg,
.link_icon_lg {
  @apply rounded-md p-2;
}
/* button and link size for without text end */

/* Button & Link CSS end  */

/* switch design start */
.switch {
  @apply relative inline-block h-5 w-10 focus-visible:outline-none;
}
.switch .default_switch {
  @apply h-0 w-0 opacity-0;
}
.switch .default_switch_active + .switch_slider_primary {
  @apply !bg-dodgerblue;
}
.slider {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  @apply absolute bottom-0 left-0 right-0 top-0 cursor-pointer rounded-full;
}
.switch_slider_primary {
  @apply bg-lightSilver;
}
:global(body.dark) .switch_slider_primary {
  @apply bg-dimGray;
}
.slider::before {
  content: "";
  -webkit-transition: 0.2s;
  transition: 0.2s;
  @apply absolute bottom-[0.125rem] left-[0.1875rem] h-4 w-4 rounded-full;
}
.switch_slider_primary:before {
  @apply bg-white;
}
:global(body.dark) .switch_slider_primary:before {
  @apply bg-white;
}
.switch .switch_primary:checked + .switch_slider_primary {
  @apply bg-dodgerblue hover:bg-crystalblue;
}
:global(body.dark) .switch .switch_primary:checked + .switch_slider_primary::before {
  @apply bg-white;
}
.switch .switch_primary:checked + .slider:before {
  -webkit-transform: translateX(1.125rem);
  -ms-transform: translateX(1.125rem);
  transform: translateX(1.125rem);
}
.switch .switch_primary:disabled + .switch_slider_primary {
  @apply cursor-not-allowed bg-brightgray;
}
:global(body.dark) .switch .switch_primary:disabled + .switch_slider_primary {
  @apply bg-bauhaus;
}
/* switch design end */
/* checkbox design start */
.checkboxWrapper {
  @apply flex items-center gap-4 p-2;
}
.checkboxWrapper .default_checkbox {
  @apply relative cursor-pointer rounded-[4px] disabled:cursor-not-allowed;
}
.checkboxWrapper .checkbox_primary {
  @apply border border-brightgray !bg-drWhite hover:border-2 disabled:!border-brightgray disabled:!bg-drWhite disabled:hover:border;
}
:global(body.dark) .checkboxWrapper .checkbox_primary {
  @apply !border-goshawkGrey !bg-carbonFiber disabled:!border-goshawkGrey disabled:!bg-carbonFiber;
}
.checkboxWrapper .checkbox_primary:checked,
:global(body.dark) .checkboxWrapper .checkbox_primary:checked {
  @apply !border-dodgerblue !bg-dodgerblue hover:!border-crystalblue hover:!bg-crystalblue focus:!border-transparent disabled:!border-dodgerblue disabled:!bg-dodgerblue;
}

:global(body.dark) .checkboxWrapper .checkbox_primary:checked::after {
  @apply !bg-none;
}

.checkboxWrapper .checkbox_indeterminate_primary,
:global(body.dark) .checkboxWrapper .checkbox_indeterminate_primary {
  @apply !border-dodgerblue !bg-dodgerblue hover:!border-crystalblue hover:!bg-crystalblue disabled:!border-dodgerblue disabled:!bg-dodgerblue;
}

.checkboxWrapper .checkbox_indeterminate_primary::before {
  content: "";
  background-image: url("../images/bar.svg");
  transform: translate(-50%, -50%);

  @apply absolute left-2/4 top-2/4 h-[2px] w-[10px] bg-no-repeat;
}
.checkboxWrapper .checkbox_indeterminate_primary:checked::before {
  background-image: none;
}
.default_checkbox_label {
  @apply cursor-pointer text-sm;
}
.checkboxWrapper .checkbox_label_primary {
  @apply text-brightgrey;
}
:global(body.dark) .checkboxWrapper .checkbox_label_primary {
  @apply !text-leadbelcher;
}
/* checkbox design end */
/* badge design start */
.badge {
  @apply flex items-center gap-1 rounded-md px-1.5 py-[2px] font-inter-medium text-xs;
}
.badge :global(label) {
  @apply text-xs;
}
.badge_primary {
  @apply bg-antiflashwhite text-davygrey;
}
:global(body.dark) .badge_primary {
  @apply bg-raisinblack text-silvers;
}
:global(body.dark) .badge_primary label {
  @apply text-silvers;
}
.badge_secondary {
  @apply border border-brightgrey  bg-ghostwhite text-davygrey;
}
:global(body.dark) .badge_secondary {
  @apply border-stiletto bg-nobleBlack;
}
.badge_lg {
  @apply px-2 text-sm;
}
.badge_smd {
  @apply px-2 text-sm;
}
.badge_rounded {
  justify-content: center;
  line-height: 1em !important;
  @apply rounded-full px-1.5 text-xs;
}
/* badge design end */

/* Input CSS start */
.inputWrapper {
  @apply grid gap-1.5;
}

.inputWrapper .default_input {
  @apply w-full rounded-lg px-3 py-2 caret-newcar disabled:cursor-not-allowed;
}

.inputWrapper .input_primary,
.inputWrapper .input_default {
  @apply border border-brightgray bg-white font-inter-medium text-sm text-brightgrey placeholder-santagrey hover:border-lightSilver hover:bg-brilliance focus:!border-lightSilver  disabled:bg-antiflashwhite disabled:text-santagrey disabled:hover:border-brightgray;
}

:global(body.dark) .inputWrapper .input_primary,
:global(body.dark) .inputWrapper .input_default {
  @apply !border-jet !bg-mirage !text-lavender_grey !placeholder-gray_300 hover:!border-kettleman hover:bg-chromaphobicBlack focus:!border-kettleman disabled:!border-balticSea disabled:!bg-raisinblack disabled:!text-agedmoustachegrey  disabled:!placeholder-neonNazar disabled:hover:!border-balticSea;
}
.inputWrapper .plain_input {
  @apply !border-0 !bg-transparent !p-[6px]   placeholder-lightSilver caret-newcar;
}

:global(body.dark) .inputWrapper .plain_input {
  @apply !bg-transparent !placeholder-kettleman;
}

.inputWrapper .input_title {
  @apply font-inter-semibold text-xl text-richblack;
}

:global(body.dark) .inputWrapper .input_title {
  @apply !text-lighthouse;
}

.inputWrapper .input_description {
  @apply text-sm !text-aurometalsaurus;
}

:global(body.dark) .inputWrapper .input_description {
  @apply !text-battleshipgray;
}

.inputWrapper .iconWrapper {
  @apply relative;
}

.inputWrapper .iconWrapper .input_icon {
  @apply !pl-8;
}

.inputWrapper .span_icon {
  @apply absolute left-2 top-[11px];
}

.inputWrapper .input_typing,
:global(body.dark) .inputWrapper .input_typing {
  @apply focus:!border-dodgerblue;
}

.inputWrapper .input_editing {
  @apply !border-aurometalsaurus focus:!border-aurometalsaurus;
}

:global(body.dark) .inputWrapper .input_editing {
  @apply !border-battleshipgray hover:!border-battleshipgray focus:!border-battleshipgray;
}

.inputWrapper .input_success,
:global(body.dark) .inputWrapper .input_success {
  @apply !border-greenhaze hover:!border-greenhaze focus:!border-greenhaze;
}

.inputWrapper .input_error,
:global(body.dark) .inputWrapper .input_error {
  @apply !border-valentinered !bg-lavenderBlush text-incubus placeholder-incubus hover:!border-valentinered focus:!border-valentinered;
}
.inputWrapper .input_normal_error,
:global(body.dark) .inputWrapper .input_normal_error {
  @apply !bg-transparent !text-redVitality !placeholder-redVitality;
}

:global(body.dark) .inputWrapper .input_error {
  @apply !bg-lavenderBlush !text-incubus !placeholder-incubus;
}

.inputWrapper .default_label,
.inputWrapper .default_span {
  @apply font-inter-medium text-xs text-davygrey;
}
:global(body.dark) .inputWrapper .default_label {
  @apply !text-silvers;
}
.inputWrapper .label_error,
:global(body.dark) .inputWrapper .label_error {
  @apply !text-valentinered;
}
.inputWrapper .span_error,
:global(body.dark) .inputWrapper .span_error {
  @apply text-valentinered;
}
/* Input CSS end */

/* ICON CSS start */
.messageIcon path,
.messageIconDisabled path {
  @apply !stroke-santagrey;
}

:global(body.dark) .messageIcon path {
  @apply !stroke-gray_300;
}

:global(body.dark) .messageIconDisabled path {
  @apply !stroke-neonNazar;
}
/* ICON CSS end */

/* textarea / lexical editor design start */

.editor-shell {
  @apply relative rounded-sm;
}

.editor_container {
  @apply relative cursor-text border-none bg-white;
}

:global(body.dark) .editor_container {
  @apply bg-mirage;
}
.editor-shell .editor_container.tree_view {
  @apply rounded-none;
}

.editor-shell .editor_container.plain_text {
  @apply rounded-tl-lg rounded-tr-lg;
}

.editor_scroller {
  @apply relative flex cursor-text resize-none border-0 outline-none;
}

.textarea_editor_container .editor {
  @apply relative flex-auto cursor-text border border-brightgray bg-white px-3 pt-3;
}

.textarea_editor_container .editor_default {
  @apply rounded-lg;
}

.textarea_editor_container .editor_footer {
  @apply rounded-t-lg;
}

.textarea_editor_container .editor_header {
  @apply rounded-b-lg;
}

:global(body.dark) .textarea_editor_container .editor {
  @apply border-jet bg-mirage;
}

.textarea_editor_container .editor_scroller,
.metadata-view-container .editor_scroller {
  @apply w-full resize-none;
}

.textarea_editor_container .editor_scroller > div > div:first-child {
  @apply relative mb-1 min-h-[126px] w-full border-none text-sm outline-none;
}

:global(body.dark) .textarea_editor_container .editor_scroller > div > div:first-child {
  @apply border-none bg-mirage caret-greychateau outline-none;
}
.textarea_editor_container_default .editor_scroller > div > div:first-child {
  @apply min-h-[64px];
}

.textarea_editor_container .editor_scroller > div > div:first-child p {
  @apply text-xs text-brightgrey;
}

:global(body.dark) .textarea_editor_container .editor_scroller > div > div:first-child p {
  @apply text-lavender_grey;
}

.textarea_editor_container .Placeholder__root {
  @apply pointer-events-none absolute inline-block overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal text-santagrey;
}

.placeholder_editor_default {
  @apply left-[2.65rem] top-3;
}
.placeholder_editor_footer {
  @apply left-3.5 top-3;
}

.placeholder_editor_header {
  @apply left-3.5 top-[3.35rem];
}

:global(body.dark) .textarea_editor_container .Placeholder__root {
  @apply text-gray_300;
}

.metadata-view-container .Placeholder__root {
  @apply hidden;
}

.textarea_editor_container .editor_content_default {
  @apply relative;
}

.textarea_editor_container .editor_content_header {
  @apply relative flex rounded-t-lg border border-b-0 border-brightgray px-3 py-2;
}

:global(body.dark) .textarea_editor_container .editor_content_header {
  @apply border-jet bg-mirage;
}

.textarea_editor_container .editor_content_footer {
  @apply relative flex rounded-b-lg border border-t-0 border-brightgray p-3;
}

:global(body.dark) .textarea_editor_container .editor_content_footer {
  @apply border-jet bg-mirage;
}

.input_textarea_wrapper {
  @apply pointer-events-none relative flex flex-col gap-1 rounded-lg border border-brightgray bg-white p-3 hover:border-lightSilver hover:bg-brilliance;
}

.input_textarea_wrapper:focus-within {
  @apply border-lightSilver;
}

:global(body.dark) .input_textarea_wrapper:focus-within {
  @apply border-kettleman;
}

:global(body.dark) .input_textarea_wrapper {
  @apply border-jet bg-mirage hover:border-kettleman;
}

.input_textarea {
  @apply pointer-events-auto flex resize-none gap-2 border-none bg-transparent !p-0 font-inter-medium text-sm text-brightgrey placeholder-santagrey caret-newcar placeholder:font-inter-medium disabled:!text-gray-400 disabled:placeholder-gray-400 disabled:opacity-100;
}

:global(body.dark) .input_textarea {
  @apply !bg-transparent !text-lavender_grey placeholder-gray_300;
}

.input_textarea_label {
  @apply block overflow-hidden text-ellipsis whitespace-nowrap font-inter-medium text-xs text-davygrey;
}

:global(body.dark) .input_textarea_label {
  @apply text-silvers;
}

.input_textarea_typing,
.input_textarea_typing:focus-within,
:global(body.dark) .input_textarea_typing,
:global(body.dark) .input_textarea_typing:focus-within {
  @apply border-dodgerblue;
}

.input_textarea_editing,
.input_textarea_editing:focus-within {
  @apply !border-aurometalsaurus;
}

:global(body.dark) .input_textarea_editing,
:global(body.dark) .input_textarea_editing:focus-within {
  @apply !border-battleshipgray;
}

.input_textarea_noborder {
  @apply flex resize-none flex-wrap overflow-hidden break-words rounded-none border-none bg-transparent p-0 font-inter-semibold text-richblack placeholder-lightSilver caret-newcar disabled:!text-lightSilver disabled:placeholder-lightSilver;
}

.input_textarea_title,
.input_textarea_title input {
  @apply text-xl placeholder:text-xl;
}

.input_textarea_description,
.input_textarea_description input {
  @apply font-inter-medium text-sm !text-aurometalsaurus placeholder:text-sm;
}

:global(body.dark) .input_textarea_noborder {
  @apply !bg-transparent !text-lighthouse placeholder-kettleman disabled:text-kettleman;
}

:global(body.dark) .input_textarea_title,
.input_textarea_title input {
  @apply text-xl;
}

:global(body.dark) .input_textarea_description,
.input_textarea_description input {
  @apply !text-battleshipgray;
}

.error_style {
  @apply font-inter-medium text-xs text-valentinered;
}

.input_textarea_error {
  @apply !text-redVitality;
}

:global(body.dark) .input_textarea_error {
  @apply !text-redVitality;
}

/* textarea / lexical editor design end */

/* radio button CSS start */

.radioButtonWrapper {
  @apply flex items-center gap-4 p-2;
}

.radioButtonWrapper .default_radio_button {
  @apply disabled:cursor-not-allowed;
}
.radioButtonWrapper .default_radio_button:hover {
  transition: background-color 0.3s;
}

.radioButtonWrapper .radio_button_primary {
  box-shadow: none !important;
  @apply relative cursor-pointer appearance-none border border-brightgray bg-drWhite text-dodgerblue hover:!border-2 focus:!border-brightgray focus:bg-drWhite disabled:!bg-antiflashwhite disabled:hover:!border;
}

:global(body.dark) .radioButtonWrapper .radio_button_primary {
  @apply !border-goshawkGrey !bg-carbonFiber !text-dodgerblue  focus:!border-goshawkGrey focus:!bg-carbonFiber focus-visible:!bg-dodgerblue  disabled:!border-blackTie disabled:!bg-darkCharcol;
}

.radioButtonWrapper .radio_button_primary:checked {
  @apply !border-dodgerblue !bg-dodgerblue hover:!border;
}
:global(body.dark) .radioButtonWrapper .radio_button_primary:checked {
  @apply !border-dodgerblue  !bg-dodgerblue hover:!border focus:!border-dodgerblue;
}
:global(body.dark) .radioButtonWrapper .radio_button_primary:checked:focus {
  box-shadow: none !important;
}

.radioButtonWrapper .radio_label_primary {
  @apply cursor-pointer text-brightgrey;
}

:global(body.dark) .radioButtonWrapper .radio_label_primary {
  @apply !text-lavender_grey;
}

/* radio button CSS end */
/* Common form controls CSS End */

.hide_spin_buttons::-webkit-inner-spin-button,
.hide_spin_buttons::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
