.selectTemplate {
  @apply relative;
}
.selectTemplate > div {
  @apply !border-brightgray !bg-ghostwhite text-sm  text-aurometalsaurus;
}
:global(body.dark) .selectTemplate > div {
  @apply !border-iridium !bg-balticsea;
}
:global(body.dark) .selectTemplate > div input {
  @apply -z-10;
}

.selectTemplate > div input {
  @apply focus:ring-0;
}
.selectTemplate > div .labelTag {
  @apply flex gap-2;
}
.selectTemplate:last-child {
  @apply !h-fit;
}
.agreementHeader {
  @apply flex w-full justify-center gap-2 border-b border-brightgray p-3 maxMd:fixed maxMd:right-0 maxMd:top-[104px] maxMd:z-[1] maxMd:bg-white;
}
.agreementContainer {
  @apply bg-white;
}
:global(body.dark) .agreementContainer > div:first-child {
  @apply bg-balticsea;
}
:global(body.dark) .agreementContainer > div:not(:first-child) {
  @apply bg-darkjunglegreenss;
}
.agreementBody {
  @apply bg-white text-lg font-medium;
}
:global(body.dark) .agreementBody {
  @apply bg-darkjunglegreenss;
}
.agreementBody h3 {
  @apply font-inter-medium font-medium;
}
.agreementBodyContainer {
  @apply mt-2 grid gap-x-10 px-4 md:grid-cols-3 md:gap-y-4 md:px-5 maxMd:!gap-4;
}
.agreementBodyContainer .fieldTitle {
  @apply mb-2;
}

@screen minMaxMd {
  .agreementBodyContainer {
    @apply grid-cols-2 gap-x-5;
  }
}
.agreementFields {
  @apply gap-2 !text-sm font-medium md:gap-4;
}
.agreementFields h3 {
  @apply dark:!text-white;
}

.agreementFields input[type="text"]:focus {
  @apply !border-iridium !outline-none !ring-mirage !ring-opacity-0;
}
.rotateImg {
  @apply rotate-180;
}
.radioButtons {
  @apply h-4 w-4 border-none bg-gray-100 text-mirage ring-mirage focus:!ring-transparent dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-0;
}
:global(body.dark) .radioButtonContainer input[type="radio"].radioButtons:checked {
  background-image: url("../images/checkmark.svg") !important;
  @apply !bg-white ring-offset-transparent focus:!ring-0 focus:ring-offset-0;
  box-shadow: none !important;
}
.radioButtons:checked {
  background-image: url("../images/agreements//check.svg") !important;
  background-size: auto !important;
}

.agreementSelectErorrs > div:first-of-type {
  @apply rounded-lg !border !border-red-500 !bg-red-50;
}
.agreementErorrs > div > select {
  @apply rounded-lg border-none !border-red-500 !bg-red-50 text-red-900;
}
.agreementSelectErorrs > div:first-of-type {
  @apply !bg-red-50;
}
.agreementCommonTable {
  @apply mt-4 md:ml-[-7px] maxMd:overflow-x-auto;
}
.agreementCommonTable table {
  @apply border-b border-b-brightgray;
}
:global(body.dark) .agreementCommonTable table {
  @apply !border-thunders;
}

:global(body.dark) .agreementCommonTable table thead {
  @apply !border-thunders;
}
:global(body.dark) .agreementCommonTable tbody tr {
  @apply hover:!bg-balticsea;
}

.agreementCommonTable table > thead th {
  @apply !normal-case;
}

.agreementCommonTable > div:first-child {
  @apply max-h-60 overflow-y-auto;
}
.agreementCommonTable > div {
  @apply p-0 !shadow-none;
}
.agreementCommonTable > div > table > thead {
  @apply border-y border-y-brightgray !bg-transparent !bg-none !text-mirage;
}
.agreementCommonTable > div > table th:first-child,
.agreementCommonTable > div > table > tbody td:first-child {
  @apply !ml-0 !py-4;
}
.agreementCommonTable > div > table > tbody td,
.agreementCommonTable > div > table > thead th {
  @apply !py-4 text-sm;
}
.agreementCommonTable h3 {
  @apply text-aurometalsaurus;
}

.radioButtonContainer {
  @apply flex cursor-pointer items-center justify-start gap-2 text-sm;
}
.fieldTitle {
  @apply mb-2 flex gap-[10px];
}
.fieldTitle > label:first-child {
  @apply flex gap-2;
}
.agreementCommonTable h3 {
  @apply font-inter-medium font-medium text-aurometalsaurus;
}
.agreementRightCol .selectTemplate,
.agreementRightCol select,
.agreementRightCol textarea,
.agreementRightCol input,
.agreementRightCol .multiDropdownSelect,
.tab_body_border .unitValueContainer {
  @apply !max-w-[446px];
}
@screen maxLg {
  .tabList button {
    @apply !p-2.5;
  }
}

.tabList > div > button {
  @apply !border-r !border-t !text-sm font-medium text-aurometalsaurus;
}

.agreementDropDown {
  @apply w-full;
}

.agreementDropDown select:focus {
  @apply !border-mirage !outline-none !ring-mirage !ring-opacity-0;
}
:global(body.dark) .agreementDropDown select {
  @apply !border-iridium !bg-balticsea !text-greychateau;
}
.agreementDropDown.agreementErorrs > div select {
  @apply border border-solid !border-red-500;
}
:global(body.dark) .agreementDropDown.agreementErorrs > div select {
  @apply !border-red-500;
}
.custom_tabList div[role="tablist"] {
  overflow-x: auto;
  min-width: 100%;
  max-width: 500px;
  flex-flow: nowrap;
}

/* Categories CSS */

.category_tabs,
.supplier_tabs,
.entity_tabs {
  @apply border-b-0;
}

.category_tabs_active,
.supplier_tabs_active,
.entity_tabs_active {
  @apply absolute left-0 h-px w-full border-b border-brightgray;
  top: 3.063rem;
  width: calc(100% - 7.5rem);
  margin-left: 7.5rem;
}

.tabs_scroll {
  @apply overflow-y-scroll;
}
.tabs_scroll::-webkit-scrollbar {
  @apply !hidden;
}
.tab_body_border {
  @apply grid gap-4 pb-6;
}
:global(body.dark) .tab_body_border {
  @apply !border-thunders;
}
.tab_body_border .fieldTitle {
  @apply mb-0;
}
.tab_body_border .agreementViewField label {
  @apply md:w-2/5;
}
.agreementsGrid .agreementRowStart {
  @apply !items-start;
}
.agreementsGrid .agreementRowStart .fieldTitle {
  @apply mt-[10px];
}

.tabs_scroll .agreementCommonTable:global(.table-heading) {
  @apply w-[inherit] min-w-[163px];
}

.tab_body_border .agreementFields {
  @apply flex w-full items-center;
}
.category_tabs button[role="tab"],
.supplier_tabs button[role="tab"],
.entity_tabs button[role="tab"] {
  @apply rounded-none border border-l-0 border-brightgray px-5 py-3.5;
}

.category_tabs button[aria-selected="true"],
.supplier_tabs button[aria-selected="true"],
.entity_tabs button[aria-selected="true"] {
  @apply border-b-0 border-t-2 border-t-mirage text-mirage;
}

@screen maxMd {
  .category_tabs > div button,
  .supplier_tabs > div button,
  .entity_tabs > div button {
    @apply px-2;
  }
}
.custom_tabs > div:last-child {
  @apply !mb-8 !border-b !border-brightgray !pb-8;
}

.agreements__tabs [role="tabpanel"] {
  @apply px-0;
}

.agreements__tabs button[role="tab"] {
  @apply whitespace-nowrap;
}
.agreementViewField {
  @apply text-sm;
}
.agreementViewField > label {
  @apply flex gap-1 font-medium text-mirage;
}
.agreementViewField > h3 {
  @apply !font-normal text-aurometalsaurus;
}

.agreementViewField > ol {
  @apply mt-2 list-inside !font-normal text-aurometalsaurus dark:text-boulder;
}
.warningTab > button {
  @apply border-0 text-mirage maxMd:p-2 maxMd:text-left;
}
.warningTab > button[aria-selected="true"] {
  @apply border-b-2 border-b-mirage;
}
:global(body.dark) .warningTab > button[aria-selected="true"] {
  @apply border-white;
}
.warningModal .warningModalHeader {
  @apply !px-6 !pt-6;
}
.warningModal .warningModalHeader > h3 {
  @apply font-semibold;
}
.warningModal .warningModalBody {
  @apply overflow-hidden !px-0 pb-0;
}
.warningTabItem {
  @apply !h-80 overflow-auto !py-4 pl-4 md:pl-5;
}
.warningTab div:last-child > div {
  @apply py-0;
}
@media (min-width: 1613px) {
  .agreementContainer :global(.tabs_details) {
    @apply !w-full;
  }
}
.agreementContainer :global(.tabs_details) {
  @apply flex-nowrap md:w-fit maxMd:flex-wrap;
}
.agreementContainer :global(.tabs_details_container)::-webkit-scrollbar {
  @apply h-[10px] overflow-x-auto;
}
.agreementContainer :global(.tabs_details_container)::-webkit-scrollbar-thumb {
  @apply rounded-none;
}
.agreementContainer :global(.tabs_details)::-webkit-scrollbar {
  @apply h-1;
}
/* agreementsForm */
.agreementsFormLable {
  @apply flex gap-2 text-sm;
}
:global(body.dark) .agreementsFormLable {
  @apply text-white;
}

.agreementTable {
  @apply mt-8;
}

.noFoundText {
  @apply border-b py-3 text-center text-sm dark:!text-greychateau;
}
:global(.dark) .noFoundText {
  @apply border-thunders text-boulder;
}

.fieldLabel label,
.fieldLabel,
.agreementsFormSelect {
  @apply w-full;
}
.agreementLeftCol {
  @apply w-full md:w-2/5;
}
.agreementRightCol {
  @apply w-full md:w-3/5;
}
.tab_body_border .agreementsFormFields,
.agreementsForm .agreementsFormFields {
  @apply flex gap-2 md:gap-4;
}
.agreementsForm {
  @apply maxMd:flex-col;
}
.agreementsForm {
  @apply maxMd:flex-col;
}
.tab_body_border .agreementsFormSelect,
.agreementsForm .agreementsFormSelect,
.agreementsForm .agreementDropDown {
  @apply agreementRightCol;
}
.agreementsForm .fieldTitle {
  @apply agreementLeftCol;
}

.agreementsGrid {
  @apply grid gap-4 px-4;
}

:global(body.dark) .fieldTitle {
  @apply text-white;
}

/* agreement parent container */

.suppliersParentAgreement .agreementsFormFields {
  @apply flex flex-col;
}

.suppliersParentAgreement :global(.supplier_label) {
  @apply absolute right-[-170px];
}

.suppliersParentAgreement .agreementsFormSelect {
  @apply relative left-[40.6%];
}
