.DialogActions {
  justify-content: right;
  @apply flex flex-row border-t border-brightgray pt-4 dark:border-thunders;
}

.DialogButtonsList {
  @apply flex flex-col justify-end;
}

.DialogButtonsList button {
  margin-bottom: 20px;
}
