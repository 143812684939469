.sidebar {
  @apply relative flex h-screen flex-col justify-between bg-ghostwhite px-3 py-5 maxMd:h-full maxMd:pt-0;
}
.sidebarFullWidth {
  min-width: 15.625rem;
  width: 15.625rem;
  transition: width 0.2s;
  z-index: 11;
  @apply maxMd:z-20 maxMd:w-full;
}
.sidebarToggle {
  @apply hidden;
}
.sidebarSubMenu {
  @apply relative;
}
.sidebarSubMenu::before {
  content: "";
  @apply absolute left-5 h-full border-l border-brightgray;
}
:global(body.dark) .sidebarSubMenu::before {
  @apply border-jet;
}
