/* New version of main content*/
body {
  @apply overflow-hidden;
}

.main-content-style {
  width: calc(100% - 15.625rem);
  min-height: 100vh;
  @apply relative gap-2 overflow-hidden bg-gray-50 py-2 pr-2 dark:bg-eerieblack maxMd:ml-0 maxMd:w-full;
}

.main-content-toggle.main-content-style {
  @apply ml-0 w-full p-2;
}

.shadow-custom {
  box-shadow: 0rem 0.0625rem 0.125rem 0rem #0000000d;
}

.tabs-btn {
  transition: all 0.1s ease-in-out;
  @apply shadow-custom rounded-lg border border-brightgray bg-white dark:border-jet dark:bg-mirage;
}
.tabs-btn svg path {
  @apply dark:!fill-white;
}

.btn-outline {
  @apply shadow-custom rounded-lg border border-brightgray bg-white px-3 py-[0.375rem] text-sm text-richblack dark:border-jet dark:bg-mirage dark:text-white dark:hover:bg-raisinblack;
}

.pagination,
.pagination > div:first-child {
  @apply flex items-center;
}

.pagination > div:first-child {
  @apply gap-1 px-2 dark:text-battleshipgray;
}
.pagination ul {
  @apply shadow-custom mt-0;
}
.btn-outline svg path rect {
  @apply fill-davygrey;
}

.pagination > ul li button {
  @apply py-1.5 opacity-100 dark:border-jet dark:bg-mirage dark:text-silvers dark:hover:bg-raisinblack;
}
.pagination > ul li button:disabled {
  @apply hover:bg-transparent dark:text-battleshipgray;
}
.si.fill.three_dots_icon path {
  fill-opacity: 1;
  @apply fill-gray_300;
}
.data-table::-webkit-scrollbar {
  @apply hidden;
}
.data-table table {
  @apply select-none;
}
.data-table th {
  @apply border border-antiflashwhite bg-ghostwhite py-[0.625rem] text-left text-sm font-medium text-aurometalsaurus dark:border-raisinblack dark:!bg-mirage dark:text-gray_500;
}

.data-table tr th:first-child,
.data-table tr td:first-child {
  @apply p-0;
}
.data-table tr th .checkbox_wrapper,
.data-table tr td .checkbox_wrapper {
  @apply flex cursor-pointer items-stretch gap-1;
}

.data-table tr th .checkbox_wrapper > div,
.data-table tr th .checkbox_wrapper > span {
  @apply flex h-auto p-1;
}
.data-table tr td .checkbox_wrapper > div,
.data-table tr td .checkbox_wrapper > span {
  @apply flex h-auto px-1 py-[0.625rem];
}
.data-table tr th .checkbox_wrapper > div,
.data-table tr td .checkbox_wrapper > div {
  @apply flex w-full items-center;
}
.data-table tr th:first-child > span:hover > div,
.data-table tr td:first-child > span:hover > div {
  @apply opacity-100;
}
.data-table td {
  @apply border border-antiflashwhite px-3 text-left text-sm font-medium text-brightgrey dark:border-raisinblack dark:!bg-mirage dark:text-gray_700;
}
.tags-design {
  @apply flex items-center gap-[0.375rem] rounded-md px-[0.375rem] py-1 text-xs font-medium text-davygrey;
}
.tags-design .tags-title {
  width: calc(100% - 1rem);
  @apply text-xs text-davygrey dark:text-silvers;
}
.tags-design span {
  @apply h-4 w-4 rounded-full;
}
.data-table tr td:first-child:hover > div {
  @apply opacity-100;
}

/* sorting start */
.simple_table_th .simple_sorting {
  transition: all 0.4s ease-in-out;
  @apply absolute left-1 z-[1] mt-1 w-56 rounded-lg border border-brightgray bg-brilliance p-1 shadow-sortingShadow dark:border-jet dark:bg-mirage;
}
.simple_table_th .simple_sorting li {
  transition: all 0.4s ease-in-out;
  @apply flex items-center gap-2 rounded-[4px] px-2 py-1 text-brightgrey hover:bg-antiflashwhite dark:text-uniquegrey hover:dark:bg-nero;
}
.sorting_icon path {
  @apply dark:!stroke-greyShingle;
}
/* sorting end */
/* Resizable tanstack table  */
.resizer {
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  @apply absolute right-0 top-0 h-full w-[0.3125rem] bg-blue-700;
}

.resizer.isResizing {
  @apply bg-blue-600 opacity-100;
}

.resizer {
  @apply opacity-0;
}

.resizer:hover {
  @apply opacity-100;
}

/* common dropdown */
.DropdownMenuContent {
  box-shadow: 0rem 0.0625rem 0.125rem -0.0625rem #0000001a;
  box-shadow: 0rem 0.0625rem 0.1875rem 0rem #0000001a;
  @apply relative z-10 flex min-w-[13rem] flex-col gap-1 rounded-md border border-brightgray bg-white p-1 dark:border-black_lacquer dark:bg-mirage;
}
.DropdownMenuItem {
  @apply cursor-pointer rounded px-2 py-1 text-sm text-gray-700 hover:bg-antiflashwhite dark:text-battleshipgray dark:hover:bg-raisinblack;
  word-break: break-word;
}
.DropdownMenuCheckboxItem {
  @apply cursor-pointer rounded px-1 py-1.5 text-sm text-gray-700 hover:bg-antiflashwhite dark:text-battleshipgray dark:hover:bg-tricornblack;
}

.DropdownMenuItem:focus-visible {
  @apply outline-none;
}

.DropdownMenuSeparator {
  @apply m-[0.32rem] h-[1px] bg-antiflashwhite dark:bg-tricornblack;
}

.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  @apply cursor-not-allowed bg-transparent opacity-75 hover:bg-transparent;
}

.si.fill.icon_darkmode path {
  @apply fill-white dark:fill-mirage;
}
.si.fill.theme_icon path {
  @apply dark:fill-silvers;
}
.si.fill.table_icon path {
  @apply dark:fill-battleshipgray;
}
.table_circle_icon circle {
  @apply dark:stroke-battleshipgray;
}
.si.fill.table_calendar_icon path {
  fill-opacity: 1;
  @apply dark:fill-battleshipgray;
}
.grid_switch button:focus-visible {
  @apply outline-none ring-offset-0;
}
.si.fill.vendors_icon path {
  fill-opacity: 1;
  @apply dark:fill-battleshipgrays;
}
.vendors-accordion {
  height: calc(100vh - 4.75rem);
  @apply overflow-y-auto rounded-none border-b-0 dark:!border-jet;
}
.vendors-accordion input[type="checkbox"] {
  @apply !rounded !border-brightgray dark:!border-[#ffffff1a];
}
.vendors-accordion input[type="checkbox"]:checked {
  @apply !border-dodgerblue !bg-dodgerblue;
}
.vendors-accordion > div {
  @apply flex flex-col border-b border-antiflashwhite dark:border-raisinblack;
}
.vendors-accordion > div:last-child {
  @apply border-none;
}
.form-addon svg {
  @apply absolute left-4 top-[0.8125rem];
}
.form-addon svg path {
  @apply dark:fill-[#ffffff66];
}
.form-addon input {
  @apply !pl-9;
}

.seprator::before,
.seprator-max {
  content: "";
  @apply absolute bottom-0 left-[3.95rem] top-0 h-full border-l border-l-brightgray dark:border-[#ffffff1a];
}
.seprator-max {
  @apply !left-[5.95rem];
}
.tags-design__done-icon path {
  @apply dark:fill-mirage;
}
.filter-text-dark {
  @apply dark:!text-[#ffffffbf];
}

.form_select select {
  background-position: right 1rem center !important;
  @apply dark:!bg-[length:0.58rem];
}
.select_checkbox input[type="checkbox"]:checked::after {
  background-color: #3f83f8 !important;
  @apply top-[0.1875rem];
}
.select_checkbox label {
  @apply dark:!text-crayolasblue;
}
.select_checkbox_priority .high,
.select_checkbox_priority .medium,
.select_checkbox_priority .low,
.high,
.low,
.none,
.medium {
  @apply tags-design font-semibold;
}
.select_checkbox_priority .high,
.high {
  @apply bg-mistyrose !text-lavared dark:!text-lavared;
}
.select_checkbox_priority .medium,
.medium {
  @apply bg-blond !text-freshcinnamon dark:!text-freshcinnamon;
}
.select_checkbox_priority .low,
.low {
  @apply bg-azureishWhite !text-tropicalRainForest dark:!text-tropicalRainForest;
}

.select_checkbox_priority .none {
  @apply bg-antiflashwhite !text-davygrey dark:!bg-raisinblack dark:!text-silvers;
}
.select_checkbox_priority .none,
.none {
  @apply bg-antiflashwhite !text-davygrey dark:!text-silvers;
}

.status_checkbox label {
  @apply dark:!text-gray_700;
}
.vender_formcontrol {
  @apply relative flex w-full items-center gap-2 rounded-lg border border-brightgray px-3 py-2 text-sm;
}
.vender_formcontrol input {
  @apply w-full;
}
.vender_formcontrol input::placeholder {
  @apply text-sm;
}
.form_control {
  @apply block w-full rounded-lg border border-gray-300 bg-white text-gray-900 placeholder-santagrey shadow-sm !ring-opacity-0 dark:border-iridium dark:bg-mirage dark:text-greychateau dark:placeholder-stormdust sm:text-sm mac13Inch:px-3 mac13Inch:py-2 !important;
}
.textarea-form_control {
  @apply block w-full rounded-lg border border-gray-200 bg-white text-gray-900 placeholder-santagrey focus:border-mirage focus:ring-mirage dark:border-gray-600 dark:bg-mirage dark:text-greychateau dark:placeholder-stormdust sm:text-sm;
}
.form_select select {
  @apply bg-transparent pr-[2.125rem] dark:!bg-mirage;
}
.form_select input {
  @apply bg-transparent dark:!bg-transparent;
}
.vendors_icon svg path {
  @apply dark:fill-battleshipgrays;
}
.favorite_icon path {
  @apply dark:fill-silvers dark:!stroke-silvers;
}
.vendor_pagination_button button {
  @apply hover:bg-antiflashwhites dark:border-jet dark:hover:bg-raisinblack;
}
.si.fill.pagination_arrow path {
  @apply fill-davygrey dark:fill-silvers;
}
.btn_disable {
  @apply cursor-default hover:!bg-transparent dark:hover:!bg-transparent;
}
/* checkbox green */
.vendors_checkbox_input {
  @apply !leading-[0rem];
}
.vendors_checkbox_input input {
  @apply !rounded-[50%];
}
.checkbox_input_green input:checked {
  @apply !bg-greenhaze;
}
.vendors-checkbox-semi-checked:checked {
  @apply !h-[0.75rem]  !w-[0.75rem] bg-dodgerblue bg-none;
}

/* add interpretation checkbox */
.custom-checkbox input[type="checkbox"] {
  @apply !border-[#D1D5DB] !bg-transparent;
}

.custom-checkbox input[type="checkbox"]:checked {
  @apply !border-dodgerblue !bg-transparent bg-none;
}

.custom-checkbox input[type="checkbox"]:checked::after {
  content: "";
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  @apply absolute right-[0.125rem] top-[0.125rem] h-[0.625rem] w-[0.375rem] rotate-180 bg-dodgerblue;
}

.vendors_checkbox_workflow input {
  @apply !h-[0.75rem] !w-[0.75rem];
}
.stars-icon path {
  @apply !stroke-none dark:fill-gray_300;
}

.reminder_wrapper {
  @apply w-[calc(100%-1.5rem)];
}
.reminder_wrapper > div {
  @apply w-auto;
}
.tasks_reminder_custom_view .custom_tooltip {
  @apply hidden;
}

.tasks_reminder_custom_view p {
  @apply w-full;
}

.tasks_reminder_custom_view .task_reminder_content {
  @apply w-[calc(100%-11.125rem)];
}
.common_dropdown_v2 {
  @apply rounded-lg !bg-white dark:!bg-raisinblack;
}
.common_dropdown_v2__menu {
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
  @apply rounded-md border-[1px] border-brightgray;
}

.common_dropdown_v2__control {
  @apply h-auto !min-h-0 !bg-white !px-3 !py-2 dark:!border-[#FFFFFF1A] dark:!bg-mirage;
}

.common_dropdown_v2_edit .common_dropdown_v2__control {
  @apply !border-dodgerblue;
}

.common_dropdown_v2--is-disabled {
  @apply !cursor-not-allowed;
}
.common_dropdown_v2__single-value {
  @apply !text-brightgrey dark:!text-uniquegrey;
}

.common_dropdown_v2__placeholder {
  @apply !m-0 font-inter-medium text-sm !text-santagrey disabled:!text-santagrey disabled:opacity-100 dark:!text-gray_300;
}
.common_dropdown_v2__menu-notice--loading {
  @apply text-sm;
}

.common_dropdown_v2__indicator-separator {
  @apply hidden;
}

.common_dropdown_v2__control .common_dropdown_v2__input {
  @apply !bg-transparent;
}

.common_dropdown_v2__value-container {
  @apply gap-2 !p-0;
}

.common_dropdown_v2__input-container {
  @apply !m-0 !p-0;
}

.common_dropdown_v2__control .common_dropdown_v2__input {
  @apply !bg-transparent;
}

.common_dropdown_v2__indicator {
  @apply !p-0 !text-gray-500 dark:!text-battleshipgray;
}

.common_dropdown_v2__indicator svg {
  @apply h-4 w-4;
}

.common_dropdown_v2__multi-value {
  @apply !m-0 gap-1 !bg-lavender px-1.5 py-0.5;
}

.common_dropdown_v2__multi-value__label {
  @apply !p-0 font-inter-medium !text-xs !font-medium !text-crayolasblue;
}

.common_dropdown_v2__multi-value__label:hover {
  @apply !bg-transparent;
}

.common_dropdown_v2__control .common_dropdown_v2__input {
  @apply !bg-transparent;
}

.common_dropdown_v2__menu-notice--no-options {
  @apply text-sm;
}

.common_dropdown_v2__menu-notice--loading {
  @apply text-sm;
}
.common_dropdown_v2__multi-value__remove {
  @apply !p-0;
}

.common_dropdown_v2__multi-value__remove:hover {
  @apply !bg-transparent;
}

.common_dropdown_v2__multi-value__remove svg {
  @apply !h-3 !w-3 !fill-crayolasblue;
}

.common_dropdown_v2__indicators {
  @apply flex gap-2;
}

.no_indicator_dropdown .common_dropdown_v2__indicators {
  @apply hidden;
}

.dropdown_without_border .common_dropdown_v2__control {
  @apply w-fit max-w-fit gap-1 border-none px-0 py-0.5;
}

.common_dropdown_v2__menu-notice--no-options {
  @apply text-sm;
}

.unit_select__control {
  @apply w-3/4;
}
.multitag_spacing .searchable_dropdown__dropdown-indicator,
.multitag_spacing .searchable_dropdown__indicator-separator {
  @apply !hidden;
}
.multitag_spacin .searchable_dropdown__control {
  @apply !min-h-fit;
}
.multitag_spacing .searchable_dropdown__menu-list {
  @apply rounded-lg border border-brightgray !bg-white shadow-dropdownShadow dark:border-jet dark:!bg-mirage;
}
.multitag_spacing .searchable_dropdown__option {
  @apply !bg-white !font-medium !text-brightgrey hover:!bg-antiflashwhites hover:!text-brightgrey dark:!bg-mirage dark:!text-uniquegrey dark:hover:!bg-raisinblack;
}
.searchable_dropdown__menu {
  @apply dark:bg-mirage;
}

.si.fill.right_arrow path {
  @apply fill-richblack dark:fill-white;
}
.right_arrow {
  @apply hidden;
}
.multitag_spacing .searchable_dropdown__option--is-selected .right_arrow {
  @apply block;
}
.range_select_date {
  @apply grid grid-cols-2 gap-2 p-3;
}
.range_select_date span {
  @apply rounded-lg border border-brightgray px-3 py-2 dark:border-jet dark:text-white;
}
.range_select_wrapper .react-datepicker__header {
  @apply !mt-0 !pt-0 dark:!bg-mirage;
}
.range_switch > div label > div:checked {
  @apply dark:after:!bg-green-400;
}

/* .react-datepicker__tab-loop {
  @apply absolute z-50 top-0;
} */

.range_select_wrapper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day--selected {
  @apply border-newcar !bg-newcar !text-white dark:!text-white;
}
.range_select_wrapper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day:hover {
  @apply !bg-newcar dark:border-newcar;
}
.range_select_wrapper
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  @apply !bg-lavender dark:!text-mirage;
}
.range_select_wrapper .react-datepicker__day--in-range {
  @apply !bg-lavender text-mirage dark:!text-mirage;
}
.range_select_wrapper .react-datepicker__day:not(.react-datepicker__day--in-range),
.range_select_wrapper .react-datepicker__day-name {
  @apply dark:!text-gray-400;
}
.range_select_wrapper .react-datepicker-popper .react-datepicker__week .react-datepicker__day {
  @apply dark:text-mirage;
}
.range_select_wrapper
  .react-datepicker__day.react-datepicker__day--021.react-datepicker__day--in-range {
  @apply !bg-lavender text-mirage hover:text-white;
}
.range_select_wrapper .react-datepicker__day--range-end {
  @apply !bg-[#c3ddfd];
}
.range_select_wrapper .react-datepicker__day--in-selecting-range {
  @apply text-richblack;
}
.range_select_wrapper {
  @apply rounded-lg border border-[#E5E7EB] dark:border dark:border-jet dark:bg-mirage;
}
.range_select_wrapper .react-datepicker {
  @apply dark:bg-mirage;
}
.apply_filter_btn {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  @apply flex w-full items-center justify-center gap-2 rounded-md border border-brightgray bg-white px-2 py-1 text-xs text-santagrey dark:border-gray_200 dark:bg-mirage dark:text-gray_400;
}
.filter_accordion_title h2 {
  @apply w-full pr-2;
}
.selection_tag_chip .selection_close_icon path {
  @apply !stroke-crayolasblue;
}
.selection_tag_chip {
  @apply mt-3 flex flex-wrap gap-2;
}
.selection_tag_chip span {
  @apply flex items-center gap-1 rounded-md bg-lavender px-2 py-1 text-xs text-crayolasblue;
}
.select_checkbox input {
  @apply dark:!z-0;
}
.dynamic_options_dropdown__option {
  @apply !p-0;
}
.dynamic_options_dropdown .combobox_dropdown__option {
  @apply !p-0;
}
.owner_dropdown_v2__option {
  @apply !p-0;
}
.custom_badge {
  @apply flex items-center gap-2 rounded-md border border-dashed border-lightSilver px-2 py-1 font-inter-medium text-xs dark:border-kettleman;
}
.si.fill.badge_icon path {
  @apply dark:fill-battleshipgray;
}
.si.fill.badge_icon circle {
  @apply dark:stroke-battleshipgray;
}
.upload_file_attachments {
  @apply cursor-pointer rounded-lg border border-dashed border-brightgray bg-ghostwhite px-4 py-8 text-sm text-aurometalsaurus dark:border-jet dark:bg-darkjunglegreenss dark:text-greychateau;
}
/* to hide the extra icon in word editor */
.e-documenteditorcontainer .e-de-status-bar {
  @apply !block;
}
.e-documenteditorcontainer .e-de-statusbar-pageweb {
  @apply !hidden;
}
.upload_file {
  @apply relative z-20 inline-block cursor-pointer items-center gap-2 overflow-hidden rounded-lg border border-transparent !bg-richblack font-inter-medium text-sm text-white hover:!bg-midnightexpress focus:outline focus:outline-[3px] focus:outline-brightgray active:!bg-blackknight disabled:cursor-not-allowed disabled:!bg-antiflashwhite disabled:text-santagrey disabled:opacity-75 dark:border-transparent dark:!bg-gray_900 dark:text-mirage dark:hover:!bg-gray_800 dark:focus:bg-gray_800 dark:focus:outline-gray_200  dark:active:!bg-gray_900 dark:disabled:!bg-gray_100 dark:disabled:text-gray_400;
}
.agreementTabCommonTableContainer .searchCommonTableBtn {
  @apply !ml-0;
}
.upload_file {
  @apply relative inline-block cursor-pointer overflow-hidden rounded-lg bg-midnightexpress text-sm text-white;
}
.upload_file input.upload {
  @apply absolute right-0 top-0 w-[6.125rem] opacity-0;
}

.arrow_up_right_icon {
  @apply h-1.5 w-1.5;
}

.arrow_up_right_icon path {
  @apply !fill-current;
}

.table_column_name {
  @apply !ml-2;
}
.dropdown_option_show {
  overflow: initial !important;
}

.assessment_type_dropdown .common_dropdown_v2__menu-list {
  @apply p-0;
}

.assessment_type_dropdown .common_dropdown_v2__option {
  @apply !bg-transparent !p-0;
}

.assessment_type_dropdown .common_dropdown_v2__option .assessment_type_option {
  @apply flex w-full justify-between rounded px-2 py-1 text-sm font-medium text-brightgrey dark:text-gray_700;
}

.assessment_type_dropdown .common_dropdown_v2__option--is-focused .assessment_type_option {
  @apply bg-cascading_white text-brightgrey dark:bg-raisinblack dark:text-uniquegrey;
}

.assessment_type_dropdown .common_dropdown_v2__group {
  @apply p-0;
}

.assessment_type_dropdown .common_dropdown_v2__group > div:last-child {
  @apply flex flex-col gap-1 p-2;
}

.assessment_type_dropdown .common_dropdown_v2__group-heading {
  @apply p-0 capitalize;
}

.questionnaire_dropdown {
  @apply !w-auto min-w-[7rem];
}

.questionnaire_dropdown .common_dropdown_v2__control {
  @apply border-none !px-0 !py-0.5 text-xs font-medium text-gray-600;
}

.date_picker_icon path {
  @apply !fill-current;
  fill-opacity: 1;
}

.toast_v2 {
  @apply flex w-full gap-2;
}

.toast_v2_body > div:last-child {
  @apply w-full font-inter-medium text-sm font-medium text-brightgrey;
}

.Toastify__toast-theme--dark .toast_v2_body > div:last-child {
  @apply w-full font-inter-medium text-sm font-medium text-gray_700;
}

.toast_v2 .Toastify__close-button {
  @apply !self-center;
}
.criticality_badge .common_dropdown_v2__multi-value {
  @apply !bg-mistyrose;
}
.criticality_badge .common_dropdown_v2__multi-value__label {
  @apply !text-lavared;
}
.criticality_badge .common_dropdown_v2__multi-value__remove svg path {
  @apply !fill-lavared;
}
.services_select .common_dropdown_v2__multi-value {
  @apply max-w-full;
}
.simple_table_th:first-child .simple_table_header {
  @apply flex items-center gap-2 pl-0;
}
.simple_table_th {
  @apply !p-0;
}

.simple_table_th .simple_table_header {
  @apply select-none px-3 py-[0.625rem];
}
/* Report module start */
#dashboard_layout {
  margin-bottom: 1rem;
  height: max-content !important;
}

.e-panel-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.e-panel-content svg {
  width: 100%;
}

#dashboard_layout .e-panel {
  page-break-after: always;
}

@media print {
  .print-ignore {
    @apply !hidden;
  }
  #dashboard_layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: max-content !important;
  }

  .main-content-style {
    width: 100vw;
    position: unset;
    overflow: visible;
  }

  #dashboard_layout .e-panel {
    height: max-content !important;
    max-width: 100% !important;
    position: unset !important;
  }
}

/* Common btn css*/

.btn-primary-v2 {
  @apply flex items-center gap-2 rounded-lg border border-transparent !bg-richblack px-3 py-1 font-inter-medium text-sm text-white hover:!bg-midnightexpress focus:outline focus:outline-[3px] focus:outline-brightgray active:!bg-blackknight disabled:cursor-not-allowed disabled:!bg-antiflashwhite disabled:!text-santagrey disabled:opacity-75 dark:border-transparent dark:!bg-antiflashwhites dark:!text-mirage dark:hover:!bg-steam dark:focus:!bg-steam dark:focus:outline-jet  dark:active:!bg-antiflashwhites dark:disabled:!bg-raisinblack dark:disabled:!text-agedmoustachegrey;
}

.btn-secondary-v2 {
  @apply flex items-center gap-2 rounded-lg border border-brightgray !bg-white px-3 py-1 font-inter-medium text-sm text-richblack shadow-sm hover:!bg-ghostwhite focus:!bg-ghostwhite focus:outline focus:outline-[3px] focus:outline-brightgray active:!bg-antiflashwhite disabled:cursor-not-allowed disabled:!text-santagrey disabled:opacity-75 dark:border-jet dark:!bg-mirage dark:text-lighthouse dark:hover:!bg-mirage dark:focus:!bg-mirage dark:focus:outline-jet dark:active:!bg-raisinblack dark:active:text-lighthouse dark:disabled:!bg-mirage dark:disabled:!text-gray_300;
}

/* Report module end */

/* Common Table | View Options Start*/
.view-options-menu {
  @apply w-64;
}
.view-options-menu .dropdown-search .search-input {
  @apply w-full rounded-t-lg border-none bg-transparent text-sm outline-none placeholder:text-gray-400 dark:!bg-transparent;
}
.view-options-menu .dropdown-menu {
  @apply flex  flex-col gap-1 p-1;
}
.view-options-menu .dropdown-menu .li-group-label {
  @apply px-2 pt-1 text-xs font-medium text-santagrey dark:text-westchestergrey;
}
.view-options-menu .dropdown-menu .li-group {
  @apply flex flex-col gap-1;
}

.view-options-menu .dropdown-menu li.view_option {
  @apply rounded px-2 py-1 hover:bg-mournmountainsnow dark:hover:bg-nero;
}
.view-options-menu .dropdown-menu li.view_option .drag-indicator {
  @apply cursor-grab stroke-richblack active:cursor-grabbing dark:stroke-lighthouse;
}
.view-options-menu .dropdown-menu li.view_option .drag-indicator circle {
  @apply fill-richblack dark:fill-lighthouse;
}
.view-options-menu .dropdown-menu li.view_option button:disabled .drag-indicator {
  @apply cursor-not-allowed;
}
.view-options-menu .dropdown-menu li.view_option > .view_option_wrapper {
  @apply flex w-full items-center gap-2 disabled:cursor-not-allowed;
}
.view-options-menu .dropdown-menu li.view_option p.fallback-message {
  @apply text-sm font-medium text-gray-700 dark:text-battleshipgray;
}
.view-options-menu .dropdown-menu li.view_option .view-body {
  @apply flex flex-1 items-center gap-2;
}
.boardView {
  @apply grid gap-1 p-1;
}
.view-options-menu .dropdown-menu li.view_option .view-body .title,
.boardView .title {
  @apply text-sm font-medium text-brightgrey dark:text-brushedmetal;
}
.view-options-menu .dropdown-menu li.view_option .view-body .toggle-visibility-icon,
.boardView .view_icon {
  @apply cursor-pointer !fill-battleshipgray;
}
.view-options-menu .status-icon circle {
  @apply dark:stroke-silvers;
}
.view-options-menu .calendar-icon path {
  fill-opacity: 1;
}
.view-options-menu .column-icon.default-icon path {
  fill-opacity: 1;
  @apply fill-aurometalsaurus dark:fill-silvers;
}
.listing_dropdown_wrapper {
  @apply p-1;
}

.manage_option_dropdown_wrapper {
  @apply absolute right-0 top-[2.59rem] z-[15] rounded-lg border border-brightgray bg-white shadow-sortingShadow dark:border-black_lacquer dark:bg-mirage;
}
.manage_option_dropdown_wrapper li {
  transition: all 0.4s ease-in-out;
  @apply flex cursor-pointer items-center gap-2 rounded px-2 py-1;
}
.manage_option_dropdown_wrapper li:hover {
  @apply bg-antiflashwhites dark:bg-raisinblack;
}
.manage_option_list {
  @apply grid w-[221px] gap-1 p-1 pt-2;
}
.manage_option_inner_list {
  @apply flex w-64 items-center gap-2 p-2 text-sm;
}
/* Common Table | View Options End*/

.modal_mobileview_center.vendor-delete-modal,
.modal_mobileview_center.confirm-delete-modal,
.modal_mobileview_center.questionnaire-delete-modal {
  background: rgba(29, 29, 31, 0.5);
}
.vendor-delete-modal > div > div > div h3,
.confirm-delete-modal > div > div > div h3,
.questionnaire-delete-modal > div > div > div h3 {
  @apply font-inter-medium text-sm;
}

/* Listing dropdown menu */
.listing_dropdown {
  @apply relative;
}
.listing_button {
  @apply rounded p-1 hover:bg-antiflashwhite;
}
.listing_button:hover svg path {
  fill-opacity: 1;
  @apply fill-brightgrey;
}
.listing_dropdown_wrapper ul li button {
  transition: all 0.4s ease-in-out;
  @apply flex w-full cursor-pointer items-center gap-2 rounded px-2 py-1 text-sm hover:bg-antiflashwhites dark:hover:bg-raisinblack;
}
.si.fill.table_circle_icon path {
  @apply dark:!fill-battleshipgray;
}
.si.stroke.table_circle_icon {
  @apply dark:!stroke-battleshipgray;
}
.listing_dropdown_wrapper ul li:not(:last-child) button {
  @apply mb-1;
}
.next_step_dropdown li {
  @apply flex cursor-pointer items-center justify-between !gap-2.5 rounded !px-2 !py-1 hover:bg-antiflashwhites dark:hover:bg-raisinblack;
}
.next_step_dropdown li:not(:last-child) {
  @apply mb-1;
}
.next_step_dropdown li > div {
  @apply flex items-center gap-2;
}
.right_check_icon path {
  @apply !fill-richblack dark:!fill-lighthouse;
}
.si.stroke.back_arrow path {
  @apply stroke-davygrey dark:stroke-silvers;
}

/* Common search button */
.common_search_btn {
  @apply flex min-h-[2.125rem] items-center rounded-lg border border-brightgray transition-all dark:border-jet;
}
.common_search_btn > span {
  @apply rounded-md hover:bg-antiflashwhite dark:hover:bg-raisinblack;
}
.common_search_btn > span svg path {
  @apply dark:!stroke-silvers;
}
.search_input {
  transition: all 0.4s ease-in-out;
  @apply invisible w-0 opacity-0;
}
.common_search_btn .search_input input {
  @apply w-full border-none  !bg-transparent px-0 py-0 text-sm text-brightgrey dark:!text-lavender_grey;
}
.search_open {
  transition: all 0.4s ease-in-out;
  @apply visible w-[16.125rem] pr-2 opacity-100;
}
.common_search_typing {
  @apply !border-dodgerblue;
}

/* accordion styles for redix-ui */
.AccordionChevron {
  transition: transform 300ms;
}
.AccordionChevron path {
  @apply dark:!stroke-silvers;
}
.AccordionTrigger[data-state="open"] .AccordionChevron {
  transform: rotate(360deg);
}
.AccordionTrigger .AccordionChevron {
  transform: rotate(180deg);
}
.vendors-accordion .AccordionTrigger[data-state="open"] {
  @apply !bg-white dark:!bg-mirage;
}
.vendors-accordion .AccordionTrigger {
  @apply flex w-full justify-between gap-2 !bg-ghostwhite p-4 font-inter-medium text-sm hover:!bg-antiflashwhite dark:!bg-blackOut dark:text-lighthouse dark:hover:!bg-jet;
}
.vendors-accordion .AccordionContent {
  @apply px-4 py-2;
}
/* accordion styles for redix-ui - end */

.gridCards {
  @apply h-full;
}

.kanbanGroup:has(.ghostClass) {
  @apply bg-ghostwhite dark:bg-mirage;
}
.gridCards .ghostClass {
  @apply !cursor-grabbing opacity-20;
}
.gridCards .ghostClass ~ .hiddenClass {
  @apply block;
}

/*Company Dropdown style start*/
.arrow-toggle-v2 {
  @apply rounded-md p-1 hover:bg-ghostwhite  hover:dark:bg-mirage;
}

.arrow-toggle-v2 svg {
  transform: rotate(180deg);
  @apply !mr-0;
}

.arrow-toggle-v2 svg path {
  @apply stroke-aurometalsaurus hover:!stroke-gray-600 dark:stroke-greychateau;
}

.avatar_v2 {
  @apply flex h-full w-full items-center !justify-center !bg-brightgray px-1.5 py-1 dark:!bg-jet dark:!text-white;
}

.avatar_v2_sm {
  @apply h-6 w-6 text-xs;
}

.avatar_v2_smd {
  @apply h-5 w-5 min-w-[1.25rem] text-xs;
}

.avatar_v2_regular {
  @apply h-8 w-8 text-sm;
}

.avatar_v2_md {
  @apply h-12 w-12 text-base;
}

.avatar_v2_lg {
  @apply h-20 w-20 text-3xl;
}

.avatar_v2_xl {
  @apply h-36 w-36 text-5xl;
}

.avatar_v2_xs {
  @apply h-4 w-4 text-xs;
}

.avatar_v2_rounded {
  @apply rounded-full;
}

.avatar_v2_squared {
  @apply rounded;
}

.company_dropdown_content {
  @apply flex flex-col gap-1 rounded-lg border border-brightgray bg-emptiness py-1 dark:border-jet dark:bg-mirage;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
/* TODO remove testing for common component */
.error_icon path {
  @apply !stroke-valentinered;
}
/* TODO remove  */

/*Company Dropdown style end*/
.si.fill.fill_current_icon path {
  @apply !fill-current;
}

.si.stroke.fill_current_icon path {
  @apply !stroke-current;
}

.combobox_dropdown_wrapper {
  @apply relative;
}
.combobox_dropdown_wrapper > div {
  @apply absolute top-10 z-10 min-w-[13rem];
}
.combobox_dropdown_wrapper.member_dropdown > div {
  @apply top-8;
}
.combobox_dropdown {
  @apply flex min-w-[9.1875rem] items-center rounded-lg border border-brightgray px-3 py-1.5 text-sm focus:bg-antiflashwhite dark:border-stiletto dark:bg-darkjunglegreens focus:dark:bg-raisinblack;
}
.list_dropdown {
  @apply !border-none;
}
.combobox_dropdown_wrapper.list_dropdown > div {
  @apply relative top-0 max-w-[16rem];
}
.list_dropdown .combobox_dropdown__control {
  @apply !rounded-none !border-b !border-l-0 !border-r-0 !border-t;
}
.list_dropdown .combobox_dropdown__menu {
  @apply !relative !top-0 !mb-0 !border-none;
}
.list_dropdown .combobox_dropdown__menu-list {
  @apply !border-none !pt-0;
}
.owner_dropdown .combobox_dropdown_arrow.si.fill path {
  @apply fill-richblack dark:!fill-lighthouse;
}
.si.fill.combobox_dropdown-arrow path {
  @apply dark:fill-antiflashwhite;
}
.combobox_dropdown > div > svg path {
  @apply !fill-davygrey dark:!fill-silvers;
}
.combobox_dropdown__indicators {
  @apply !hidden;
}
.combobox_dropdown__menu {
  @apply !mt-0 !rounded-none !rounded-b-lg border-x border-brightgray !shadow-none dark:border-blacksmoke;
}
.combobox_dropdown__control {
  @apply !min-h-[2.1875rem] !rounded-none !rounded-t-lg !border-brightgray bg-white dark:!border-blacksmoke dark:!bg-raisinblack;
}
.combobox_dropdown__menu-list {
  @apply rounded-b-lg border-b border-brightgray dark:border-blacksmoke;
}
.combobox_dropdown__option {
  @apply rounded !bg-brilliance !px-2 !py-2 hover:!bg-antiflashwhite dark:!bg-mirage dark:text-silverpolish dark:hover:!bg-tricornblack;
}
.combobox_dropdown__menu-list .common_create_dropdown_addIcon {
  @apply mt-1 rounded border-none bg-white px-2 py-2 text-brightgrey hover:bg-antiflashwhite hover:text-brightgrey dark:bg-mirage dark:text-brushedmetal dark:hover:bg-tricornblack;
}
.combobox_dropdown__menu-list .common_create_dropdown_addIcon span svg {
  @apply mr-2 h-4 w-4;
}
.combobox_dropdown__menu-list .common_create_dropdown_addIcon span svg path {
  @apply dark:fill-uniquegrey;
}
.combobox_dropdown__menu-list > div {
  @apply border-b px-1 pb-1 dark:border-blacksmoke;
}
.combobox_dropdown__menu-list {
  @apply !border-x-0 !border-t-0;
}
.vendor_list_modal > div {
  @apply max-w-[77rem];
}
.vendor_list_modal > div > div {
  @apply dark:bg-mirage;
}
.vendor_list_modal .vendor_list_title {
  @apply !py-3 md:!px-8;
}
.vendor_list_modal .vendor_list_title h3 {
  @apply w-full;
}
.vendor_list_modal .list_name_textarea textarea {
  @apply !p-0;
}

/* owner dropdown design */
.owner_dropdown .combobox_dropdown {
  @apply border-none bg-white p-0 focus-visible:outline-none dark:bg-transparent;
}
.combobox_dropdown_wrapper.owner_dropdown > div {
  @apply z-10 min-w-[30rem];
}
.combobox_dropdown_wrapper.member_dropdown {
  @apply flex;
}
.common_dropdown_v2__control {
  @apply !px-3;
}
.vendor_list_modal .fill.list_icon path {
  @apply dark:fill-battleshipgray;
}
.owner_dropdown .si.fill.select_arrow path {
  @apply !fill-aurometalsaurus dark:!fill-battleshipgray;
}
.add_btn .icon-smd.si.fill path {
  @apply dark:fill-silvers;
}
.add_btn {
  @apply rounded-md p-1 hover:bg-antiflashwhite dark:hover:bg-raisinblack;
}
.add_btn:hover svg path {
  @apply dark:!fill-silverstone;
}
.custom_checkbox input[type="checkbox"]:checked {
  @apply !border-transparent !bg-dodgerblue;
}
.custom_checkbox input[type="checkbox"] {
  @apply rounded-[0.25rem];
}
.combobox_dropdown-close {
  @apply rotate-180;
}
.combobox_dropdown-open {
  @apply rotate-0;
}
.si.fill.checkmark_icon path {
  @apply fill-richblack dark:fill-lighthouse;
}
.combobox_dropdown_wrapper.member_dropdown .combobox_dropdown__menu-list > div,
.combobox_dropdown_wrapper.owner_dropdown .combobox_dropdown__menu-list > div {
  @apply border-b-0;
}
.owner_dropdown .combobox_dropdown__control {
  @apply !border-b;
}
.owner_dropdown .combobox_dropdown__menu-list {
  @apply !px-1 pb-0 pt-1;
}
.list_search__indicators {
  @apply !hidden;
}
.list_search__option {
  @apply !p-0;
}
.share-dropdown-btn[data-state="open"] {
  @apply bg-antiflashwhite dark:bg-raisinblack;
}
.list_search__value-container {
  @apply !pl-8;
}
.si.stroke.vendor_search_icon path {
  @apply dark:!stroke-battleshipgray;
}

.combobox_dropdown_wrapper .combobox_dropdown__control {
  @apply cursor-text border-b-0 !bg-brilliance dark:!bg-mirage;
}
.combobox_dropdown_wrapper .combobox_dropdown__menu-list {
  @apply !bg-brilliance px-0 pb-0 dark:!bg-mirage;
}
.owner_dropdown .combobox_dropdown__menu-list,
.member_dropdown .combobox_dropdown__menu-list {
  @apply pb-0;
}
.member_search_dropdown {
  @apply relative z-10 min-w-[30rem];
}
.member_search_dropdown .combobox_dropdown__control {
  @apply !bg-brilliance dark:!bg-mirage;
}
.member_search_dropdown .combobox_dropdown__menu-list {
  @apply !bg-brilliance dark:!bg-mirage;
}
.member_search_dropdown .combobox_dropdown__menu {
  @apply border-t-0;
}
.member_search_dropdown .combobox_dropdown__menu-list > div {
  @apply border-b-0 pb-0;
}
.member_search_dropdown .common_dropdown_v2 {
  @apply rounded-tr-md;
}
.member_search_dropdown .combobox_dropdown__value-container {
  @apply cursor-text;
}
.member_search_dropdown .combobox_dropdown__menu-list > div {
  @apply px-0;
}
.combobox_dropdown.combobox_active {
  @apply bg-antiflashwhite dark:bg-raisinblack;
}
.combobox_dropdown__input-container input {
  @apply !bg-transparent;
}
.associated_dropdown .combobox_dropdown__menu-list > div {
  @apply border-b-0 border-none;
}

.associated_dropdown .combobox_dropdown__menu-notice--loading,
.associated_dropdown .combobox_dropdown__menu-notice--no-options {
  @apply !p-1 text-sm;
}

.associated_dropdown.variant_dropdown.combobox_dropdown__menu,
.associated_dropdown .variant_dropdown .combobox_dropdown__group-heading {
  @apply m-0;
}

.associated_dropdown .variant_dropdown .combobox_dropdown__option {
  @apply mb-1 rounded !px-2 !py-1;
}

.associated_dropdown .variant_dropdown .combobox_dropdown__option:last-child {
  @apply mb-0;
}

.modal_svg path {
  @apply !cursor-pointer;
}

.multitag_spacing input {
  @apply dark:!bg-transparent;
}
.main_wrapper {
  height: calc(100% - 61px);
  @apply overflow-auto;
}
.main_grid_wrapper {
  @apply !overflow-hidden;
}
.main_grid_wrapper > .data-table {
  @apply !min-w-full;
}
.main_wrapper > .data-table {
  @apply h-full min-w-[70rem];
}
.main_wrapper::-webkit-scrollbar,
.custom_scroll::-webkit-scrollbar {
  @apply w-2;
}
.badge_check {
  @apply h-4 w-4 !p-0;
}
.common_modal_header {
  @apply !flex !items-center !justify-between !rounded-t !border-b-brightgray bg-white !px-4 !py-3 dark:!border-b-jet dark:bg-mirage;
}
.common_modal_header h3 {
  @apply w-full font-inter-medium text-sm !text-richblack dark:!text-lighthouse;
}
.modal_wrapper .common_modal_body {
  @apply !rounded-b;
}
.common_modal_body,
.common_modal_footer {
  @apply bg-white !p-4 dark:bg-mirage;
}
.common_modal_footer {
  @apply !border-t-brightgray dark:!border-t-jet;
}
.bread_crumb_icon path {
  @apply !fill-aurometalsaurus dark:!fill-battleshipgrays;
}
.bread_crumb_list li a:hover > p {
  @apply text-brightgrey dark:!text-white;
}
.bread_crumb_list li a:hover .bread_crumb_icon path {
  @apply !fill-brightgrey dark:!fill-shiningSliver;
}

/* Toggle switch design start */
.toggle-switch input {
  @apply hidden appearance-none;
}
.toggle-switch label {
  transition: 0.3s;
  @apply relative mb-0 flex h-[34px] w-full cursor-pointer items-center justify-center rounded-lg bg-antiflashwhite dark:bg-raisinblack;
}
.toggle-switch button {
  @apply h-full w-10 px-3 py-2;
}
.toggle-switch button.active {
  @apply z-0 rounded-lg border border-brightgray bg-white dark:border-stiletto dark:bg-mirage;
}
.toggle-switch button svg {
  @apply relative top-0 z-10 m-auto block;
}
.toggle-switch.active button:first-child:after {
  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
}
/* Toggle switch design end */
.code_bracket_icon path {
  @apply h-3 w-3.5;
}

/*comments lexical textarea typeahead*/

.typeahead_popover_v2 {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  @apply flex flex-col rounded-lg border border-brightgray bg-brilliance dark:border-jet dark:bg-dynamicblack;
}
.typeahead_popover_v2 ul {
  @apply m-0 max-h-[200px] list-none overflow-y-scroll rounded-b-lg bg-brilliance p-0 dark:bg-dynamicblack;
}
.typeahead_popover_v2 ul::-webkit-scrollbar {
  @apply hidden;
}
.typeahead_popover_v2 ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead_popover_v2 ul li {
  @apply m-0 min-w-[180px] cursor-pointer text-sm outline-none;
}

.typeahead_popover_v2 ul li div.selected {
  @apply bg-antiflashwhites dark:bg-nero;
}

.typeahead_popover_v2 li {
  @apply m-0 flex w-full p-1;
}
.typeahead_popover_v2 li div {
  @apply flex w-full cursor-pointer items-center gap-2 rounded-lg border-0 bg-transparent px-2 py-1.5;
  word-break: break-word;
}

.typeahead_popover_v2 li.active div {
  @apply flex h-5 w-5 bg-contain;
}

.typeahead_popover_v2 li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead_popover_v2 li:last-child {
  @apply rounded-b-lg rounded-t-none;
}

.typeahead_popover_v2 li div:hover {
  @apply bg-antiflashwhites dark:bg-nero;
}

.typeahead_popover_v2 li div .text {
  @apply flex min-w-[150px] grow font-inter-semibold text-xs text-richblack dark:text-lighthouse;
}

.typeahead_popover_v2 li div .icon {
  @apply flex h-6 min-h-[1.5rem] w-6 min-w-[1.5rem] select-none bg-contain bg-center bg-no-repeat text-xs leading-4;
}
.item_v2 {
  @apply m-0;
}
.textarea_editor_v2 span.mention {
  @apply !bg-transparent text-xs text-dodgerblue;
}
.vendor_group_fill_icon path {
  @apply dark:!fill-nickel;
}
.vendor_group_stroke_icon path,
.vendor_group_stroke_icon circle {
  @apply !stroke-aurometalsaurus dark:!stroke-nickel;
}
.vendor_checkmark {
  @apply ml-auto;
}
.vendor_checkmark path {
  @apply !fill-richblack dark:!fill-whiteSmoke;
}
.vendor_disabled_fill_icon path,
.vendor_disabled_fill_icon circle {
  @apply !fill-lightSilver dark:!fill-neonNazar;
}

.vendor_disabled_stroke_icon path,
.vendor_disabled_stroke_icon circle {
  @apply !stroke-lightSilver dark:!stroke-neonNazar;
}
.warning-fill path {
  @apply !fill-richblack !stroke-none dark:!fill-lighthouse;
}
.error_back_icon path {
  @apply !stroke-mirage dark:!stroke-lighthouse;
}
.vendor_status_title {
  @apply flex w-fit items-center gap-1 rounded-md border border-brightgray px-1.5 py-0.5 dark:border-jet  dark:!text-gray_600 dark:text-silvers;
}
.vendor_status_title h5 {
  @apply text-xs text-davygrey dark:text-silvers;
}
.agreement_dropdown_icon.fill path {
  fill-opacity: 1;
  @apply fill-aurometalsaurus dark:fill-battleshipgray;
}
.comment_dropDown_icon path {
  @apply fill-davygrey dark:!fill-silvers;
}
.comment_dropdown {
  min-width: 8.125rem;
  @apply z-50;
}
.comment_dropdown .DropdownMenuItem svg path {
  @apply dark:!fill-battleshipgray;
}

.comment_reopen_icon.si.fill path {
  @apply fill-none stroke-aurometalsaurus;
}

.dark .comment_dropdown .DropdownMenuItem svg.comment_reopen_icon path {
  @apply !fill-none stroke-battleshipgray;
}

.vendor_tabs {
  min-width: inherit !important;
}

.si.exclamation-triangle path {
  @apply !stroke-none;
}

.comment_textarea .comment_footer {
  @apply sticky bottom-0 left-0 bg-white dark:bg-mirage;
}

.comment_list_view .textarea_editor_v2 {
  @apply !bg-transparent;
}
.comment_list_view .textarea_editor_v2 p {
  @apply !text-richblack dark:!text-uniquegrey;
}

.metadata_tooltip {
  min-width: 3.125rem;
  max-width: 50rem;
  text-align: center;
  @apply !rounded !px-2 !py-1 !font-inter-medium !text-xs !text-antiflashwhites dark:!bg-antiflashwhites dark:!text-chromaphobicBlack;
}
.status_tooltip > div {
  width: initial !important;
}
.step_dropdown {
  @apply min-w-[9.1875rem];
}
.truncate_full {
  width: 99%;
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}
.icon_fill-opacity path {
  fill-opacity: 1;
}
/* Filter Dropdown */
.filter_dropdown {
  @apply my-1;
}
.condition-dropdown {
  @apply relative mr-3 border-none p-0 py-2 !pl-0 !pr-7 font-inter-medium text-xs text-santagrey dark:!bg-transparent;
}
.input-group {
  @apply relative flex items-center rounded-lg border border-brightgray px-3 dark:border-stiletto;
}
.input-group input {
  @apply w-full border-y-0 border-l border-r-0 border-brightgray py-2 pl-3 pr-0 text-sm text-santagrey focus:!border-brightgray dark:!bg-transparent dark:focus:!border-stiletto;
}
.input-group select:not([size]) {
  background: url("../images/icons/filter-down.svg");
  background-position: right 0.5rem center;
  @apply cursor-pointer bg-no-repeat;
}
.dark .input-group select:not([size]) {
  background: url("../images/icons/dark-filter-down.svg");
  background-position: right 0.5rem center !important;
  background-size: 10px 10px !important;
  @apply bg-no-repeat;
}
.si.fill.tabthemeicon path {
  @apply fill-davygrey dark:fill-silvers;
}
.si.stroke.tabthemeicon path {
  @apply stroke-davygrey dark:stroke-silvers;
}
.comment_dropdown.multiple_select {
  min-width: 11.25rem;
}
.modal_transparent_bg {
  @apply !bg-transparent;
}
.modal_shadow_xl > div > div {
  @apply !shadow-xl;
}
/* skeleton design start */
.gridCards .react-loading-skeleton {
  @apply rounded-md  bg-antiflashwhite dark:bg-raisinblack;
}
.gridCards .react-loading-skeleton::after {
  background-image: linear-gradient(
    90deg,
    #f3f4f6 20%,
    rgba(243, 244, 246, 0.5) 35%,
    #fff 50%,
    rgba(249, 250, 251, 0.5) 65%,
    #f3f4f6 80%
  );
  @apply rounded-md;
}
.dark .gridCards .react-loading-skeleton::after {
  background-image: linear-gradient(
    90deg,
    #262628 20%,
    #333335 35%,
    #4a4a4c 50%,
    #333335 65%,
    #262628 80%
  );
}
/* skeleton design end */

.lexical_submit_button:disabled .si.fill.fill_current_icon path {
  @apply !fill-santagrey dark:!fill-boulder;
}

/*currency input group dropdown css start*/
.currency_input_dropdown {
  @apply rounded-l-lg border border-brightgray px-2 py-1 dark:border-jet;

  &.small {
    @apply border border-brightgray px-2 py-0 dark:border-jet;
  }
}
.currency_input_dropdown .common_dropdown_v2 {
  @apply min-w-[25rem];
}
/*currency input group dropdown css end*/

.multitag_spacing input {
  @apply !px-0;
}
@screen mac13Inch {
  .form_control.form_selection {
    @apply !px-0 !shadow-none;
  }
  .form_control.form_selection input {
    @apply !p-0;
  }
}

.primary_btn_disabled:disabled .si.fill path {
  @apply !fill-santagrey;
}

.lock-closed-icon path {
  @apply !fill-richblack dark:!fill-antiflashwhite;
}

.react-datepicker__tab-loop {
  @apply absolute top-[2.375rem];
}
.row_three_dots_icon svg {
  @apply h-5 w-5;
}
.row_three_dots_icon path {
  fill-opacity: 1;
  @apply fill-gray_300;
}

.feed-item-impact-icon path {
  fill-opacity: 1;
  @apply !fill-red-600;
}
.book-read-green path {
  fill-opacity: 1;
  @apply !fill-green-600 !stroke-green-600;
}

.multiTages__value-container {
  @apply gap-2 !py-0;
}
.multiTages__indicator-separator {
  @apply hidden;
}
.multiTages__indicators .clear_btn {
  @apply mr-3 grid h-5 w-5 place-content-center rounded border border-brightgray bg-white shadow-sm;
}
.multiTages__input-container {
  @apply !my-0;
}
.multiTages__dropdown-indicator {
  @apply mr-3 !p-0;
}
.multiTages__dropdown-indicator svg {
  @apply h-[1.125rem] w-[1.125rem];
}
.multiTages__dropdown-indicator svg path {
  @apply fill-aurometalsaurus dark:fill-greychateau;
}
.multiTages__input {
  @apply dark:!bg-transparent;
}
.multiTages__clear-indicator {
  @apply mr-2 cursor-pointer !p-0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.advisense-theme,
.advisense-theme * {
  @apply !font-hkgrotesk-regular;
}

.common-search-sm {
  @apply !min-h-[28px];
}
.common-search-sm > span {
  @apply min-h-[28px];
}

.article_assign_dropdown .combobox_dropdown__control {
  @apply !rounded-none !border-l-0 !border-r-0;
}

.article_assign_dropdown .combobox_dropdown__menu {
  @apply !rounded-none !border-0;
}

.article_assign_dropdown .combobox_dropdown__menu-list {
  @apply !rounded-none !border-0 !p-0;
}
