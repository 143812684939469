.emojiHeader {
  @apply flex overflow-x-auto border-b-2 border-b-antiflashwhite;
}
:global(body.dark) .emojiHeader {
  @apply border-thunders;
}
.emojiHeader::-webkit-scrollbar {
  @apply h-1 cursor-pointer;
}

.emojiHeader li,
.emojiBody span {
  @apply inline-block cursor-pointer px-2 py-1 hover:bg-brightgray;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.emojiBody span {
  @apply w-8;
}
.emojiHeader li + li,
.emojiBody span + span {
  @apply pl-2;
}
.emojiBody {
  @apply max-h-32 overflow-y-auto;
}
.active {
  @apply bg-brightgray;
}
:global(body.dark) .active,
:global(body.dark) .emojiHeader li:hover,
:global(body.dark) .emojiBody span:hover {
  @apply bg-iridium;
}
.emojiBody::-webkit-scrollbar {
  @apply hidden;
}
