.kanban_col {
  @apply grid w-[19.844rem] gap-4 py-4 text-base font-semibold text-mirage dark:text-gray-300 mac13Inch:text-sm;
}

:global(body.dark) .kanban_col {
  @apply text-white;
}

.itemDrag {
  @apply shadow-lg;
}
.taskModalHeader {
  @apply border-b px-4 py-4 md:px-6 !important;
}

.tasksBlock {
  @apply max-h-[63vh] overflow-x-hidden overflow-y-scroll;
}
.tasksBlock::-webkit-scrollbar {
  @apply hidden;
}

:global(body.dark) .statusDropdown select {
  @apply border-iridium !bg-balticsea !text-greychateau placeholder-stormdust;
}

.statusDropdown > div {
  @apply w-auto;
}
.statusDropdown > div > button {
  @apply flex w-full justify-between;
}
.downloadModalHeader {
  @apply border-b p-4 text-lg font-normal text-mirage md:p-[1.375rem]  !important;
}

.toggleImg {
  @apply h-8 w-8 cursor-pointer self-center border-2 border-transparent hover:rounded-[0.25rem] hover:border-lightSilver hover:bg-ghostwhite;
}
.fieldLabel {
  @apply mb-2 ml-[1px] text-sm font-medium dark:text-white;
}
:global(body.dark) .fieldLabel {
  @apply text-white;
}
.fieldValue {
  width: calc(100% - 30px);
  word-wrap: break-word;
  @apply ml-2 text-sm font-medium text-aurometalsaurus dark:!text-greychateau;
}
:global(body.dark) .fieldValue {
  @apply text-greychateau;
}

.task_tabs button[aria-selected="true"],
.status_tabs button[aria-selected="true"] {
  @apply relative !border-b-0 !border-t-2 border-t-mirage text-mirage;
}

.task_tabs button[aria-selected="true"]:focus-visible,
.status_tabs button[aria-selected="true"]:focus-visible {
  @apply outline-none ring-opacity-0;
}

.task_tabs button[aria-selected="true"]::before,
.status_tabs button[aria-selected="true"]::before {
  content: "";
  @apply absolute !bottom-0 left-0 z-[1] h-[2px] w-full bg-white;
}
.addTaskButton {
  @apply flex w-full items-center justify-center gap-2 rounded-lg border-2 border-dashed border-brightgray py-2 font-inter-medium font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-100 hover:text-mirage mac13Inch:text-sm;
}
:global(body.dark) .addTaskButton {
  @apply border-iridium text-greychateau hover:bg-balticsea hover:text-white;
}
:global(body.dark) .addTaskButton:hover svg path {
  @apply !fill-white;
}
.addTaskButton:hover svg path {
  @apply fill-mirage;
}
:global(body.dark) .addTaskButton svg path {
  @apply !fill-greychateau;
}
.addStatusButton {
  @apply m-0 flex h-56 w-80 items-center justify-center rounded-lg border-2 border-dashed border-gray-200 py-2 font-semibold text-gray-500  hover:border-gray-300 hover:bg-antiflashwhite hover:text-gray-900;
}
.addStatusButton svg {
  @apply h-8 w-8;
}
:global(body.dark) .addStatusButton {
  @apply border-thunders hover:bg-balticsea;
}

@screen maxMd {
  .task_tabs > div button {
    @apply px-2;
  }
  .status_tabs > div button {
    @apply px-2;
  }
}
.calendarGridview button {
  @apply mac13Inch:items-center mac13Inch:text-sm;
}
.calendarGridview button svg {
  @apply mac13Inch:h-4 mac13Inch:w-4;
}
.tasksEditor {
  @apply bg-transparent;
}
.taskCommentList {
  @apply !mt-4;
}
.taskCommentList li {
  @apply !pb-0;
}
.taskCommentEditorPlaceholder {
  @apply !static;
}
.userAvtarContainer {
  @apply grid grid-cols-3 gap-2;
}
.userAvtarContainer > div {
  word-break: break-all;
  @apply flex items-center rounded-md bg-ghostwhite px-3 py-1;
}
