.account_content {
  @apply border-b border-brightgray py-4 pl-11 pr-6 maxMd:px-4 mac13Inch:pl-6;
}
:global(body.dark) .account_content {
  @apply border-thunders;
}
.search_block {
  @apply relative flex items-end justify-between;
}
.search_field {
  @apply w-full md:w-2/5 maxMd:max-w-full;
}
.search_field input {
  @apply mt-4 cursor-text !pl-9;
}
.search_img {
  @apply pointer-events-none absolute left-3 top-7 h-[18px] w-[18px] mac13Inch:!top-[1.45rem];
}
.search_button {
  @apply m-1;
}
.member_list > div {
  @apply shadow-none;
}
.member_list > div {
  overflow: initial;
  @apply maxMd:overflow-auto;
}
.member_list > div {
  @apply h-full;
}
.table_header {
  @apply border-b border-brightgray bg-white font-inter-medium text-xs !font-medium text-mirage;
}
:global(body.dark) .table_header {
  @apply border-thunders;
}
.table_header tr th {
  @apply maxMd:!px-4;
}

.member_list > td {
  @apply p-0 text-mirage;
}
.add_field input {
  width: 95%;
}
@screen maxMd {
  .add_field input {
    @apply w-full;
  }
}

.add_field {
  @apply w-full;
}
.member_list th {
  @apply py-4 text-sm;
}
.member_list td,
.member_list th {
  @apply ml-0 px-0 normal-case;
}
.member_list tr td {
  @apply relative text-aurometalsaurus;
}

.member_list td:first-child,
.member_list th:first-child {
  @apply !pl-11 maxMd:!pl-4 mac13Inch:!pl-6;
}

.memberDropDown {
  width: 18.75rem;
  left: initial !important;
  top: 50px !important;
}
:global(body.dark) .memberDropDown {
  @apply border-solid;
}
.memberDropDownTop {
  min-width: 260px !important;
  @apply top-0 !important;
}
:global(body.dark) .memberDropDown {
  @apply border-iridium bg-thunders;
}
:global(body.dark) .memberDropDown ul li:hover {
  @apply bg-darkjunglegreenss;
}
.member_list tr td:last-child {
  @apply px-5;
}
