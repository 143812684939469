.article_details_grid {
  @apply grid grid-cols-[minmax(32px,auto)_minmax(0px,720px)_minmax(32px,auto)] gap-6 gap-x-0 overflow-y-auto py-8 md:gap-x-6;
}

.article_details_grid > * {
  @apply col-start-2 col-end-3;
}

.article_details_collapsible {
  @apply grid grid-rows-[0fr] transition-grid-template-rows;

  transition-duration: 500ms;
}

.article_details_collapsible_content {
  @apply overflow-hidden;

  grid-row: 1 / span 2;
}

.article_details_collapsible_expanded {
  @apply grid-rows-[1fr];
}

.article_details_actions span[role="status"] {
  @apply leading-5;
}
