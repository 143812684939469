.datePickerV1 {
  @apply !z-50;
}
.datePickerV1 :global(.react-datepicker) {
  @apply !rounded-lg !border-0 bg-white !shadow-md;
}
:global(body.dark) .datePickerV1 :global(.react-datepicker) {
  @apply bg-thunders;
}
.datePickerV1 :global(.react-datepicker__navigation-icon--previous::before) {
  @apply dark:border-white;
}
.datePickerV1 :global(.react-datepicker__navigation-icon) {
  font-size: 5px !important;
}
.datePickerV1 :global(.react-datepicker__header) {
  @apply !m-2 !border-b-0 !bg-white;
}
:global(body.dark) .datePickerV1 :global(.react-datepicker__header) {
  @apply !bg-thunders;
}
.datePickerV1 :global(.react-datepicker__week) {
  @apply flex !gap-[6px];
}
.datePickerV1 :global(.react-datepicker__day-names) {
  @apply flex !gap-3;
}
.datePickerV1 :global(.react-datepicker__day-name) {
  @apply !text-aurometalsaurus;
}
:global(body.dark) .datePickerV1 :global(.react-datepicker__day-name) {
  @apply !text-white;
}
.datePickerV1 :global(.react-datepicker__day) {
  @apply !flex !h-8 !w-8 !items-center !justify-center !rounded-lg hover:!bg-thunders hover:text-white;
}
:global(body.dark) .datePickerV1 :global(.react-datepicker__day) {
  @apply border-greychateau !text-white hover:border;
}
.datePickerV1 :global(.react-datepicker__day--selected) {
  @apply !rounded-lg !border border-mirage !bg-mirage !text-white;
}
:global(body.dark) .datePickerV1 :global(.react-datepicker__day--selected) {
  @apply border-greychateau !bg-transparent;
}
:global(body.dark) .datePickerV1 :global(.react-datepicker__current-month) {
  @apply text-white;
}
