.commonTable {
  @apply w-full pt-2 text-left;
}

.commonTable thead {
  @apply bg-antiflashwhite;
}
:global(body.dark) .commonTable thead {
  @apply bg-balticsea;
}
.commonTable .commonTableBody tr {
  @apply border-b border-brightgray;
}
:global(body.dark) .commonTable tr {
  @apply border-thunders bg-darkjunglegreenss;
}

.commonTable td:nth-child(2) {
  @apply min-w-[9rem];
}

.commonTable td {
  @apply max-w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap break-all px-2;
}
.commonTable th {
  @apply px-2;
}
:global(body.dark) .commonTable thead tr th {
  @apply !bg-balticsea;
}
.commonTable td,
.commonTable th {
  @apply !py-3 text-mirage;
}

:global(body.dark) .commonTable th {
  @apply !text-white;
}

:global(body.dark) .commonTable td {
  @apply !text-greychateau;
}

.checkbox {
  @apply cursor-pointer;
}
:global(body.dark) .checkbox {
  @apply !border-liver !bg-iridium;
}

.commonTable :global(.row-selection) {
  @apply w-14 pl-4;
}
.commonTable tbody tr td {
  @apply !text-sm text-aurometalsaurus;
}
.commonTable tbody tr {
  @apply border-b;
}
.table_list li {
  @apply inline-block;
}
.table_list {
  @apply text-right;
}
.commonTable.documentTable thead th {
  @apply text-sm font-semibold;
}

.documentTable thead tr th:first-child,
.documentTable tbody tr td:first-child {
  @apply pl-4;
}

.commonTable.documentTable tbody tr td {
  @apply text-mirage;
}
.commonTable tbody tr {
  @apply border-b;
}
:global(body.dark) .commonTable tbody tr {
  @apply border-thunders;
}
.commonTable tbody tr:hover {
  @apply bg-antiflashwhite;
}
:global(body.dark) .commonTable thead tr {
  @apply bg-balticsea;
}

.commonTable th {
  @apply whitespace-nowrap text-sm font-semibold maxMd:pr-4;
}
.tableSingleHeading th:first-child {
  @apply !pl-5;
}
.tableSingleTitle {
  @apply !max-w-full;
}

.disableFavorite ul > div:first-child,
.disableFavoriteMobile {
  @apply pointer-events-none opacity-50;
}

.commonTable tr th:first-child {
  @apply w-12;
}

/* css for suggestion history on search bar */
.suggestionBoxMainDiv {
  @apply absolute right-0 top-8 z-10 w-full py-2;
}

.suggestionBoxSubDiv {
  @apply mt-2 w-full rounded-md bg-white py-2 shadow-md;
}

:global(body.dark) .suggestionBoxSubDiv {
  @apply bg-mirage;
}

.historyText {
  @apply pb-2 pl-4 text-xs font-semibold text-gray-700;
}

:global(body.dark) .historyText {
  @apply text-white;
}

.suggestionBoxItemList {
  @apply flex max-h-32 w-full flex-col overflow-y-auto;
}

.suggestionBoxItemList li {
  @apply flex w-full cursor-pointer flex-row items-center justify-between bg-white px-4 py-2 text-sm font-normal text-gray-500 hover:bg-antiflashwhite;
}

:global(body.dark) .suggestionBoxItemList li {
  @apply bg-mirage text-greychateau hover:bg-darkjunglegreenss;
}

.suggestionBoxItemList li span {
  @apply flex w-[85%] break-all;
}

:global(.table-not-selectable tbody tr td:first-child:first-of-type),
:global(.table-not-selectable thead tr th:first-child:first-of-type) {
  @apply !pl-3;
}
