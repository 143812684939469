* {
  box-sizing: border-box;
}
::-webkit-scrollbar-track {
  @apply bg-brightgray dark:!bg-thunders;
}
::-webkit-scrollbar {
  @apply h-1 w-1 bg-brightgray dark:!bg-thunders md:h-2.5;
}
::-webkit-scrollbar-thumb {
  @apply bg-silver dark:!bg-iridium;
}
@screen maxMd {
  ::-webkit-scrollbar {
    @apply h-1 w-1;
  }
}

.dark::-webkit-scrollbar-track {
  @apply bg-thunders;
}
.dark::-webkit-scrollbar {
  @apply bg-thunders;
}
.dark::-webkit-scrollbar-thumb {
  @apply bg-iridium;
}
body {
  @apply overflow-x-hidden font-inter-regular font-normal text-mirage maxMd:overflow-hidden;
}
body.dark {
  @apply bg-darkjunglegreens;
}

.pb18 {
  padding-bottom: 1.125rem !important;
}

.ml90 {
  margin-left: 5.625rem;
}
.main-content {
  width: calc(100% - 15.625rem);
  height: calc(100vh - 4rem);
  margin-left: 15.625rem;
  @apply fixed right-0 top-16 overflow-x-hidden maxMd:ml-0 maxMd:w-full;
}
.custom_grid {
  height: calc(100vh - 182px);
  overflow-y: auto;
}

@screen maxMd {
  .custom_grid {
    height: calc(100% - 260px);
  }
}

.main-content-toggle {
  margin-left: 3.75rem;
  @apply maxMd:ml-0;
}

.title-lg {
  @apply font-sans-medium text-3xl;
}

.title-md {
  @apply font-inter-semibold text-2xl;
}

.title-sm {
  @apply font-inter-medium text-sm;
}

.auth-container {
  height: calc(100vh - 48px);
  @apply flex items-center justify-center maxMd:mt-6;
}

.card {
  @apply m-auto bg-white shadow-commonShadow dark:bg-darkjunglegreenss;
}

.card-auth {
  padding-bottom: 5.375rem;
  width: 42.188rem;
  @apply mx-3 p-16;
}

@screen maxMd {
  .card.card-auth {
    @apply rounded-lg p-4;
  }
}
.main-bgcolor {
  @apply bg-ghostwhite dark:bg-mirage;
}
.main-table-bg {
  @apply dark:bg-darkjunglegreenss;
}
.main-content.main-risk-bgcolor {
  @apply bg-ghostwhite dark:bg-mirage;
}
.policy-bg {
  @apply bg-white dark:bg-darkjunglegreenss;
}
.toaster {
  @apply absolute -right-96 top-5 mr-4;
  z-index: 100;
}
.custom_dropdown {
  @apply dark:!bg-thunders;
}
.custom_dropdown li:hover {
  @apply dark:!bg-iridium;
}

.select-control input[type="text"]:focus,
input[type="email"],
input[type="password"] {
  box-shadow: none !important;
}

textarea::placeholder {
  @apply text-sm;
}
.error {
  @apply !border-red-500 !bg-red-50 !text-red-900;
}
.is-error input {
  @apply border-lavared bg-ghostwhite text-mirage dark:!border-lavared dark:!bg-balticsea;
}
.form-input {
  @apply relative;
}
.form-input button,
.form-input .check {
  @apply absolute right-0 top-0;
}

.drag-card {
  opacity: 1 !important;
  --tw-rotate: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}

.spotlight-modal button[aria-label="Close"] {
  @apply md:hidden;
}
[aria-label="Close"] svg path {
  @apply dark:stroke-greychateau;
}

.spotlight-modal h3 {
  @apply w-full;
}

.spotlight-modal h3 input {
  @apply cursor-text outline-none;
}
@screen md {
  .spotlight-content {
    @apply overflow-y-auto;
    height: 26.875rem;
  }
}

.spotlight-content::-webkit-scrollbar {
  width: 0.25rem;
}

.spotlight-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.spotlight-content ul li:hover {
  @apply bg-antiflashwhite dark:bg-balticsea;
}

.spotlight-content ul li:hover img {
  @apply visible;
}
@screen maxMd {
  .search_body {
    height: calc(100vh - 332px);
    @apply overflow-auto;
  }
}
@screen maxMd {
  .no-searchfound {
    height: calc(100vh - 332px) !important;
  }
}
.spinner-center > div {
  @apply fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2;
}

.spotlight-footer li {
  @apply font-inter-medium text-xs text-aurometalsaurus dark:text-stormdust;
  margin-right: 1.375rem;
}

.spotlight-footer li button {
  @apply flex items-center;
}

.spotlight-footer li img {
  @apply mr-1;
}

canvas {
  @apply mx-auto;
}
.form-control {
  @apply block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 shadow-sm !ring-opacity-0 dark:border-iridium dark:bg-balticsea dark:text-greychateau dark:placeholder-stormdust sm:text-sm mac13Inch:px-3 mac13Inch:py-2 !important;
}

.textarea-form-control {
  @apply block w-full rounded-lg border border-gray-200 bg-gray-50 text-gray-900 focus:border-mirage focus:ring-mirage dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-stormdust sm:text-sm;
}

.btn_primary {
  @apply rounded-full !bg-mirage px-5 py-2.5 text-sm !text-white hover:!bg-thunders dark:!bg-white dark:!text-mirage dark:hover:!bg-greygoose;
}
@screen mac13Inch {
  .btn_primary {
    @apply text-xs;
  }
}
.nav_active {
  @apply !font-inter-medium !text-mirage dark:!text-white md:bg-gray-100 md:dark:bg-balticsea;
}
.nav_active:hover span {
  @apply dark:!text-mirage;
}
.nav_active svg path {
  @apply !fill-mirage dark:!fill-white;
}

.nav_active span {
  @apply !font-inter-medium !text-mirage dark:!text-white;
}

.header_link_active {
  @apply !text-mirage dark:!text-white;
}

.btn_secondary {
  @apply rounded-full border border-mirage bg-white px-5 py-2.5  text-sm text-mirage hover:bg-gray-100 dark:!border-white dark:bg-darkjunglegreenss dark:text-white;
}
@screen mac13Inch {
  .btn_secondary {
    @apply text-xs;
  }
}

.css-26l3qy-menu {
  @apply !relative !shadow-none;
}

.tag {
  @apply flex items-center rounded-md px-3 py-1.5 font-inter-medium text-xs;
}

.tag-ready svg path {
  @apply fill-greenhaze !stroke-transparent;
}

.ContentEditable__root {
  @apply text-greychateau;
}
.multitag__input:focus {
  @apply !ring-opacity-0 dark:!ring-opacity-0;
}

.action {
  @apply border-l border-r border-brightgray;
  width: 33.563rem;
}

.mceContentBody {
  background: red;
  color: #000;
}

.right-tabs {
  @apply border-brightgray dark:border-thunders md:max-w-[26.25rem] md:border-l-2 md:border-t maxMd:mt-[46px];
}
.right-tabs [role="tabpanel"] {
  @apply h-full pr-0 pt-0 md:w-[387px];
}

.right-tabs div[role="tablist"] {
  min-width: 60px;
  @apply border-b-2 border-[#E5E7EB] dark:border-thunders md:order-1 md:min-h-[100vh] md:!flex-col md:border-b-0 md:border-l-2;
}

@screen maxMd {
  .right-tabs div[role="tablist"] {
    @apply absolute left-0 right-0 top-[57px] z-[1];
  }
}

.right-tabs div[role="tablist"] button {
  @apply flex
  items-center justify-center border-0 px-0 outline-none hover:bg-antiflashwhite md:h-[45px] md:w-[58px];
}
.right-tabs div[role="tablist"] button img {
  @apply h-6 w-6;
}

.right-tabs div[role="tablist"] button .tooltip {
  left: inherit !important;
  right: 82px;
  @apply bg-brightgrey text-lightSilver dark:bg-thunders maxMd:right-4;
}

.right-tabs div[role="tablist"] button[aria-selected="true"] {
  @apply !border-0 bg-antiflashwhite dark:bg-mirage;
}

.right-tabs div[role="tablist"] button[aria-selected="true"] svg path {
  @apply stroke-mirage dark:stroke-white;
}

.tags-custom .css-1rhbuit-multiValue {
  @apply rounded-md bg-antiflashwhite;
}

.tags-custom .css-xb97g8:hover {
  @apply bg-transparent;
}

.tags-custom .css-12jo7m5 {
  @apply pl-3 font-bold text-mirage;
}

.tags-custom .css-1s2u09g-control {
  @apply rounded-lg border-lightSilver bg-ghostwhite;
}
.tags-custom input {
  z-index: -1;
}
.tags-custom > div {
  @apply dark:border-iridium dark:bg-balticsea;
}

.arrow-toggle svg {
  transform: rotate(180deg);
  @apply !mr-0;
}
.metadata-arrow {
  @apply ml-2.5 w-6;
}
.metadata-arrow img {
  @apply max-w-[24px] hover:opacity-100 mac13Inch:max-w-[16px];
}

.h-modal {
  @apply !h-screen !overflow-hidden;
}

.select-control.form-sotrol {
  height: auto !important;
}

.group_select_control > .select-control.form-sotrol > div {
  @apply !max-w-full;
}

.select-control.form-sotrol > div {
  max-width: 340px !important;
  width: 100% !important;
  @apply dark:border-thunders dark:bg-balticsea dark:ring-0;
}
.custom_settings {
  @apply w-full;
}

.custom_settings > span {
  @apply !flex items-center;
}
.custom_settings {
  @apply dark:hover:!bg-balticsea;
}

.custom_settings span svg {
  @apply inline-block md:mr-2;
}
.custom_settings > span svg rect {
  @apply dark:fill-iridium;
}
.active span svg path {
  @apply stroke-mirage dark:stroke-white;
}

.active span span {
  @apply font-inter-medium text-mirage dark:text-white;
}
@screen md {
  .pagination-btn button span {
    @apply hidden;
  }
}
.pagination-btn button {
  @apply cursor-pointer;
}

@screen maxMd {
  .pagination-btn {
    @apply sticky bottom-0 left-0 right-0 z-10 w-full justify-start bg-white px-6 dark:bg-darkjunglegreens;
  }
  .pagination-btn {
    @apply py-4;
  }

  .pagination-btn button {
    @apply btn_primary flex h-[40px] min-w-[130px] items-center justify-center;
  }

  .pagination-btn button:first-child {
    @apply mr-[10px];
  }

  .pagination-btn button:nth-child(2) img {
    filter: invert(1) brightness(100);
  }

  .pagination-btn button img {
    max-width: 28px;
  }
}

@screen maxMd {
  .main-wrap {
    @apply overflow-y-auto overflow-x-hidden;
  }
}

.custom_settingBar > div {
  @apply maxMd:w-full;
}

.loader {
  transform: translate(-50%, -50%);
  z-index: 111;
  @apply fixed left-1/2 top-1/2 flex h-screen w-full flex-col items-center justify-center;
}

.loader::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  @apply pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full;
}
.download-icon {
  @apply relative z-[1];
}
input[type="checkbox"] {
  @apply focus:ring-white;
}
@screen mac13Inch {
  input[type="checkbox"],
  input[type="radio"] {
    padding: initial !important;
  }
}
input[type="checkbox"] {
  @apply border border-lightSilver !bg-ghostwhite;
}
input[type="checkbox"] {
  @apply dark:border-boulder dark:!bg-darkjunglegreenss dark:focus:ring-0 dark:focus:ring-offset-0;
}

input[type="checkbox"]:checked {
  @apply !border-mirage !bg-mirage ring-offset-0 focus:ring-0 focus:ring-transparent dark:relative dark:!bg-white dark:!ring-offset-0 dark:focus:!ring-transparent dark:focus:ring-boulder;
}
.dark input[type="checkbox"]:checked::after {
  content: "";
  background: url("../images/checkmark.svg") no-repeat;
  top: 4px;
  left: 2.2px;
  @apply absolute h-[10px] w-[10px];
}

input[type="checkbox"]:checked:focus {
  @apply !border-mirage;
}

input[type="checkbox"]:focus {
  box-shadow: none;
  @apply !border-lightSilver dark:!border-liver;
}

input[type="checkbox"]:not(:checked).custom-checkbox {
  @apply !bg-ghostwhite;
}
.sidebar_setting > div {
  @apply w-full !rounded-tl-lg bg-white dark:bg-darkjunglegreenss;
}
.sidebar_setting > div::-webkit-scrollbar {
  @apply h-1 w-1;
}
input:focus,
textarea:focus,
select:focus {
  @apply !border-mirage !outline-none !ring-mirage !ring-opacity-0;
}
.column-selection {
  @apply flex items-center maxMd:!mt-1 maxLg:mt-4;
}
.column-selection__toggle span {
  @apply !p-0;
}
button.column-selection__toggle {
  @apply h-6 w-6 justify-center border-0 focus:!ring-0;
}
@screen mac13Inch {
  button.column-selection__toggle {
    @apply h-4 w-4;
  }
}
.column-selection button {
  @apply p-0 text-left focus:outline-none focus:ring-0;
}
.column-selection button:hover {
  @apply !bg-transparent;
}

.column-selection div[aria-describedby=":rq:"] button {
  @apply bg-antiflashwhite;
}
.column-selection ul li label {
  @apply flex h-[36px] w-full cursor-pointer items-center px-4 text-gray-700;
}

.custom_settings span {
  @apply pl-0 mac13Inch:text-sm;
}
.Toastify__toast-body div:last-child {
  @apply text-mirage;
}
.Toastify__toast-icon svg {
  @apply fill-greenhaze;
}
.Toastify__progress-bar--success {
  @apply !bg-greenhaze;
}
.Toastify__toast--warning .Toastify__toast-icon svg {
  @apply h-4 w-4 fill-valentinered;
}
.Toastify__toast--warning .toast_v2_body {
  @apply text-sm;
}
.Toastify__progress-bar--warning {
  @apply !bg-valentinered;
}
.actions {
  padding-left: 0.375rem;
}

.metadata-tabs {
  @apply h-full overflow-x-hidden px-4 maxMd:pb-4;
}
.metadata-tabs::-webkit-scrollbar {
  @apply w-1;
}
.metadata-tabs__header {
  font-family: "InterSemiBold" !important;
  @apply pt-[25px] !text-lg md:mb-[30px] maxMd:pt-4 mac13Inch:mb-3 mac13Inch:pt-4;
}
@screen mac13Inch {
  .metadata-tabs__header {
    @apply !text-base;
  }
}

.select-member .form-sotrol {
  width: calc(100% - 150px);
  @apply maxMd:w-full;
}

.select-member .form-sotrol > div {
  @apply border-brightgray bg-ghostwhite dark:bg-balticsea maxMd:!max-w-full;
}

.select-member .css-1n6sfyn-MenuList,
.select-member .css-1nmdiq5-menu {
  @apply rounded bg-white dark:bg-balticsea;
}
.dark .select-member > div svg {
  @apply fill-boulder;
}
.custom_choose_file > div {
  @apply max-w-xl;
}
.search_text h3 {
  @apply text-base;
}

.main-content-toggle.main-content {
  width: calc(100% - 3.75rem);
}

input[type="checkbox"]:focus {
  @apply !border-lightSilver;
}

/* table CSS */

.data_table thead {
  @apply bg-transparent;
}

.data_table tr {
  @apply border-b border-brightgray dark:border-thunders dark:!bg-darkjunglegreenss;
}

.data_table th {
  @apply py-4 text-sm font-bold text-mirage;
}
.data_table td {
  @apply text-aurometalsaurus dark:text-white;
}

.tabError {
  @apply relative flex h-4 w-4 items-center justify-center rounded-full bg-lavared p-1;
}

.tabError span {
  @apply text-[10px] font-bold text-white;
}

/* module details container CSS */
.details_container {
  @apply relative w-full overflow-y-auto pt-4;
}
.overflow-template {
  overflow-y: inherit;
}
/* tabs */
.tabs_details_container {
  @apply relative mt-4 w-full md:overflow-auto;
}
.tabs_details {
  @apply relative border-b-0 dark:!border-thunders maxMd:w-full;
}

@screen maxMd {
  .tabs_details_container {
    margin-top: 20px;
  }
}
.tabs_details_container::-webkit-scrollbar {
  width: 0.25rem;
}

.details_sub_container {
  @apply w-full px-4 pt-3  md:px-5;
}

.details_heading {
  @apply mb-3 font-inter-medium text-lg font-bold capitalize dark:text-white md:mb-6 mac13Inch:text-base;
}

.details_label {
  @apply break-words text-sm text-aurometalsaurus dark:text-boulder;
  flex-direction: inherit;
}
@screen maxMd {
  .tabs_details > button:last-child {
    @apply border-b;
  }
  .right-tabs > div > div {
    flex-direction: inherit;
  }
}
/* calendar */
.event_title {
  width: calc(100% - 106px);
}
.fc-dayGridWeek-view .fc-daygrid-day-events,
.fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  @apply !mt-2.5;
}
.fc-today-button.fc-button.fc-button-primary {
  @apply !mx-2;
}
.fc-header-toolbar {
  @apply px-4 pt-4 md:pt-5;
}
.fc-today-button.fc-button.fc-button-primary {
  @apply !border-transparent !bg-transparent capitalize !text-darkjunglegreens;
}
.fc-prev-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary {
  @apply !bg-transparent !p-0 text-darkjunglegreens;
}

.fc-prev-button.fc-button.fc-button-primary:hover,
.fc-next-button.fc-button.fc-button-primary:hover {
  @apply bg-transparent text-darkjunglegreens;
}
.fc .fc-button {
  @apply !border-none !text-sm;
}
.fc .fc-toolbar {
  @apply !justify-start md:h-[45px] maxMd:flex-col;
}
.fc .fc-toolbar .fc-toolbar-chunk:first-child {
  @apply mr-auto;
}
.fc-toolbar-chunk:last-child {
  @apply ml-auto md:mr-3;
}
.fc .fc-toolbar-title {
  @apply !ml-3 !text-sm;
}
.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
  @apply mac13Inch:text-sm;
}
.fc-h-event .fc-event-title {
  @apply mac13Inch:text-xs;
}
.fc .fc-button:focus {
  @apply !shadow-none;
}
.fc .fc-button-primary {
  @apply !bg-white !capitalize !text-darkjunglegreens;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  border-bottom: 1px solid #1d1d1f !important;
}
.fc .fc-button {
  @apply !rounded-none;
}
.fc-prev-button.fc-button.fc-button-primary:active,
.fc-next-button.fc-button.fc-button-primary:active,
.fc-today-button.fc-button.fc-button-primary:active {
  @apply !border-none;
}
.fc-theme-standard .fc-scrollgrid {
  @apply !border-l-0;
}
.fc-next-button {
  @apply !ml-0;
}

/* header buttons CSS */
.custom-checkbox input[type="checkbox"] {
  @apply border-red-500 !bg-red-50;
}
.custom-checkbox input[type="checkbox"]:checked {
  @apply !border-mirage !bg-mirage;
}
.multitag_spacing__multi-value__label {
  @apply !px-1.5 !py-1;
}
.multitag_spacing__menu {
  @apply !z-[11];
}
@screen maxMd {
  .multitag_spacing__multi-value__label {
    @apply !whitespace-normal break-words;
  }
}
.multitag__multi-value__label {
  @apply !p-0;
}
.mark_row_unread td {
  @apply !bg-white text-mirage;
}
.main-content .mark_row_unread td {
  @apply dark:!bg-darkjunglegreens dark:!text-white;
}

.mark_row_read td {
  @apply !bg-ghostwhite !text-[#6B7280];
}
.main-content-full .mark_row_read td {
  @apply dark:!bg-darkjunglegreenss dark:!text-greychateau;
}
.multitag__multi-value__label {
  padding: 0px !important;
}
.multitag__multi-value svg {
  @apply text-santagrey dark:text-greychateau;
}
.multitag__multi-value {
  @apply !bg-brightgray dark:!bg-iridium;
}
.modal-header button {
  @apply text-gray-400;
}
.modal-header h3 {
  @apply font-sans-medium text-lg text-mirage mac13Inch:text-base;
}

.details_table tr {
  @apply border-b border-brightgray dark:border-thunders dark:bg-darkjunglegreenss;
}

.details_table th {
  @apply bg-white py-4 font-inter-medium text-sm font-medium text-mirage;
}
.details_table td {
  @apply text-aurometalsaurus dark:text-white;
}

.details_table > div {
  @apply rounded-none shadow-none;
}

thead tr th {
  @apply !rounded-tl-none !rounded-tr-none font-inter-medium font-medium dark:!bg-darkjunglegreenss dark:text-white;
}
tbody tr {
  @apply dark:border-thunders dark:bg-darkjunglegreenss dark:hover:!bg-balticsea;
}
tbody tr td {
  @apply !rounded-bl-none !rounded-br-none dark:text-greychateau maxMd:px-4;
}
.custom_list li:last-child button p {
  @apply font-medium text-mirage dark:border-white dark:text-white;
}
div:has(> .setting_modal) {
  @apply dark:bg-darkjunglegreenss;
}

input.border-red-500,
select.border-red-500,
textarea.border-red-500 {
  @apply dark:!border-lavared;
}

input,
textarea,
select {
  @apply focus:!shadow-none focus:!ring-0 dark:!border-iridium dark:!bg-balticsea dark:!text-greychateau dark:!placeholder-stormdust dark:placeholder:text-stormdust dark:focus:!ring-iridium mac13Inch:!px-3 mac13Inch:!py-2;
}

label {
  @apply text-sm font-medium text-mirage dark:text-white;
}

.custom-datepicker .react-datepicker__day {
  @apply !inline-flex !rounded-lg;
}
.sidebar_cart > div {
  @apply xl:mb-4 maxMd:mb-4;
}

.dashboard_table:nth-child(even) {
  @apply dark:!bg-balticsea;
}

.spotlight-modal {
  @apply !h-full;
}
.custom_datePicker .react-datepicker {
  @apply absolute z-10;
}
.preview_centerText {
  @apply absolute left-1/2 top-1/2;
  transform: translate(-50%, -50%);
}

.height-full {
  height: calc(100vh - 4rem);
}

.details_table div table thead {
  @apply capitalize dark:!bg-red-300;
}

input[type="radio"] {
  @apply dark:border-solid dark:!border-iridium;
}
.column-selection ul {
  @apply dark:border-iridium dark:!bg-thunders;
}

.dark .column-selection ul {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.custom_tooltip {
  @apply !bg-mirage dark:!bg-thunders;
}
.custom_tooltip div:last-child {
  @apply bg-mirage dark:bg-thunders;
}
.custom_avtar div div {
  @apply dark:bg-liver;
}
.shadow_content h2 {
  @apply text-red-400;
}
.custom_datePicker .react-datepicker {
  @apply absolute z-10;
}

#filter-datepicker .react-datepicker-popper {
  @apply !fixed;
  width: 300px !important;
  inset: 0 !important;
}

#filter-datepicker .react-datepicker-popper .react-datepicker__week {
  width: 286px !important;
}

#filter-datepicker .react-datepicker-popper .react-datepicker__day-names {
  width: 286px !important;
  gap: 0 !important;
}

#filter-datepicker .react-datepicker-popper .react-datepicker__current-month {
  padding: 10px !important;
}

#filter-datepicker .react-datepicker-popper .react-datepicker__navigation {
  @apply hover:!bg-transparent;
  width: 10px !important;
  top: 12px;
}

#filter-datepicker .react-datepicker-popper .btn_primary,
#filter-datepicker .react-datepicker-popper .btn_secondary {
  @apply !block !w-2/5;
}

#filter-datepicker .react-datepicker-popper .btn_primary {
  @apply mb-2.5 ml-2 !text-white dark:!bg-white dark:!text-mirage;
  height: 38px !important;
}
#filter-datepicker .react-datepicker-popper .btn_primary:hover {
  @apply !bg-mirage !text-white;
}

:global(body.dark) #filter-datepicker .react-datepicker-popper .btn_primary {
  @apply !bg-white hover:!text-white;
}
.table-heading,
.agreement-detail-label {
  @apply w-2/5;
}
.agreement-detail-label {
  @apply maxMd:w-full;
}

.agreement-detail-label img {
  @apply ml-[10px] max-w-[18px];
}

.general_modal > div {
  @apply h-auto !important;
}
.fc-today-button.fc-button.fc-button-primary {
  @apply dark:!text-mountainmist md:px-0;
}
.fc-icon-chevron-left:before,
.fc-icon-chevron-right:before,
.fc-toolbar-title {
  @apply dark:text-mountainmist;
}
@screen maxMd {
  .fc-dayGridMonth-view .fc-daygrid-event-harness,
  .fc-dayGridWeek-view .fc-daygrid-event-harness {
    @apply m-auto h-2 !min-h-[8px] w-2 overflow-hidden rounded-full bg-dodgerblue;
  }

  .fc-dayGridMonth-view .fc-daygrid-day-events,
  .fc-dayGridWeek-view .fc-daygrid-day-events {
    @apply mx-auto mb-2.5 h-2 !min-h-[8px] w-2 overflow-hidden;
  }

  .fc-listWeek-view.fc-list.fc-list-sticky,
  .fc-dayGridDay-view.fc-daygrid {
    height: calc(100svh - 179px) !important;
  }

  .common_movbile_view ul {
    height: calc(100svh - 556px);
    @apply relative overflow-y-auto px-4 py-3;
  }
}

.fc-Task-button.fc-button.fc-button-primary {
  @apply !bg-transparent;
}

.fc .fc-button-primary {
  @apply dark:!bg-transparent dark:!text-greychateau;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  @apply dark:!border-mountainmist dark:!text-white;
}
.fc-theme-standard .fc-scrollgrid {
  @apply dark:!border-thunders;
}
.fc-theme-standard th {
  @apply !pl-0;
}
.fc-theme-standard td,
.fc-theme-standard th {
  @apply dark:!border-thunders;
}
.fc-col-header-cell-cushion {
  @apply dark:!text-brightgray;
}

.fc-daygrid-day-number {
  @apply dark:text-white;
}
.sr-only {
  @apply !invisible;
}

.fc-toolbar-chunk .fc-Task-button,
.fc-toolbar-chunk .fc-Calendar-button {
  @apply absolute  mac13Inch:!p-[0.35rem];
  top: 1.35rem;
}

.fc-toolbar-chunk .fc-Task-button::before,
.fc-toolbar-chunk .fc-Calendar-button::before,
.dark .fc-toolbar-chunk .fc-Task-button::before,
.dark .fc-toolbar-chunk .fc-Calendar-button::before {
  @apply absolute;
  content: "";
  height: 1.25rem;
  width: 1.2rem;
  left: -1.1rem;
  top: 0.3rem;
  background-repeat: no-repeat;
  background-position: center;
}
.fc-toolbar-chunk .fc-Calendar-button::before {
  background-image: url("../images/calendar/calendar-icon.svg");
}
.dark .fc-toolbar-chunk .fc-Calendar-button::before {
  background-image: url("../images/calendar/calendar_today.svg") !important;
}
.fc-toolbar-chunk .fc-Task-button::before {
  background-image: url("../images/calendar/description-icon.svg");
}
.dark .fc-toolbar-chunk .fc-Task-button::before {
  background-image: url("../images/darkmode/description.svg");
}

.fc-toolbar-chunk .fc-Task-button {
  @apply left-[42%];
}
@media (min-width: 1600px) {
  .fc-toolbar-chunk .fc-Task-button {
    @apply left-[44%];
  }
}
.fc-toolbar-chunk .fc-button-primary.fc-Calendar-button,
.fc-toolbar-chunk .fc-button-primary.fc-today-button {
  left: 48.5%;
  @apply !text-mirage !opacity-100;
}
.fc-Calendar-button.fc-button.fc-button-primary {
  @apply dark:!text-white;
}

.fc-toolbar-chunk .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  @apply !border-none;
}
.droppable-col {
  width: 100%;
  background-color: gray;
  padding: 10px 10px 0 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}

.item {
  margin-bottom: 10px;
  background-color: gray;
  color: white;
  border: 1px solid white;
  padding: 5px;
  border-radius: 7px;
}

.item.dragging {
  background-color: coral;
}

.setting-modal-height-full {
  height: calc(100vh - 73px);
}

@screen maxMd {
  .setting-modal-height-full {
    @apply h-full;
  }
  .seeting-mobile {
    @apply h-full;
  }
}
.css-12a83d4-MultiValueRemove svg {
  @apply dark:text-greychateau;
}
.save_current_search {
  @apply !p-0;
}
.save_current_search button {
  @apply px-4 py-2;
}

.css-qbdosj-Input,
.css-19bb58m,
.multitag__input-container,
.multitag_spacing__input,
.css-mohuvp-dummyInput-DummyInput {
  @apply mac13Inch:!flex mac13Inch:h-[25px];
}
.multitag_spacing__input {
  @apply dark:!bg-transparent;
}
input.multiTaskTag {
  @apply !p-0;
}
.multiTaskTag {
  @apply dark:text-white;
}
.metadata-tabs .select-member .css-kn3f2l-control {
  @apply mac13Inch:!min-h-[auto];
}

.css-qbdosj-Input input {
  @apply dark:!bg-transparent;
}
.css-1dimb5e-singleValue {
  @apply dark:!text-white;
}

.css-bs8tb2-MultiValueGeneric div {
  @apply dark:text-white;
}
.css-1hqwh8q-MultiValueGeneric div svg {
  @apply !fill-lightSilver dark:fill-boulder;
}
.css-1fdsijx-ValueContainer {
  padding-left: 20px;
}
.css-qbdosj-Input input:focus {
  @apply !ring-0;
}
.css-1xa3fb9-control {
  @apply border border-lightSilver bg-ghostwhite dark:border-iridium dark:bg-balticsea;
}
.custom_select select {
  @apply dark:border-iridium dark:focus:!border-iridium;
}
.custom_settings span svg path {
  @apply dark:stroke-greychateau;
}
.fc-Task-button.fc-button.fc-button-primary,
.fc-Calendar-button.fc-button.fc-button-primary {
  @apply text-aurometalsaurus;
}

.icon_changes button:first-child {
  @apply dark:text-greychateau;
}
.icon_changes svg path {
  @apply dark:fill-white;
}
.icon_changes button {
  @apply dark:text-white;
}
.icon_changes button:first-child svg path {
  @apply dark:fill-greychateau;
}
.custom_dropdown ul li:last-child div p {
  @apply !text-lavared;
}
.custom_dropdown div:last-child {
  max-width: 100%;
  width: 200px;
}
.country_select,
.industry_select {
  @apply mr-1.5;
}

.country_select path,
.industry_select path {
  @apply fill-aurometalsaurus dark:fill-greychateau;
}
/* end  */

.policy_heading {
  @apply font-inter-medium text-lg text-mirage;
}
.compare_modal > div {
  max-width: 1248px;
  @apply md:h-full;
}
.compare_dropdown {
  @apply mb-4 font-inter-semibold text-aurometalsaurus dark:text-white;
}
.compare_modal .PlaygroundEditorTheme__h1 {
  @apply pl-3;
}
.compare_modal .ContentEditable__root {
  @apply pl-3;
}
@screen md {
  .compare_modal > div > div {
    max-height: 100vh;
    height: 100%;
  }
}

.custom_dropdown svg {
  @apply !rotate-0;
}

.radioButtons {
  @apply cursor-pointer border-none !bg-gray-100 !text-mirage !ring-mirage focus:!ring-transparent dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-0;
}
:global(body.dark) .radioButtonContainer input[type="radio"].radioButtons:checked {
  background-image: url("../images/checkmark.svg") !important;
  @apply !bg-white !ring-offset-transparent focus:!ring-0 focus:!ring-offset-0;
  box-shadow: none !important;
}
.radioButtons:checked {
  @apply !bg-darkjunglegreens focus:!ring-offset-0;
  background-image: url("../images/agreements/check.svg") !important;
  background-size: auto !important;
}
.intercom-lightweight-app-launcher {
  @apply dark:!bg-white;
}
.intercom-lightweight-app-launcher-icon-open svg path {
  @apply dark:fill-darkjunglegreens;
}
/* svg icon color */
.themesvg_color path {
  @apply fill-aurometalsaurus dark:fill-boulder;
}

.grid_clock_icon path {
  @apply !fill-aurometalsaurus dark:!fill-greychateau;
}

.threedots_icon path {
  @apply !fill-aurometalsaurus !stroke-none dark:!fill-greychateau;
}

.calendar_active .fc-toolbar-chunk .fc-Calendar-button::before {
  background-image: url("../images/calendar/calendar-active.svg");
}
.task_active .fc-toolbar-chunk .fc-Task-button::before {
  background-image: url("../images/calendar/description-active.svg");
}

.task_active .fc-toolbar-chunk .fc-button-primary.fc-Task-button,
.fc-toolbar-chunk .fc-button-primary,
.calendar_active .fc-toolbar-chunk .fc-button-primary.fc-Calendar-button,
.fc-toolbar-chunk .fc-button-primary {
  @apply !font-medium text-mirage;
}
.calenderViewHidden > div div:last-child {
  @apply hidden;
}
.question_icon path {
  @apply stroke-aurometalsaurus dark:stroke-boulder;
}
.main_dashboard {
  @apply bg-ghostwhite dark:bg-darkjunglegreens;
}
.comman_table > div {
  box-shadow: none;
  @apply maxMd:overflow-auto;
}

.tabs_details [role="tabpanel"] {
  @apply px-0;
}
.comman_table thead {
  @apply bg-white capitalize;
}
.comman_table thead {
  @apply border-y border-brightgray dark:border-thunders;
}

.overflow-inherit {
  overflow: inherit;
}

.btn-danger span {
  @apply mac13Inch:text-xs;
}

.font-xs-mac13 span {
  @apply mac13Inch:text-xs;
}
.header-font-xs-mac13 h3 {
  @apply mac13Inch:text-base;
}

.apexcharts-text tspan {
  @apply mac13Inch:text-xs;
}

@screen mac13Inch {
  select {
    @apply !px-3 !py-2;
  }
  .css-1xc3v61-indicatorContainer {
    @apply !p-[6px];
  }
}
.comman_table thead th {
  @apply py-4 text-sm font-bold text-mirage;
}
.si.stroke.upload_icon path {
  @apply !stroke-mirage dark:!stroke-white;
}
.seeting_icon path {
  @apply dark:stroke-greychateau;
}

.clock_icon path {
  @apply stroke-aurometalsaurus dark:stroke-greychateau;
}

.add_icon path {
  @apply dark:fill-aurometalsaurus;
}

.editer_border .ContentEditable__root {
  @apply border border-lightSilver dark:border-thunders;
}
.edit_policies .ContentEditable__root,
.edit_note .ContentEditable__root {
  @apply px-2 md:px-8;
}
.edit_risk .ContentEditable__root {
  @apply pl-28;
}
.edit_risk .Placeholder__root {
  @apply left-28;
}
.edit_risk .draggable-block-menu {
  @apply !left-0;
}
.edit_note .ContentEditable__root {
  @apply maxMd:pl-8 maxMd:pr-0;
}
.fc-theme-standard .fc-list {
  @apply !border-l-transparent dark:border-thunders;
}
.calendar_active .fc-list-day-cushion {
  @apply dark:!bg-thunders dark:!text-white;
}
.fc-list-event-time {
  @apply dark:text-white;
}
.fc-list-event-title a {
  @apply dark:!text-white;
}
.fc .fc-list-event:hover td {
  @apply dark:bg-balticsea;
}
.download_icon path {
  @apply stroke-white dark:stroke-darkjunglegreens;
}
.spotlight-content_item svg:last-child {
  visibility: hidden;
}

.spotlight-content_item:hover svg:last-child {
  visibility: visible;
}

.grand-assistant-modal > div:first-child {
  @apply max-w-[864px];
}

.grand-assistant-modal h3 {
  @apply w-full font-sans-medium text-lg;
}
.search-icon-dark input {
  @apply cursor-text border-transparent bg-transparent pl-10 text-sm placeholder:text-aurometalsaurus focus:!border-transparent dark:border-l-0 dark:border-r-0 dark:!border-thunders dark:!bg-darkjunglegreenss dark:placeholder:!text-greychateau md:!pl-12;
}

.search-icon-dark svg path {
  @apply dark:stroke-greychateau;
}
.link-icon-dark path {
  @apply dark:fill-white;
}

.grand-assistant-modal form svg {
  @apply ml-1.5 h-[15px] w-[15px] md:ml-4;
}
.grand-assistant-modal__disc {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  @apply overflow-hidden text-ellipsis;
}
.multitag_spacing__multi-value__remove,
.multitag__multi-value__remove {
  @apply hover:!bg-transparent;
}
.multitag_spacing__multi-value__remove:hover svg path,
.multitag__multi-value__remove:hover svg path {
  @apply fill-mirage dark:fill-white;
}
.multitag_spacing__multi-value__label {
  @apply dark:text-white dark:hover:text-white;
}
button[aria-label="Close"]:hover {
  @apply dark:bg-iridium;
}
.question_icon {
  @apply cursor-pointer;
}
.bookmark_icon path {
  @apply dark:stroke-greychateau;
}
.bookmark_icon_dark {
  @apply mr-2 md:mr-3;
}

.bookmark_icon_fill {
  @apply mr-3;
}
.bookmark_icon_fill path {
  @apply fill-aurometalsaurus !stroke-aurometalsaurus dark:fill-greychateau dark:!stroke-greychateau;
}
.Question_icon path {
  @apply dark:fill-greychateau;
}
.Question_icon path:last-child {
  @apply dark:fill-white;
}
.fc .fc-list-empty {
  @apply !bg-transparent md:!h-auto;
}
.fc-list-empty-cushion {
  @apply dark:text-white;
}
.card_text {
  width: calc(100% - 20px);
}
.icon-smd .si .fill .send_icon path {
  @apply dark:stroke-greychateau;
}

.select-control .multitag_spacing__input {
  grid-area: inherit !important;
  @apply !px-0;
  min-width: 0px !important;
  position: absolute;
  z-index: -1;
}
.provider_modal__input-container::after {
  white-space: normal !important;
  min-width: 1px !important;
}
.provider_modal__input {
  padding-left: 0px !important;
  min-width: 0px !important;
}
.authentication_dropdown div:last-child {
  @apply rounded dark:!bg-thunders;
}

.provider_modal__control {
  @apply dark:!bg-balticsea;
}
.provider_modal__multi-value {
  @apply px-1.5 py-1 dark:bg-liver;
}

.provider_modal__multi-value__label {
  @apply hover:!text-inherit dark:text-white dark:hover:!text-white;
}
.mapping_tag__multi-value__remove,
.provider_modal__multi-value__remove {
  @apply hover:!bg-transparent;
}
.provider_modal__multi-value__remove:hover svg path {
  @apply fill-mirage dark:fill-white;
}
.multitag_spacing__control {
  @apply dark:border-iridium dark:bg-balticsea;
}
.sr-only.peer.custom_checkbox {
  position: inherit;
}
.sso_select select {
  @apply dark:border-thunders dark:focus:!border-thunders;
}
.sr-only.peerinput[type="checkbox"]:checked {
  position: inherit !important;
}
.groups_switch input[type="checkbox"] {
  position: inherit;
}
.multitag_spacing__placeholder div {
  @apply dark:text-stormdust;
}
.group_mapping svg path {
  @apply dark:stroke-greychateau;
}
.multitag {
  @apply !ring-0;
}
.copy_link input {
  @apply pr-8 dark:!text-white;
}

[alt="article-image"] {
  @apply dark:text-white;
}

.header_questionIcon path:first-child {
  @apply hover:fill-mirage dark:fill-greychateau;
}
.header_questionIcon path:last-child {
  @apply dark:fill-darkjunglegreenss dark:stroke-darkjunglegreenss;
}

.provider_modal__control.css-58p7r4-control {
  @apply border-red-500;
}
.mapping_tag__multi-value__label {
  @apply !px-1.5 !py-1 dark:text-white;
}
.mapping_tag__multi-value__remove:hover svg path {
  @apply fill-mirage dark:fill-white;
}
.mapping_tag__multi-value__label {
  @apply hover:text-mirage dark:hover:!text-white;
}
.provider_modal__multi-value__label {
  @apply dark:!text-white;
}
.mapping_tag__placeholder div {
  @apply dark:text-greychateau;
}
.provider_modal__multi-value__label {
  @apply hover:!text-mirage;
}

.PlaygroundEditorTheme__textBold {
  @apply dark:text-greychateau;
}
.icon__color {
  @apply h-3 w-3;
}
.icon__color path {
  @apply stroke-santagrey;
}
.Modal__closeButton:hover .icon__color path {
  @apply stroke-black;
}
.metadata-editor-container .editor-scroller > div > div:first-child {
  min-height: 80px !important;
}
.metadata_card {
  @apply relative flex rounded border border-lightSilver p-2;
}
.closeIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @apply absolute right-2 top-2 rounded bg-mirage;
}

.closeIcon svg {
  @apply ml-0.5 h-2.5 w-2.5 cursor-pointer;
}
.closeIcon svg path {
  @apply fill-white;
}

.multitag__control input.multitag__input {
  @apply mac13Inch:!px-0;
}
.policies_content {
  @apply mx-auto w-full max-w-[1600px] px-4 md:px-[114px];
}
.policies-edit_view {
  @apply maxMd:pl-8;
}
.calendar-tabs {
  @apply relative;
}
.calendar-tabs [role="tablist"] {
  @apply absolute -top-5 left-0 right-0 mx-auto w-[200px] justify-center border-b-0 md:top-5 maxMd:gap-x-5;
}
@screen maxMd {
  .calendar-tabs [role="tablist"] {
    top: 78px;
    @apply left-4 right-full justify-start text-sm;
  }
  .fc .fc-button {
    @apply !px-0;
  }
  .fc-button-group {
    @apply gap-4;
  }
  .fc-toolbar-chunk .fc-toolbar-title {
    @apply !ml-auto;
  }
  .fc-toolbar-chunk .fc-button-group .fc-button-active {
    @apply !bg-transparent;
  }
  .fc .fc-toolbar .fc-toolbar-chunk:first-child {
    @apply w-full bg-ghostwhite p-4 dark:bg-darkjunglegreens;
  }
  .fc-header-toolbar {
    @apply !mb-0 p-0;
  }
  .fc-header-toolbar .fc-toolbar-chunk:last-child {
    @apply py-2.5 pr-4;
  }
  .events_dots {
    @apply inline-block h-2 w-2 rounded-full bg-dodgerblue;
  }
}

.calendar-tabs [role="tablist"] button {
  @apply border-0 outline-none dark:text-greychateau md:mr-10;
}
.calendar-tabs [role="tablist"] button[aria-selected="true"] {
  @apply font-inter-medium text-mirage dark:bg-transparent dark:text-white;
}
.calendar-tabs [role="tabpanel"] {
  @apply p-0;
}
.move_select {
  @apply bg-brightgray dark:bg-iridium;
}
.move_select button {
  @apply hover:bg-transparent dark:hover:bg-transparent;
}
.component-picker-menu {
  @apply bottom-0;
}
.component-picker-menu .item {
  @apply dark:border-iridium dark:bg-thunders dark:text-greychateau;
}
.component-picker-menu .item.selected {
  @apply dark:bg-iridium;
}
.typeahead-popover {
  @apply dark:bg-thunders;
}
.typeahead-popover li .icon {
  @apply dark:text-greychateau;
  filter: contrast(0);
}

hr:after {
  @apply dark:bg-thunders;
}
input[type="file"]::file-selector-button {
  @apply dark:!bg-iridium;
}

.css-1u9des2-indicatorSeparator {
  @apply dark:bg-transparent;
}
.multitag_spacing__indicator svg path {
  @apply dark:fill-boulder;
}

.dark select {
  background-image: url("../images/icons/arrow-bottom.svg") !important;
  background-position: 96% !important;
  background-size: 0.78rem !important;
}
.send_icon path {
  @apply dark:stroke-greychateau;
}
.mobile-navigation-modal > div {
  @apply h-auto;
}

/* Policy compare changes */
.authentication_content {
  width: calc(100% - 160px);
}
.long-tooltip-content {
  top: 434.5px;
  left: -498px;
  opacity: 100;
  visibility: initial;
  word-wrap: break-word;
  left: -12px;
  height: min-content;
  @apply absolute flex w-3/4 justify-center break-words;
}

.notes_content > div:first-child {
  max-width: 1600px;
  padding-left: 114px;
  padding-right: 114px;
  @apply mx-auto w-full maxMd:px-2;
}

.sub-header,
.mark_row_unread {
  @apply dark:bg-darkjunglegreenss;
}
.PlaygroundEditorTheme__h1:focus-visible {
  outline: none;
}
.notes_content .editer_border .ContentEditable__root {
  border: none;
}
.sub-header,
.mark_row_unread {
  @apply dark:bg-darkjunglegreenss;
}
.notes_content {
  overflow: inherit;
  @apply mt-2;
}
.custom_choose_file h3 {
  @apply !text-mirage;
}
[aria-label="Close"]:hover svg path {
  @apply stroke-mirage;
}
.msg_copilot span {
  @apply mb-3 inline-block rounded-lg border border-lightSilver bg-white p-3 dark:border-transparent dark:bg-thunders dark:text-white;
}
.chat_action span {
  @apply block;
}
.msg_copilot:last-child {
  @apply mb-0;
}
.command_suggest {
  @apply mb-3 flex items-center justify-end gap-x-3;
}
.command_suggest span {
  @apply rounded-lg border border-brightgray bg-white px-2 py-1 text-sm text-aurometalsaurus dark:border-iridium dark:bg-darkjunglegreenss dark:text-greychateau;
}

.down_icon svg path {
  @apply stroke-boulder;
}
.msg_right svg path {
  @apply dark:stroke-greychateau;
}

.msg_user {
  @apply mb-3 flex justify-end;
}

.msg_user span {
  @apply rounded-lg bg-davygrey p-3 text-white dark:bg-stormdust;
}

.footer_icon div button svg path {
  @apply stroke-greychateau;
}
.footer_icon textarea {
  @apply w-full resize-none border-none !bg-ghostwhite p-0 pb-12 text-sm text-santagrey focus:ring-0 dark:!bg-thunders dark:text-boulder;
}
@screen maxMd {
  .footer_icon textarea {
    min-height: 60px;
    @apply pb-0;
  }
  .down_icon svg {
    @apply h-6 w-6;
  }
}

.close_icon path {
  @apply dark:stroke-greychateau;
}
.multitag__indicators,
.multitag_spacing__indicators {
  @apply !hidden;
}
.metadata-view-container .PlaygroundEditorTheme__paragraph span {
  @apply text-sm;
}
.editor-container .PlaygroundEditorTheme__paragraph span {
  @apply text-sm;
}
.tags {
  @apply cursor-pointer !rounded-xl border border-black !bg-white !px-3 !py-2;
}
.tags span {
  @apply flex items-center font-inter-medium text-mirage;
}
.tags span svg {
  @apply ml-2 max-w-[16px] cursor-pointer;
}
/* Avatar CSS */

.avatar {
  @apply flex h-full w-full items-center !justify-center bg-antiflashwhite;
}

.avatar_xs {
  @apply h-4 w-4 text-xs;
}

.avatar_smd {
  @apply h-5 w-5 text-xs;
}

.avatar_sm {
  @apply h-6 w-6 text-xs;
}

.avatar_regular {
  @apply h-8 w-8 text-xs;
}

.avatar_md {
  @apply h-12 w-12 text-base;
}

.avatar_lg {
  @apply h-20 w-20 text-3xl;
}

.avatar_xl {
  @apply h-36 w-36 text-5xl;
}

.avatar_rounded {
  @apply rounded-full;
}

.avatar_squared {
  @apply rounded;
}

.select-team-member .select-control .multitag_spacing__input {
  @apply !pl-0;
}
.arrow_design {
  @apply h-8 w-8 justify-center rounded-md border border-aurometalsaurus dark:border-white;
}

.disable_arrow {
  @apply h-8 w-8 justify-center rounded-md border border-santagrey dark:border-greychateau;
}

.activityText {
  width: calc(100% - 34px);
  word-break: break-word;
}
.button-icon {
  @apply mb-4 flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-center font-inter-medium text-base text-gray-900 hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent dark:border-iridium dark:bg-darkjunglegreenss dark:text-white dark:hover:bg-gray-700 mac13Inch:mb-3 mac13Inch:text-sm;
}
.email_truncat {
  word-wrap: break-word;
  width: 120px;
  @apply overflow-hidden text-ellipsis whitespace-nowrap text-sm dark:text-white;
}
@screen maxMd {
  .header_iconfix {
    @apply absolute -bottom-[2px] right-0;
  }
}
.metadata_btn {
  @apply grid h-[30px] w-[30px] place-items-center;
}
.disabled-icon svg {
  @apply cursor-not-allowed;
}

.disabled-icon path {
  @apply stroke-[#A6A6A7];
}

/* Svg icon csss */
.icon-2xxs {
  @apply !h-3 !w-1.5;
}
.icon-1_5xxs {
  @apply !h-3 !w-2;
}
.icon-xxs {
  @apply h-3 w-3;
}
.icon-sxs {
  @apply h-[14px] w-[14px];
}
.icon-xs {
  @apply h-4 w-4;
}
.icon-sm {
  @apply h-[18px] w-[18px];
}
.icon-smd {
  @apply h-5 w-5;
}
.icon-md {
  @apply h-6 w-6;
}
.icon-lg {
  @apply h-8 w-8;
}
.icon-xl {
  @apply h-10 w-10;
}
.icon-2xl {
  @apply h-12 w-12;
}
.custom_modal > div {
  @apply h-auto;
}
.si.stroke:not(.stroke-none) path {
  @apply stroke-aurometalsaurus dark:stroke-greychateau;
}
.si.fill path {
  @apply fill-aurometalsaurus dark:fill-greychateau;
}
.si.fill.questionicon_dark path {
  @apply fill-white;
}
.si.fill.questionicon_dark path:first-child {
  @apply fill-aurometalsaurus dark:fill-greychateau;
}
.theme_icon path {
  @apply fill-aurometalsaurus dark:fill-greychateau;
}

.header-icon_dark:hover path {
  @apply dark:fill-greychateau;
}
.si.fill.plus_icon path {
  @apply fill-mirage dark:fill-white;
}
.si.fill.sidebar_icon path {
  @apply fill-mirage dark:fill-white;
}
.fc-toolbar-chunk {
  @apply flex items-center;
}
.si.stroke.themestroke_white path {
  @apply stroke-mirage dark:stroke-white;
}
.si.fill.notification_icon path {
  @apply fill-white dark:fill-mirage;
}
.si.stroke.notification_icon path {
  @apply stroke-white dark:stroke-mirage;
}
.si.fill.themesvg_white path {
  @apply fill-white dark:fill-iridium;
}
.doc_preview {
  height: calc(100vh - 120px);
}
.e-sheet-panel {
  height: calc(100vh - 259px);
}
@screen maxMd {
  .e-sheet-panel {
    height: calc(100svh - 392px) !important;
  }
}
.policies_dropdown {
  overflow: initial !important;
}
.table_scroll > div {
  @apply min-w-[400px];
}
.dashboard_dropdown ~ div {
  @apply dark:!bg-iridium;
}
.permission_tabs button {
  @apply py-3.5;
}
.mobile_avatar .avatar_squared {
  @apply maxMd:rounded-lg;
}
.title_ellipsis {
  @apply overflow-hidden text-ellipsis;
}
.tableview_icon rect {
  @apply dark:fill-boulder;
}
.tableview_icon:hover rect,
.column-selection__toggle:hover span svg path,
.table_view_icon:hover circle {
  @apply fill-mirage dark:fill-white;
}
.table_view_icon:hover path {
  @apply stroke-mirage dark:stroke-white;
}
.icon_hover:hover svg path {
  @apply !fill-crystalblue;
}
.si.stroke.grand_icon path {
  @apply dark:stroke-greychateau;
}
.si.fill.grand_icon path {
  @apply dark:fill-greychateau;
}
.si.message_icon path {
  @apply fill-santagrey dark:fill-boulder;
}
.custom_list li a:hover svg path {
  @apply fill-mirage dark:fill-white;
}
.arrow_design svg path {
  @apply !fill-aurometalsaurus dark:!fill-white;
}
.disable_arrow svg path {
  @apply !fill-santagrey dark:!fill-greychateau;
}
.gallery_icon {
  @apply h-10 w-10;
}
.si.gallery_icon path {
  @apply dark:stroke-aurometalsaurus;
}
.si.fill.tag-draft path {
  @apply fill-sepia dark:fill-sepia;
}

.subscriptions_right path {
  @apply !fill-mirage dark:!fill-white;
}
.bookmark_icon_dark path {
  @apply dark:!stroke-greychateau;
}
.share_icon path {
  @apply dark:!fill-greychateau dark:!stroke-greychateau;
}
.key_icon path {
  @apply !fill-santagrey dark:!fill-santagrey;
}
.close-icon path {
  @apply !fill-lavared;
}
.si.stroke.close-icon path {
  @apply stroke-lavared;
}
.si.check-icon path {
  @apply fill-greenhaze dark:fill-greenhaze;
}
.si.stroke.check-icon path {
  @apply stroke-greenhaze;
}
.rotate_image {
  @apply rotate-180;
}
.si.fill.notification_logo path {
  @apply fill-white;
}
.si.fill.notification_logo rect {
  @apply fill-black;
}

.danger_icon path {
  @apply !stroke-inherit;
}
.dowun_arrow svg {
  @apply !rotate-0;
}
.tag_icon_size svg {
  @apply h-3 w-3;
}
.excelsheet_toogal {
  width: calc(100% - 448px);
}

@screen maxMd {
  .modal_mobileview_center > div {
    @apply flex items-center justify-center;
  }
  .modal_mobileview_center > div > div {
    @apply w-full;
  }
  .mobile_search {
    @apply relative border-b px-3.5 py-5 dark:border-thunders;
  }
  .mobile_search input {
    @apply rounded-lg border border-lightSilver p-3;
  }
  .mobile_search svg {
    @apply left-6 top-[33px];
  }
  .text_ellipsis {
    max-width: 230px;
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }
}
.email_ellipsis {
  max-width: 220px;
  @apply block overflow-hidden text-ellipsis whitespace-nowrap;
}

#filter-datepicker .publication-date .react-datepicker-popper {
  top: -118px !important;
}
@screen md {
  #filter-datepicker .publication-datetwo .react-datepicker-popper {
    top: -168px !important;
  }
}
.heading_title {
  @apply font-inter-medium text-lg dark:text-white;
}

.metadata_file {
  width: calc(100% - 34px);
  @apply flex;
}
@screen maxMd {
  .changepassword_modal > div > div {
    @apply w-full;
  }
}
@screen maxMd {
  .modal_height {
    @apply !h-full;
  }
  .modal_height > div > div {
    @apply h-full;
  }
  .provider-modal {
    @apply !h-full;
  }
  .provider-modal > div > div {
    @apply h-full max-h-full;
  }
  .add-iconbox button {
    @apply maxMd:!pr-0;
  }
}
.badge_title {
  @apply rounded-md bg-aurometalsaurus px-2.5 py-1 text-sm text-white;
}
.header_iconfix div:nth-child(3) {
  @apply flex;
}

.no-data-available {
  height: calc(100vh - 195px);
}

#permission-list .permission-item {
  @apply border-b border-solid border-gray-300;
}

#permission-list .permission-item:last-child {
  @apply border-b-0;
}
.width_calc {
  width: calc(100% - 44px);
}
.warning-icon path {
  @apply !stroke-lavared;
}
.action_edit_icon {
  @apply h-[14px] w-[14px];
}
.accordion_arrow_icon path {
  @apply !stroke-mirage dark:!stroke-greychateau;
}
.accordion_arrow_icon path {
  @apply !stroke-mirage;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5 !important;
}
.assistant_body {
  @apply h-[74vh];
}
@screen maxMd {
  .assistant_card {
    width: calc(100% - 4rem);
  }
  .assistant_body {
    @apply min-h-[500px];
  }
}
.document_height {
  height: calc(100% - 57px);
}
@screen maxMd {
  .document_height {
    height: calc(100% - 61px);
  }
  .spreadsheet {
    height: calc(100svh - 247px) !important;
  }
}

.risk_tabbing .edit_risk {
  @apply mb-40;
}
.risk-content {
  width: calc(100% - 450px) !important;
  @apply overflow-hidden;
}
.incidents_content {
  @apply overflow-x-hidden;
}
.risk_table {
  @apply max-h-60;
}
.risk_table::-webkit-scrollbar {
  @apply w-1;
}
.columnSpacing tbody tr td:first-child,
.columnSpacing thead tr th:first-child {
  @apply !pl-5;
}
.risk_table thead th {
  @apply sticky top-0 h-11 whitespace-nowrap bg-antiflashwhite dark:bg-balticsea;
}
.sidebar_customs .active {
  @apply bg-gray-100 dark:bg-balticsea;
}
.fc-col-header .fc-scrollgrid-sync-inner,
.fc-scrollgrid-sync-inner {
  @apply dark:bg-mirage;
}
.dropdown_body > div {
  @apply dark:bg-thunders;
}
.dropdown_body > div ul li {
  @apply dark:text-greychateau dark:hover:bg-darkjunglegreenss dark:hover:text-white;
}
.impact_type_column,
.dots_horizontal_column {
  @apply grid;
}
.impact_type_column span,
.impact_type_column li,
.impact_type_column h3,
.dots_horizontal_column span,
.dots_horizontal_column h3 {
  @apply cursor-default overflow-hidden text-ellipsis whitespace-nowrap break-all;
}

.dots_horizontal_column span {
  @apply cursor-pointer;
}

.disconnect_dropdown {
  @apply absolute right-8 top-8 z-10 flex max-h-40 w-[220px]  cursor-pointer gap-2 overflow-y-auto rounded-md border border-antiflashwhite bg-white  px-4 py-3 dark:border-thunders dark:bg-thunders;
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.yearly-table tbody tr:last-child .disconnect_dropdown {
  @apply !top-[-50px];
}

.risk_table tbody tr:last-child .disconnect_dropdown {
  @apply -top-5;
}

.impact_type_dropDown {
  @apply absolute top-20 z-10  max-h-20 w-[220px] overflow-y-auto rounded-md border border-antiflashwhite bg-white px-4 py-2 dark:border-thunders dark:bg-thunders;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.risk_table tbody tr:last-child .impact_type_dropDown {
  top: inherit;
  @apply bottom-0;
}
.impact_type_dropDown li,
.impact_type_dropDown h3 {
  @apply p-1;
}

.risk_table tbody tr {
  @apply relative;
}
.react-datepicker-wrapper {
  @apply !flex !w-fit min-w-[16rem];
}
.worning-content {
  width: calc(100% - 26px);
}
.details_table > div {
  @apply overflow-x-auto;
}
.details_table th {
  @apply maxMd:px-4;
}
.label-font {
  @apply text-sm;
}

.risk_table th {
  @apply cursor-pointer;
}
.range-input-container {
  width: 300px;
}
.range_tooltip {
  background-color: #333;
  @apply invisible absolute -top-[45px] w-[86px] rounded p-2 p-[5px]  text-center text-sm text-white opacity-0;
}
.progressbar_design .range_tooltip {
  width: inherit;
  min-width: 127px !important;
}
.tooltip_arrow {
  transform: rotate(45deg);
  border-radius: 1px;
  transform: translateX(-50%) rotate(45deg);
  z-index: -1;
  @apply absolute -bottom-[5px] inline-block h-3 w-3;
}
.progressbar_design {
  @apply relative grid;
}
.busd-slider {
  width: calc(100% - 124px);
}
.progressbar_design input {
  @apply w-full;
}
:global(body.dark) .progressbar_design input {
  @apply accent-greychateau;
}

.range-input-container:hover .range_tooltip {
  @apply visible opacity-100;
}
.multitag_spacing__control {
  @apply !cursor-text;
}
.yearly-table {
  @apply max-h-96;
}
.yearly-table table tbody tr:last-child .impact_type_dropDown {
  @apply !bottom-8;
  top: inherit !important;
}
.header_action_assistant:hover > div svg path,
.setting_member_module:hover > div svg path,
.header_action_buttons:hover > div svg path {
  @apply !fill-mirage dark:!fill-white;
}

.download-icon svg:hover path,
.header_action_search:hover > div svg path {
  @apply stroke-mirage dark:stroke-white;
}
.icon_hovereffect:hover path {
  @apply fill-mirage dark:fill-white;
}
.view_icon > div {
  @apply flex;
}
/* risk download button icon CSS */

.risk-download path {
  @apply !stroke-white dark:!stroke-mirage;
}
.download_hovericon:hover svg path,
.dots_hover:hover svg path {
  @apply stroke-mirage dark:stroke-white;
}

.global_hover_icon:hover path {
  @apply stroke-mirage dark:stroke-white;
}

.yearly-table thead {
  @apply sticky left-0 top-0 z-10 w-full;
}
.yearly-table tbody tr td {
  @apply bg-white dark:bg-darkjunglegreenss;
}
.yearly-table tbody tr:hover td {
  @apply bg-antiflashwhite dark:bg-balticsea;
}
.year-plan-table .yearly-table tbody tr td:nth-child(2),
.year-plan-table .yearly-table thead tr th:nth-child(2) {
  @apply w-1/2;
}
.apexcharts-canvas svg {
  @apply dark:!bg-darkjunglegreenss;
}

.risks_table_title thead tr th {
  @apply dark:!bg-balticsea;
}
.risk_dropdown_body > div > svg path {
  @apply stroke-white dark:stroke-slate-500;
}
.risk_dropdown_body > div button > svg path {
  @apply stroke-aurometalsaurus dark:stroke-greychateau;
}
.null_bage_bg {
  @apply dark:!bg-liver;
}

.downloadLib-icon-dark path {
  @apply stroke-white dark:stroke-darkJungleGreen;
}

.custom_input_error {
  @apply !border-red-500  !bg-red-50 !text-red-900;
}
.custom_input_error::placeholder {
  @apply !text-red-700;
}
.custom_input_error div {
  @apply !text-red-900;
}
.confirm_modal {
  @apply !bg-gray-900 !bg-opacity-25;
}
/* risk add create icon CSS */
.common_create_risk_addIcon,
.common_create_dropdown_addIcon {
  @apply sticky -bottom-1 flex  w-full items-center border-t-[1px] bg-ghostwhite p-1 text-xs font-semibold text-aurometalsaurus hover:bg-antiflashwhite hover:text-black dark:border-iridium dark:bg-thunders dark:text-stormdust dark:hover:bg-iridium dark:hover:fill-white dark:hover:text-white;
}
.common_create_risk_addIcon > span > svg > path,
.common_create_dropdown_addIcon > span > svg > path {
  @apply fill-aurometalsaurus dark:fill-[#646466];
}
.common_create_risk_addIcon:hover > span > svg > path,
.common_create_dropdown_addIcon:hover > span > svg > path {
  @apply fill-black dark:fill-white;
}
.risk_dropdown {
  @apply dark:!bg-balticsea;
}
.risk_dropdown ul li {
  @apply hover:dark:!bg-darkjunglegreenss;
}
.tooltip_slider:hover .range_tooltip {
  @apply visible opacity-100;
}
.rc-slider-handle {
  @apply !cursor-pointer !opacity-100;
}
.probability_label {
  width: calc(100% - 93px);
}
.rc-slider-track {
  @apply !rounded-r-none;
}
.rc-slider-disabled {
  @apply !bg-transparent;
}
.rc-slider-disabled .rc-slider-handle {
  @apply !cursor-default;
}

.column-selection-list li > button {
  @apply focus:!ring-0;
}
.column-selection-list {
  @apply !top-[47px];
}
.column_selection_li:hover button {
  @apply dark:!bg-darkjunglegreenss;
}
.column_selection_li button {
  @apply !p-0;
}
/* Filter mobile view design */
.filter_view {
  @apply fixed left-0 top-16 z-20 h-full w-full pl-8;
}
.filter_view::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  @apply absolute bottom-0 left-0 right-0 top-0 -z-[1] h-full w-full;
}
.filter_view > div {
  height: calc(100svh - 64px);
  @apply w-full overflow-y-auto bg-white p-4 dark:bg-darkjunglegreenss;
}
.filter_view ul li {
  @apply py-2 maxMd:text-base maxMd:text-aurometalsaurus maxMd:dark:text-greychateau;
}
.filter_view ul li button {
  @apply flex w-full items-center justify-between text-left;
}
@screen maxMd {
  .filter_view ul li button span {
    @apply text-base font-medium;
  }
}
@screen maxMd {
  .react-datepicker__input-container input {
    @apply text-sm;
  }
}
@screen maxMd {
  .compare_modal > div > div {
    @apply h-full max-h-full;
  }
}
.custom-policies-title {
  width: calc(100% - 132px);
  @apply maxMd:flex-col-reverse maxMd:!items-start maxMd:gap-2;
}
.react_tooltip {
  @apply invisible relative bottom-[54px] z-10 inline-block w-24 rounded-lg bg-antiflashwhite px-2 py-1  text-center text-xs dark:bg-thunders dark:text-greychateau;
}

.react_tooltip span {
  transform: rotate(45deg);
  border-radius: 1px;
  transform: translateX(-50%) rotate(45deg);
  z-index: -1;
  @apply absolute -bottom-[5px] left-2/4 inline-block h-3 w-3 bg-antiflashwhite dark:bg-thunders;
}
.rc-slider-handle-dragging {
  @apply !shadow-none;
}
.end-range,
.start-range {
  @apply !top-0 !h-3.5 !w-3.5;
}

.metadataWidthCalc {
  width: calc(100% - 450px) !important;
}
.risks-register-table tbody td:nth-last-child(-n + 2) {
  @apply w-[1px];
}

.risks_register_table_column_default tbody td:nth-last-child(-n + 5) {
  @apply !w-0;
}

.associated-risks-table:not(.editable) tbody td:nth-last-child(-n + 3),
.associated-risks-table.editable tbody td:nth-last-child(-n + 4),
.associated-risks-table:not(.editable) tbody td:nth-child(1),
.associated-risks-table.editable tbody td:nth-child(2) {
  @apply w-[1px];
}

.btn_secondary[disabled]:hover {
  background-color: transparent !important;
  color: initial !important;
}
.create_task .tasks-draggable-block-menu {
  @apply maxMd:hidden;
}
.create_task .ContentEditable__root {
  @apply maxMd:!ml-0 maxMd:pl-0;
}
.create_task .Placeholder__root {
  @apply maxMd:left-0;
}
.serverError ul {
  @apply max-w-full;
}
.risk_truncate span {
  @apply max-w-[82px] overflow-hidden text-ellipsis whitespace-nowrap;
}
/* comman dropdown design */
.drop_down {
  @apply relative;
}
.drop_down_button {
  @apply rounded-md px-2 py-1.5 hover:bg-ghostwhite hover:dark:bg-balticsea;
}
.drop_down_button:hover svg path {
  @apply dark:fill-white;
}
.drop_down_button:hover span {
  @apply dark:text-white;
}
.drop_down > ul > li {
  @apply flex cursor-pointer flex-wrap justify-between gap-x-2 px-4 py-2 hover:bg-antiflashwhite hover:dark:bg-darkjunglegreenss;
}
.sub_dropdown_list > ul > li > span {
  width: calc(100% - 28px);
}
.sub_dropdown_list ul {
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  width: 250px;
  max-height: 490px;
  @apply absolute left-5 top-2 z-10 overflow-y-auto rounded-md bg-white py-1.5 text-sm dark:bg-thunders;
}
.article_download_dropdown ul {
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  width: 180px;
  @apply absolute z-10 rounded-md bg-white py-1.5 text-sm dark:bg-thunders;
}
.article_download_dropdown li {
  @apply z-10 flex cursor-pointer flex-wrap justify-between gap-x-2 px-4 py-2 hover:bg-antiflashwhite dark:!text-greychateau hover:dark:bg-darkjunglegreenss;
}
.sub_dropdown_list ul li svg path {
  @apply !fill-mirage dark:!fill-greychateau;
}
.inner_dropdown {
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  width: 250px;
  @apply absolute -right-[432px] top-[3.625rem] z-10 rounded-md  bg-white py-1.5 text-sm dark:bg-thunders;
}
.sub_dropdown_list {
  @apply relative;
}
.sub_dropdown_list > ul > li {
  @apply flex cursor-pointer flex-wrap justify-between gap-x-2 px-4 py-2 hover:bg-antiflashwhite hover:dark:bg-darkjunglegreenss;
}
.inner_dropdown li {
  @apply z-10 flex cursor-pointer flex-wrap justify-between gap-x-2 px-4 py-2 hover:bg-antiflashwhite dark:!text-greychateau;
}
.inner-filter-dropdown {
  width: 400px;
  right: -582px;
}
@screen minMaxXxl {
  .inner-filter-dropdown {
    width: 284px;
    right: -464px;
  }
}
.inner-filter-dropdown > li ul li {
  @apply hover:dark:bg-darkjunglegreenss;
}

.inner_dropdown li div {
  @apply w-full;
}
.active-list {
  @apply bg-antiflashwhite dark:bg-darkjunglegreenss;
}
.active-list span {
  @apply dark:text-white;
}
.sub_dropdown_list .active-list svg path {
  @apply dark:!fill-white;
}
.button-active {
  @apply bg-ghostwhite dark:bg-balticsea;
}
.drop_down .button-active svg path {
  @apply !fill-mirage dark:!fill-white;
}
.drop_down .button-active span {
  @apply text-mirage dark:text-white;
}
.filter_search_truncate {
  @apply max-w-full overflow-hidden text-ellipsis whitespace-nowrap;
}
.view_task_underline .task_underline {
  @apply underline;
}
.fixed-selected {
  @apply sticky top-0;
}

/* Trial Modal css */

.trial-modal-image {
  @apply rounded-bl-lg rounded-tl-lg bg-gray-100;
}

.trial-modal-description {
  @apply flex flex-col gap-4 p-8;
}

.trial_container > div > div > div {
  padding: 0px !important;
}
/* Document viewer css */
.e-de-pagenumber-input {
  @apply !p-0;
}

.intercom-messenger-frame,
.intercom-lightweight-app,
.intercom-app div:nth-child(2),
.intercom-launcher .intercom-launcher-active,
.intercom-lightweight-app-launcher {
  @apply !z-[1001];
}
.dropdown_darkmode {
  @apply dark:!bg-thunders;
}
.dropdown_darkmode li:hover {
  @apply dark:!bg-iridium;
}
.permission_modal .css-mxbc19-control,
.permission_modal .css-mxbc19-control {
  @apply dark:border-iridium;
}
.permission_modal input[type="text"] {
  min-width: 100% !important;
}

.permission_modal > div > div > div > div > div::after {
  white-space: inherit !important;
  min-width: 1px !important;
}

.custom_spinner path:first-child {
  @apply dark:!fill-white dark:!stroke-white;
}
.custom_spinner path:last-child {
  @apply dark:!fill-mirage dark:!stroke-mirage;
}
.note_bgcolor {
  @apply dark:bg-darkjunglegreenss;
}

.teamuserSelectOption {
  @apply flex flex-col;
}
.teamuserSelectOption input:focus {
  @apply dark:!ring-0;
}

/* multi year inside yearly planner */

.multi_year_date_picker > div:nth-child(2) > div:nth-child(2) > div > .react-datepicker {
  padding: 1rem !important;
}

.multi_year_date_picker > .react-datepicker-wrapper > .react-datepicker__input-container {
  width: 100px;
  padding: 1rem;
}

.multi_year_date_picker > .react-datepicker-wrapper > .react-datepicker__input-container {
  @apply !hidden;
}

.multi_year_date_picker
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > .react-datepicker:nth-child(3)
  > .react-datepicker__header {
  @apply !hidden;
}

.multi_year_date_picker
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > .react-datepicker
  > .react-datepicker__triangle {
  @apply !hidden;
}

.multi_year_date_picker > div:nth-child(2) > div:nth-child(2) > div > .react-datepicker {
  @apply dark:text-white;
}

.multi_year_date_picker .react-datepicker .react-datepicker-year-header {
  @apply !m-0 !mt-4;
}

.multi_year_date_picker
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > .react-datepicker:nth-child(3) {
  @apply hidden;
}
.multi_year_date_picker > .react-datepicker-wrapper > .react-datepicker__tab-loop {
  @apply absolute left-0;
}
.setting_bg {
  @apply bg-ghostwhite dark:bg-mirage;
}
.close_filter_icon path {
  @apply !stroke-blue-400;
}

.custom_select {
  @apply w-full max-w-[333px];
}
.custom_select select {
  @apply w-full rounded-lg border border-lightSilver bg-ghostwhite text-sm text-aurometalsaurus;
}
.group_input input {
  @apply !max-w-full;
}

.multi_year_date_picker .react-datepicker__year--container > div {
  @apply dark:!text-white;
}

.multi_year_date_picker .react-datepicker__year-wrapper .react-datepicker__year-text--disabled {
  @apply dark:!text-gray-400;
}

.multi_year_date_picker .react-datepicker__year-wrapper .react-datepicker__year-text--disabled {
  @apply hover:bg-white dark:!text-gray-400;
}

.multi_year_date_picker .react-datepicker__year-wrapper .react-datepicker__year-text {
  @apply dark:hover:!text-darkJungleGreen;
}
.articles_badeg {
  @apply absolute right-2.5 top-2 flex items-center gap-1 rounded bg-mirage px-2 py-1 text-sm text-white dark:bg-white dark:text-mirage;
}
.setting_main {
  transform: translateX(50%);
  height: calc(100vh - 4rem);
  @apply fixed right-1/2 top-16 w-full overflow-y-auto;
}
.risk_multiselect .risk_multitag__menu {
  @apply z-10 dark:bg-balticsea;
}
.risk_multiselect .risk_multitag__option {
  @apply dark:bg-balticsea dark:hover:!bg-iridium;
}
.risk_multiselect .risk_multitag__option--is-selected {
  @apply dark:bg-iridium dark:text-white;
}
.risk_multiselect .risk_multitag__control {
  @apply dark:border-thunders;
}
.provider_modal__multi-value__label {
  @apply dark:hover:bg-transparent;
}
.searchable_dropdown__menu-list {
  @apply break-words;
}

.searchable_dropdown__input {
  @apply !p-0;
}
.agreement_option .searchable_dropdown__menu {
  position: inherit;
}

.dropdown_modal_overflow {
  overflow: inherit !important;
}

.maintenance-dark__icon path {
  @apply dark:fill-greychateau;
}

.add_member_selection .multitag_spacing__control {
  @apply dark:!border-iridium;
}
/* action button risk-yearly-planner */
.risk_yearly_planner_action_button {
  @apply flex items-center gap-2  p-2 hover:bg-antiflashwhite dark:hover:bg-darkjunglegreenss;
}
.text_break {
  @apply whitespace-normal;
  word-break: break-word;
}

/* Object Permission Modal */

.input_field {
  @apply relative;
}

.input_field input {
  @apply h-[54px] !p-[10px] !pr-[80px];
}

.people_access_container {
  @apply mt-4 flex !w-full flex-col gap-3;
}

.custom_select {
  @apply w-full rounded-lg !border border-brightgray text-sm text-aurometalsaurus dark:border-iridium;
}

.custom_options {
  @apply p-[8px];
}

.remove_access_option {
  @apply text-red-500;
}

.members_container_sub_view {
  @apply flex flex-col gap-2;
}

.team_description_container {
  @apply flex !w-full items-center justify-between gap-2;
}

.avatar_container_team_details {
  @apply flex w-full cursor-pointer items-center gap-2;
}

.general_access_link_container {
  @apply relative  mt-4;
}

.general_access_link_container input {
  @apply !p-3;
}

.object_permission_footer_container {
  @apply flex w-full items-center justify-between gap-2;
}

.object_permission_action_button {
  @apply flex gap-2;
}
.object_permission_settings_container {
  @apply flex items-center gap-2;
}

.general_access_dropdown .drop_down_card .drop_down_icon {
  @apply pl-2;
}

.general_access_dropdown .option {
  @apply !p-1;
}

.general_access_dropdown .drop_down_card {
  @apply ml-4 !w-[300px];
}

.general_access_dropdown .drop_down_card_container {
  @apply right-[-15px];
}

/* Custom Drop down Design */

.dropdown_header {
  @apply flex w-[150px] items-center justify-between gap-2 border-brightgray;
}

.general_access_dropdown .dropdown_header {
  @apply !right-0 w-[300px];
}

.separator {
  height: 1px;
  background-color: #000;
  margin: 5px 0;
}
.drop_down_card_container {
  @apply absolute right-[-2px] z-10 mt-4;
}

.drop_down_card {
  @apply mb-4 min-w-[155px] cursor-pointer rounded-lg bg-white px-1 py-1 text-white shadow-md dark:border dark:border-jet dark:bg-mirage;
}

.option {
  @apply flex items-center gap-2 rounded text-sm font-medium text-gray-500 hover:bg-antiflashwhite hover:dark:bg-darkjunglegreenss;
}

.remove_access_icon > path {
  @apply !fill-red-500;
}
.multitag_spacing__multi-value__label {
  @apply font-inter-medium !font-medium;
}
.multitag_spacing__option {
  @apply font-inter-medium !font-medium;
}
